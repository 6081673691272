import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import firebase from "firebase";
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../Page';
import ToggleSwitch from '../ToggleSwitch';
import { withFirebase } from '../Firebase';
import _  from "lodash";
import {CircularProgress} from '@material-ui/core';

import Select from 'react-select'
import {getUser, updateUserCampaignData} from '../Utils/users';
import { AuthUserContext } from '../Session';
import { withAuthorization } from '../Session';
import FileUploader from "react-firebase-file-uploader";
import emptystate from "../../images/emptystate.svg";
import {getCampaign, getPricesByZip} from '../Utils/campaigns';
import { CampaignCategories, CampaignMealOptions, CampaignDietaryOptions } from '../Utils/defines';


const CompleteSetupPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <Layout additionalClass="header_add"  name={authUser && authUser.displayName ?authUser.displayName.split(" ")[0]:''}>
        <CompleteSetupForm authUser={authUser}/>
      </Layout>
    )}
  </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
  accImg: "",
  campaignPhoto: "",
  errorCampaign: "",
  campaignTitle: '',
  campaignCategory: '',
  campaignDescription: '',
  campaignGoalType: '',
  campaignNote: '',
  campaignSetupStep: 1,
  campaignMeals: [],
  recipientContactInformation: '',
  recipientContactPhone: '',
  recipientFirstName: '',
  recipientLastName: '',
  recipientAddress1: '',
  recipientAddress2: '',
  recipientPhone: '',
  recipientCity: '',
  recipientDietaryPreferences: [],
  recipientState: '',
  recipientZipCode: '',
  recipientNotes: '',
  recipientMeals: '',
  recipientFrequency: '',
  user: '',
  recommandationGoals: 0,
  organization:false,
  organizationName: '',
  campaigns: [],
  isPrivate:false
};

class CompleteSetupFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, isUploading: false, isError:false };
    this.storage = props.firebase.getstorage();
    this.db = this.props.firebase.getdb();
    
    this.firebase = this.props.firebase;
    this.campaignId = props.match.params.id;
    getUser(this.db, props.authUser.email, this.fetchUserData);
  }

  componentDidMount() {
    getCampaign(this.db, this.props.match.params.id, this.loadCampaign);

  }

  loadCampaign = campaign => {

    const campaignMeals = _.map(campaign.campaignMeals, item => {
      return {value:item, label:item.charAt(0).toUpperCase() + item.slice(1)}
    });

    this.setState({
      campaign,
      organization:campaign.campaignOrganization, 
      campaignGoalType:campaign.campaignGoalType, 
      recommandationGoals:campaign.recommandationGoals, 
      campaignMeals: campaignMeals
    });
  }

  fetchUserData = item => {
    this.setState({user: item.id, accImg: item.accImg, name: item.name})
  }


  onSubmitCampaign = async () => {
    console.log(this.state.campaign);
    let that=this;
    event.preventDefault();

    const {
      campaign,
      campaignPhoto,
      campaignTitle,
      campaignCategory,
      campaignDescription,
      campaignGoalType,
      recommandationGoals,
      campaignNote,
      campaignMeals,
      campaignDeliveries,
      organization,
      recipientDietaryPreferences,      
      recipientContactInformation,
      recipientFirstName,
      recipientLastName,
      recipientAddress1,
      recipientContactPhone,
      recipientAddress2,
      recipientPhone,
      recipientCity,
      recipientState,
      recipientZipCode,
      recipientNotes,
      recipientMeals,
      recipientFrequency      
    } = this.state;


    var data = {      
      campaignAdmin: this.props.authUser.email,
      campaignCategory:"", 
      campaignNote:"", 
      campaignGoalType:"", 
      recommandationGoals:"", 
      campaignPhoto:"",
      recipientDietaryPreferences: recipientDietaryPreferences.map(dietary => dietary.value),
      campaignMeals: campaignMeals.map(meal => meal.value),
      recipientMeals: 0,
      campaignStatus: 'Active',
      isPrivate: false
    };

    if (!!campaignTitle) {
      data.campaignTitle = campaignTitle;      
    }

    if (!!campaignDescription) {
      data.campaignDescription = campaignDescription;      
    }

    if (!!campaignCategory) {
      data.campaignCategory = campaignCategory;      
    }

    if (!!campaignPhoto) {
      data.campaignPhoto = campaignPhoto;      
    }

    if (!!campaignNote) {
      data.campaignNote = campaignNote;      
    }

    if (!!recommandationGoals) {
      data.recommandationGoals = recommandationGoals;      
    }

    if (!!campaignGoalType) {
      data.campaignGoalType = campaignGoalType;      
    }

    if (!!campaignDeliveries) {
      data.campaignDeliveries = campaignDeliveries;
    }

    if (!!recipientContactInformation) {
      data.recipientContactInformation = recipientContactInformation;      
    }

    if (!!recipientFirstName) {
      data.recipientFirstName = recipientFirstName;      
    }

    if (!!recipientLastName) {
      data.recipientLastName = recipientLastName;      
    }

    if (!!recipientContactPhone) {
      data.recipientContactPhone = recipientContactPhone;      
    }

    if (!!recipientAddress1) {
      data.recipientAddress1 = recipientAddress1;      
    }

    if (!!recipientAddress2) {
      data.recipientAddress2 = recipientAddress2;      
    }

    if (!!recipientPhone) {
      data.recipientPhone = recipientPhone;      
    }
    
    if (!!recipientCity) {
      data.recipientCity = recipientCity;      
    }

    if (!!recipientState) {
      data.recipientState = recipientState;      
    }

    if (!!recipientZipCode) {
      data.recipientZipCode = recipientZipCode;      
    }

    if (!!recipientNotes) {
      data.recipientNotes = recipientNotes;      
    }

    if (!!recipientMeals) {
      data.recipientMeals = recipientMeals;      
    }

    if (!!recipientMeals) {
      data.recipientFrequency = recipientFrequency;      
    }

    if (recipientZipCode) {
      const {price, rate, zone} = await getPricesByZip(this.db, recipientZipCode);
      data.orderDeliveryPrice = campaign.boxCount * price + rate;
      data.orderFundsNeeded = campaign.boxCount * price + rate;
      data.zone = zone;
    } else {
      data.orderDeliveryPrice = 0;
      data.orderFundsNeeded = 0;
      data.zone = 1;
    }
    
    const customer_data = {
      "first_name": recipientFirstName, 
      "last_name": recipientLastName,
      "email": recipientContactInformation,
      "phone": "+1"+recipientPhone.replace(/\D/g,''),
      "tags": "recipient"
    }

    fetch("https://sirvve-prod.herokuapp.com/customer" , {
      headers: {'Content-Type': 'application/json'},
      mode: "no-cors",
      method: 'POST',
      body: JSON.stringify(customer_data)
    });

    await updateUserCampaignData(that.db, that.campaignId, data);
    that.props.history.push('/campaign-overview/'+that.campaignId);    
  }

  handleUploadStart = () => {
    this.setState({isUploading: true});
  }

  handleProgress = (progress) => {
  }
  handleUploadError = (error) => {
    this.setState({isUploading: false});
    console.error(error);
  }
  handleUploadSuccessPrimary = (filename) => {
    this.storage
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ campaignPhoto: url, isUploading: false }));
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  changeMeal = (meals) => {
    this.setState({campaignMeals: meals})
  }

  changeDietary = (dietary) => {
    this.setState({recipientDietaryPreferences: dietary})
  }

  checkProfile = (event) => {
    event.preventDefault();

    const {
      campaignPhoto,
      campaignTitle,
      campaignCategory,
      campaignDescription,
      campaignMeals,
      isUploading    
    } = this.state;

    if (isUploading)
      return;
    
    let isError = !campaignPhoto || !campaignTitle || !campaignCategory || !campaignDescription || campaignMeals.length==0;
    if (isError) {
      this.setState({isError});
      return;
    }    
    
    this.setState({isError, campaignSetupStep: 2});
  }

  checkShippingInfo = (event) => {
    event.preventDefault();

    const {
      recipientDietaryPreferences,      
      recipientContactInformation,
      recipientFirstName,
      recipientLastName,
      recipientAddress1,
      recipientContactPhone,
      recipientAddress2,
      recipientPhone,
      recipientCity,
      recipientState,
      recipientZipCode,
      recipientNotes,
    } = this.state;

    
    let isError = !recipientContactInformation || 
                  /*!recipientContactPhone ||*/
                  !recipientFirstName ||
                  !recipientLastName ||
                  !recipientAddress1 ||
                  !recipientPhone ||
                  !recipientCity ||
                  !recipientState ||
                  !recipientZipCode;
    if (isError) {
      this.setState({isError});
      return;
    }    
    this.onSubmitCampaign();
  }

  renderStep1 = () => {
    const {
      accImg,
      name,
      campaignPhoto,
      campaignTitle,
      campaignCategory,
      campaignDescription,
      errorCampaign,
      campaignMeals,
      isUploading,
      organization,
      campaignGoalType,
      recommandationGoals,
      campaignDeliveries,
      campaignNote,
      isError
    } = this.state;
    var that = this;

    return (
      <div className="Container">
      <div className="campaign-form-wrap settings-form-wrap">
        <form onSubmit={this.checkProfile}>
          <div className="photo_wrap">
            {campaignPhoto && !isUploading && <img src={campaignPhoto} />}
            {!isUploading && !campaignPhoto && <div className="placeholder-img"><img src={emptystate}/></div>}
            {isUploading && <div className="placeholder-img"><CircularProgress /></div>}
            <label className="uploader_button" id="uploader_button">
              {campaignPhoto? "Edit Cover Photo":"Upload Cover Photo"}
              <FileUploader
                id="uploader_button"
                accept="image/*"
                name="primary"
                randomizeFilename
                storageRef={this.storage.ref("images")}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccessPrimary}
                onProgress={this.handleProgress}
              />
              {isError && !campaignPhoto && (
                <label className='error-label'>This field is required</label>
              )}
            </label>
          </div>

          <div className='form-field'>
            <label>Registry Title</label>
            <input
              name="campaignTitle"
              value={campaignTitle}
              onChange={this.onChange}
              type="text"
              placeholder="Enter Profile Name"
            />
            {isError && !campaignTitle && (
              <label className='error-label'>This field is required</label>
            )}
          </div>

          {!organization &&
          <div className='form-field'>
            <label>Category</label>
            <div className="select_wrap">
              <select 
                name="campaignCategory"
                onChange={this.onChange}
                placeholder="Select a Category"
                defaultValue="">
                <option value="" disabled hidden>Select a Category</option>
                {_.map(CampaignCategories, item => <option value={item.value}>{item.label}</option>)}
              </select>
            </div>
            {isError && !campaignCategory && (
              <label className='error-label'>This field is required</label>
            )}
          </div>
          }

          <div className='form-field'>
            <label>Description</label>
            <textarea 
              name="campaignDescription"
              value={campaignDescription}
              onChange={this.onChange}
              placeholder="Explain why you are creating this registry.">

            </textarea>
            {isError && !campaignDescription && (
              <label className='error-label'>This field is required</label>
            )}
          </div>

          {organization &&
          <div className='form-field'>
            <label>Goal Preference</label>
            <div className="select_wrap">
              <select 
                name="campaignGoalType"
                onChange={this.onChange}
                defaultValue={campaignGoalType}>                
                <option value="monthly">Monthly</option>
                <option value="annual">Annual</option>
              </select>
            </div>
          </div>
          }

          {
            organization && 
            <div className='form-field'>
              <label>Meals Per Goal</label>
              <div className="select_wrap">
                <select 
                  name="recommandationGoals"
                  onChange={this.onChange}
                  placeholder="How long do they need meals for?"
                  defaultValue={recommandationGoals}>
                  <option value={48}>48 meals</option>
                  <option value={96}>96 meals</option>
                  <option value={144}>144 meals</option>
                  <option value={192}>192 meals</option>
                  <option value={240}>240 meals</option>
                </select>
              </div>
            </div>
          }

          <div className='form-field'>
            <label>Meal Preferences</label>
            <Select
              value={campaignMeals}
              isMulti
              name="meals"
              options={CampaignMealOptions}
              className="basic-multi-select"
              onChange= {that.changeMeal}
              classNamePrefix="select"
            />
            {isError && campaignMeals.length==0 && (
              <label className='error-label'>This field is required</label>
            )}
          </div>

          <div className='form-field'>
            <label>Dietary Preferences <span>(optional)</span></label>
            <Select
              isMulti
              name="restrictions"
              options={CampaignDietaryOptions}
              className="basic-multi-select"
              onChange= {that.changeDietary}
              classNamePrefix="select"
            />
          </div>

          <div className='form-field'>
            <label>When would you like deliveries to start?</label>
            <div className="select_wrap">
                <select 
                  name="campaignDeliveries"
                  onChange={this.onChange}
                  placeholder=""
                  defaultValue={campaignDeliveries}>
                  <option value="As soon as funds are available">As soon as funds are available</option>
                  <option value="One week after">One week after</option>
                  <option value="Two weeks after">Two weeks after</option>
                  <option value="One month after">One month after</option>                  
                </select>
              </div>
          </div>          

          {/*organization && 
          <div className='form-field'>
            <label>Internal Notes</label>
            <textarea 
              name="campaignNote"
              value={campaignNote}
              onChange={this.onChange}
              placeholder="">

            </textarea>
          </div>
          */}

          <div className='form-field'>
            <button type="submit" disabled={isUploading}>
              Next Step
            </button>
            {isError && <label className='error-label'>Please enter all required information.</label>}
          </div>
          
        </form>
      </div>
    </div>
    );
  }

  renderStep2 = () => {
    const {
      isError,
      errorCampaign,
      campaignSetupStep,
      recipientDietaryPreferences,
      recipientContactInformation,
      recipientContactPhone,
      recipientFirstName,
      recipientLastName,
      recipientAddress1,
      recipientAddress2,
      recipientPhone,
      recipientCity,
      recipientState,
      recipientZipCode,
      recipientNotes,
      organization
    } = this.state;
    var that = this;

    return (
      <div className="Container">
      <div className="campaign-form-wrap settings-form-wrap">
        <form onSubmit={this.checkShippingInfo}>
          <div className='form-field'>
            <label>Recipient Contact Information</label>
            <input
              name="recipientContactInformation"
              value={recipientContactInformation}
              onChange={this.onChange}
              type="email"
              placeholder="Email"
            />
            {isError && !recipientContactInformation && (
              <label className='error-label'>This field is required</label>
            )}
          </div>

          {/*
           <div className='form-field'>           
          <div className='form-field'>           
           <div className='form-field'>           
          <div className='form-field'>           
           <div className='form-field'>           
              <input 
            <input 
              <input 
            <input 
              <input 
                name="recipientContactPhone"
                value={recipientContactPhone}
                onChange={this.onChange}
                type="tel"
                placeholder="Phone Number"
              />
              {isError && !recipientContactPhone && (
                <label className='error-label'>This field is required</label>
              )}
            </div>          
          </div>
            </div>          
          </div>
            </div>          
          */}

          <div className='form-field'>              
            <label>Shipping Address</label>         
            <div className="double-input">
              <input
                name="recipientFirstName"
                value={recipientFirstName}
                onChange={this.onChange}
                type="text"
                placeholder="First Name"
              />
              <input
                name="recipientLastName"
                value={recipientLastName}
                onChange={this.onChange}
                type="text"
                placeholder="Last Name"
              />
            </div>
            {isError && (!recipientFirstName || !recipientLastName) && (
              <label className='error-label'>This field is required</label>
            )}
          </div>

          <div className='form-field'>           
            <input
              name="recipientAddress1"
              value={recipientAddress1}
              onChange={this.onChange}
              type="text"
              placeholder="Address"
            />
            {isError && !recipientAddress1 && (
              <label className='error-label'>This field is required</label>
            )}
          </div>          

          <div className='form-field'>           
            <input
              name="recipientPhone"
              value={recipientPhone}
              onChange={this.onChange}
              type="text"
              placeholder="Phone"
            />
            {isError && !recipientPhone && (
              <label className='error-label'>This field is required</label>
            )}
          </div>  

          <div className='form-field'>           
            <input
              name="recipientCity"
              value={recipientCity}
              onChange={this.onChange}
              type="text"
              placeholder="City"
            />
            {isError && !recipientCity && (
              <label className='error-label'>This field is required</label>
            )}
          </div>  

          <div className='form-field'>           
            <div className="select_wrap">
              <select 
                name="recipientState"
                onChange={this.onChange}
                placeholder="State"
                defaultValue="">
                <option value="" disabled hidden>State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            {isError && !recipientState && (
              <label className='error-label'>This field is required</label>
            )}
          </div>

          <div className='form-field'>           
            <input
              name="recipientZipCode"
              value={recipientZipCode}
              onChange={this.onChange}
              type="text"
              placeholder="Zipcode"
            />
            {isError && !recipientZipCode && (
              <label className='error-label'>This field is required</label>
            )}
          </div>  



          {organization && (
            <div className='form-field'>  
                <label>Meals Per Delivery</label>
                <div className="select_wrap">            
                  <select 
                    name="recipientMeals"
                    onChange={this.onChange}
                    placeholder="Choose how many meals you’d like in each delivery"
                    defaultValue="">
                    <option value="" disabled hidden>Select</option>
                    <option value="48">48 meals(12 family meals)</option>
                    <option value="96">96 meals(24 family meals)</option>
                    <option value="144">144 meals(36 family meals)</option>
                    <option value="288">288 meals(72 family meals)</option>
                  </select>
                </div>
            </div>
          )}

          {organization && (
            <div className='form-field'>  
              <label>Desired Frequency</label>
              <div className="select_wrap">            
                <select 
                  name="recipientFrequency"
                  onChange={this.onChange}
                  placeholder="Select your preferred frequency for deliveries"
                  defaultValue="">
                  <option value="" disabled hidden>Select</option>
                  <option value="weekly">Weekly</option>
                  <option value="biweekly">BiWeekly</option>
                  <option value="monthly">Monthly</option>              
                </select>
              </div>
            </div>
          )}

          <div className='form-field'>  
            <label>Delivery Preferences and Notes</label>
            <textarea name="recipientNotes"
              value={recipientNotes} 
              onChange={this.onChange}
              placeholder="Please notate how often you'd like meals to be sent."
              className="notes"
              >
            </textarea>
            <label className='notation'>Note: Meals are delivered as family meals (12 family meals per delivery) and only sent once enough funds have been gifted to the registery.</label>
          </div>

          <div>
            <button type="submit">
              Complete Setup
            </button>            
          </div>
          {errorCampaign && <p className="error_message">{errorCampaign}</p>}
        </form>
      </div>
    </div>
    );
  }

  render() {
    const {
      accImg,
      name,
      campaignSetupStep,
      isError
    } = this.state;

    const srcPath = `https://www.shareasale.com/sale.cfm?tracking=${this.campaignId}&amount=0.00&merchantID=127255&transtype=lead`;
    return (
      <div>
        <div className="page-banner Content no-border">
          {accImg && <img className="account-img" src={accImg} />}
          <div className="account-content">
            <h2>{name}</h2>
            {/*<p>{this.props.authUser.email}</p>*/}
          </div>
        </div>
        <div className="get-started-title">
          Meal Registry Setup
          <div className="get-started-step">
          Step {campaignSetupStep} of 2
          </div>
        </div>


        {campaignSetupStep==1 && this.renderStep1()}

        {campaignSetupStep==2 && this.renderStep2()} 

        <img src={srcPath} width="1" height="1"></img>
      </div>
    );
  }
}


const authCondition = authUser => !!authUser;
const CompleteSetupForm = withRouter(withFirebase(CompleteSetupFormBase));

export default withAuthorization(authCondition)(CompleteSetupPage);

export { CompleteSetupForm };
