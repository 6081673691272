import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import SignInPage from '../SignIn';
import SignUpPage from '../SignUp';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ProfilesPage from '../Profiles';
import SettingsPage from '../Settings';
import DashboardPage from '../Dashboard';
import LandingPage from '../Landing';
import WhyPage from '../Why';
import PasswordForgetPage from '../PasswordForget';
import CompleteSetupPage from '../CompleteSetup';
import DonationsPage from '../Donations';
import CampaignsPage from '../Campaigns';
import CampaignOverviewPage from '../CampaignOverview';
import ViewCampaignPage from '../ViewCampaign';
import ContactPage from '../Contact';
import AmbassadorSignUp from '../AmbassadorSignUp';
import NewbornPage from '../Category/newborn';
import AboutPage from '../About';
import NotFoundPage from '../404';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';


const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
    h1: {
      marginTop: '0',
      marginBottom: '12px',    
      fontSize: '32px',
      fontWeight: 500,
      letterSpacing: 'normal',
      color: '#000000'
    }
  }
});

const App = (props) => (
  <Router>
    <ThemeProvider theme={theme}>
      <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route exact path={ROUTES.PROFILES} component={ProfilesPage} />
      <Route exact path={ROUTES.SETTINGS} component={SettingsPage} />
      <Route exact path={ROUTES.DASHBOARD} component={DashboardPage} />
      <Route exact path={ROUTES.CAMPAIGNS} component={CampaignsPage} />
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route exact path={ROUTES.WHY} component={WhyPage} />
      <Route exact path={ROUTES.DONATIONS} component={DonationsPage} />
      <Route exact path={ROUTES.DONATION} component={DonationsPage} />
      <Route exact path={`${ROUTES.COMPLETE_SETUP}/:id`} component={CompleteSetupPage}/>
      <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
      <Route exact path={ROUTES.CAMPAIGN_OVERVIEW} component={CampaignOverviewPage} />
      <Route exact path={ROUTES.VIEW_CAMPAIGN} component={ViewCampaignPage} />
      <Route exact path={ROUTES.CONTACT} component={ContactPage} />
      <Route exact path={ROUTES.ABOUT} component={AboutPage} />      
      <Route exact path={ROUTES.AMBASSADOR_SIGN_UP} component={AmbassadorSignUp} />      
      <Route exact path={ROUTES.NEWBORN} component={NewbornPage} />  
    </ThemeProvider>
  </Router>
);

export default withAuthentication(App);
