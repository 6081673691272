import React from 'react';
import { withFirebase } from '../Firebase';
import {ExitToApp}  from '@material-ui/icons';

const SignOutButton = ({ firebase }) => (
  <a
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      color="default"
      onClick={firebase.doSignOut}
    >
    <ExitToApp /> Log out
  </a>
);

export default withFirebase(SignOutButton);
