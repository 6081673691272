import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography, Grid, LinearProgress } from '@material-ui/core';
import emptystate from "../../images/emptystate.svg";
import deneaneImg from "../../images/dineane.jpeg";
import {getDaysLeft, getDaysDiff} from '../Utils/functions';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    campaignStatusWrapper: {
      margin: "10px 0 20px"
    },
    campaignStatusBox: {
      display: "flex",
      "&>:first-child": {
        borderRight: 'solid 0.5px #506b75'
      },
      "&>:last-child": {
        borderLeft: 'solid 0.5px #506b75'
      },
    },
    campaignStatusItem: {
      width:'33.333%',
    },
    campaignStatusTitle: {
      fontFamily: 'OpenSans',
      fontSize: '12px',
      fontWeight: '600',
      letterSpacing: '0px',
      textAlign: 'center',
      color: '#506b75',
      textTransform: 'uppercase'
    },
    campaignStatusValue: {
      fontFamily: 'OpenSans',
      fontSize: '32px',
      fontWeight: '600',
      letterSpacing: '0px',
      textAlign: 'center',
      color: '#1c976b'
    },
    campaignBottom: {
        marginTop: '10px',
        textAlign: 'center'
    },
    progressPrimaryNormal: {
      height: '6px',
      borderRadius: '0px',
      backgroundColor: 'rgba(66, 82, 90, 0.64)'
    },   
    progressBarNormal: {
      height: '6px',
      borderRadius: '0px',
      backgroundColor: '#fbcd25'
    },  
    campaignStatusBar: {
      marginBottom: '20px'
    },
    campaignCard: {
        width: '100%',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'rgba(66, 82, 90, 0.3)',
        borderRadius: '6px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        overflow: 'hidden'
    },
    campaignImg: {
        position: 'relative',
        width: '100%',
        paddingTop: '76%',
        '& img': {
            position: 'absolute',
            left: '0',
            top: '0',
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    },    
    campaignImgEmpty: {
        position: 'relative',
        width: '100%',
        paddingTop: '76%',
        '& img': {
            position: 'absolute',
            left: '0',
            top: '0',
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        }
    },
    campaignInfo: {
        padding: '30px 25px'
    },
    campaignHeadingBox: {
        minHeight: '50px',
        textAlign: 'center'
    },
    campaignHeadingBox2: {
        textAlign: 'center',
        marginBottom: '10px'
    },
    campaignHeading: {
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: '600',
        color: '#42525a',
        fontSize: '22px',
        lineHeight: '1.18'
    },
    campaignText: {
        fontFamily: "OpenSans",
        fontSize: '16px',
        letterSpacing: '0px',
        color: '#506b75',
        textAlign: 'center'        
    },
    button: {
        padding: '9px 10px',
        width: '100%',
        background: '#fed202',
        textTransform: 'Initial',
        fontFamily: 'OpenSans',
        fontSize: '14px',
        fontWeight: '600',        
        color: '#42525a'
    },
    button2: {
        marginTop: '20px',
        padding: '9px 10px',
        width: '100%',
        maxWidth: '300px',        
        background: '#fed202',
        textTransform: 'Initial',
        fontFamily: 'OpenSans',
        fontSize: '14px',
        fontWeight: '600',        
        color: '#42525a'
    },    
    button1: {
        padding: '15px 10px',
        width: '100%',
        background: '#1c976b',
        textTransform: 'Initial',
        fontFamily: 'OpenSans',
        fontSize: '16px',
        fontWeight: '600',        
        color: '#FFFFFF'
    },
    buttonLink: {
        padding: '15px 10px',
        width: '100%',
        fontFamily: 'OpenSans',
        fontSize: '16px',
        fontWeight: '600',  
        color: '#e7cb6d',
        textTransform: 'initial',
        marginBottom: "10px"
    },
    hostCard: {
        textAlign: "center",
        maxWidth: "420px",
        margin: "0 auto",
        marginTop: "70px",
        marginBottom: "90px",
        [theme.breakpoints.down('md')]: {
            marginTop: "40px",
            marginBottom: "50px",            
        }
    },
    hostImg: {
        margin: "20px auto",
        width: "120px",
        height: "120px",
        overflow: 'hidden',
        borderRadius: '100%',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    },
    hostTitle: {
        fontFamily: "Bely",
        fontSize: "16px",        
        letterSpacing: "0px",
        color: "#42525a"
    },
    hostHeading: {
        fontFamily: "Bely",
        fontSize: "36px",        
        letterSpacing: "0px",
        color: "#42525a"
    },
    hostInfo: {
        marginTop: "20px"
    },
    hostText: {
        fontFamily: "Bely",
        fontSize: "16px",   
        lineHeight: "1.25",
        letterSpacing: "0px",
        color: "#42525a"
    },
    hostButtons: {
        margin: "40px auto 0",
        maxWidth: '200px'
    }
  
})); 

const CampaginStatus = (props) => {
    const { campaign} = props;

    const classes = useStyles();

    let meals_donated = parseInt(parseFloat(campaign.orderTotalCampaignSum)*parseInt(campaign.boxCount)/parseFloat(campaign.orderDeliveryPrice));
    let recommandationGoals = campaign.recommandationGoals;
    let funded = meals_donated>recommandationGoals?100:Math.ceil(meals_donated*100.0/recommandationGoals);

    return (
        <Box className={classes.campaignStatusWrapper}>
            <Box className={classes.campaignStatusBar}>                
                <LinearProgress 
                    variant="determinate" 
                    value={funded} 
                    color="primary"  
                    classes={{colorPrimary: classes.progressPrimaryNormal, barColorPrimary:classes.progressBarNormal}}
                />
            </Box>
            <Box className={classes.campaignStatusBox}>
                <Box className={classes.campaignStatusItem}>
                    <Typography className={classes.campaignStatusValue}>{meals_donated}</Typography>
                    <Typography className={classes.campaignStatusTitle}>Servings<br/>Gifted</Typography>
                </Box>
                <Box className={classes.campaignStatusItem}>
                    <Typography className={classes.campaignStatusValue}>{funded}%</Typography>
                    <Typography className={classes.campaignStatusTitle}>Funded</Typography>
                </Box>
                <Box className={classes.campaignStatusItem}>
                    <Typography className={classes.campaignStatusValue}>{recommandationGoals / 4}</Typography>
                    <Typography className={classes.campaignStatusTitle}>Family Meals<br/> in Goal</Typography>
                </Box>
            </Box>
        </Box>
    );
}

const CampaginStatusSimple = (props) => {
    const { campaign} = props;

    const classes = useStyles();

    const dateRegistered = campaign.dateRegistered;
    const campaignLength = campaign.campaignLength;
    let strDaysLeft = getDaysLeft(dateRegistered, campaignLength);
    
    let meals_donated = parseInt(parseFloat(campaign.orderTotalCampaignSum)*parseInt(campaign.boxCount)/parseFloat(campaign.orderDeliveryPrice));
    let recommandationGoals = campaign.recommandationGoals;
    let funded = meals_donated>recommandationGoals ? 100 : (parseInt(meals_donated*100/recommandationGoals)?parseInt(meals_donated*100/recommandationGoals):0);

    return (
        <Box className={classes.campaignStatusWrapper}>
            <Box className={classes.campaignStatusBar}>                
                <LinearProgress 
                    variant="determinate" 
                    value={funded} 
                    color="primary"  
                    classes={{colorPrimary: classes.progressPrimaryNormal, barColorPrimary:classes.progressBarNormal}}
                />
            </Box>
            <Box className={classes.campaignStatusBox}>
                <Box className={classes.campaignStatusItem}>
                    <Typography className={classes.campaignStatusValue}>{meals_donated}</Typography>
                    <Typography className={classes.campaignStatusTitle}>Meals Gifted</Typography>
                </Box>
                <Box className={classes.campaignStatusItem}>
                    <Typography className={classes.campaignStatusValue}>{funded}%</Typography>
                    <Typography className={classes.campaignStatusTitle}>Funded</Typography>
                </Box>
                <Box className={classes.campaignStatusItem}>
                    <Typography className={classes.campaignStatusValue}>{recommandationGoals}</Typography>
                    <Typography className={classes.campaignStatusTitle}>Meals in Goal</Typography>
                </Box>
            </Box>
        </Box>
    );
}

const CampaginCard = (props) => {
    const { campaign, label} = props;

    const handleClickButton = () => {
        const campaignUrl = `/view/${campaign.id}`;
        window.location.href = campaignUrl;
    }
    
    const classes = useStyles();
    const campaignImg = campaign.campaignPhoto?campaign.campaignPhoto:emptystate;
    return (
        <Box className={classes.campaignCard}>
            <Box className={campaign.campaignPhoto?classes.campaignImg:classes.campaignImgEmpty}>
                <img src={campaignImg} alt={campaign.campaignTitle} />
            </Box>

            <Box className={classes.campaignInfo}>
                <Box className={classes.campaignHeadingBox}>
                    <Typography className={classes.campaignHeading}>{campaign.campaignTitle}</Typography>
                </Box>
                
                {/*<CampaginStatus classes={classes} campaign={campaign} />*/}
                <Box className={classes.campaignBottom}>
                    <Button 
                      variant="contained" 
                      onClick={handleClickButton} 
                      className={classes.button}>{label}</Button>
                </Box>
            </Box>            
        </Box>
    );
}

const CampaginSimpleCard = (props) => {
    const { campaign, label} = props;

    const handleClickButton = () => {
        const campaignUrl = `/view/${campaign.id}`;
        window.location.href = campaignUrl;
    }
    
    const classes = useStyles();
    const campaignImg = campaign.image?campaign.image:emptystate;
    return (
        <Box className={classes.campaignCard}>
            <Box className={campaign.image?classes.campaignImg:classes.campaignImgEmpty}>
                <img src={campaignImg} alt={campaign.title} />
            </Box>

            <Box className={classes.campaignInfo}>
                <Box className={classes.campaignHeadingBox2}>
                    <Typography className={classes.campaignHeading}>{campaign.title}</Typography>
                </Box>

                <Typography className={classes.campaignText}>{campaign.message}</Typography>
                <Box className={classes.campaignBottom}>
                    <Button 
                      variant="contained" 
                      onClick={handleClickButton} 
                      className={classes.button2}>{label}</Button>
                </Box>
            </Box>            
        </Box>
    );
}

const HostCard = (props) => { 
    const { data, label} = props;

    const handleClickButton1 = () => {
        const campaignUrl = `/view/gnpEYmvyAcfLalWmiol5`;
        window.location.href = campaignUrl;
    }

    const handleClickButton2 = () => {
        const campaignUrl = `/signup`;
        window.location.href = campaignUrl;
    }

    const classes = useStyles();
    const campaignImg = deneaneImg;
    return (
        <Box className={classes.hostCard}>
            <Box className={classes.hostHeadingBox}>
                <Typography className={classes.hostTitle}>Featured Host</Typography>
                <Typography className={classes.hostHeading}>Meet Dineane</Typography>
            </Box>

            <Box className={classes.hostImg}>
                <img src={campaignImg} alt="Featured Host" />
            </Box>

            <Box className={classes.hostInfo}>
                <Typography className={classes.hostText}>This superstar sponsored a family in need with a registry that provided over 300 meals in less than a month!</Typography>
            </Box>

                
            <Box className={classes.hostButtons}>
                <Button                     
                    onClick={handleClickButton1} 
                    className={classes.buttonLink}>See registry ></Button>
                <Button 
                    variant="contained" 
                    onClick={handleClickButton2} 
                    className={classes.button1}>Become a Host</Button>
            </Box>
            
        </Box>
    );
}

export {CampaginCard, CampaginSimpleCard, CampaginStatus, HostCard};