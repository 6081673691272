import React, { Component, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { API } from '../Utils/defines';
import Layout from '../Page';

const ContactPage = () => (
    <Layout additionalClass="no_wrap" menuType="no_menu" page="contact">
      <ContactPageWrap/>
    </Layout>
  );


const DEFAULT_STATE = {
    name: '',
    email: '',
    phone: '',
    message: ''
};

function ContactPageComponent(props) {
    const [data, setData] = useState(DEFAULT_STATE);
    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState("");
    const [msg, setMsg] = useState("");

    const handleChange = (event) => {
        let { name, value} = event.target;

        let _data = {...data};
        _data[name] = value;
        
        setData(_data);
        setError("");
        setMsg("");
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();
        setMsg("");
        let params = {...data};
        if (data.name === "") {
            setError("Incorrect Name");
            return;
        }

        if (data.email === "") {
            setError("Incorrect Email");
            return;
        }

        if (data.message === "") {
            setError("Incorrect Message");
            return;
        }

        setIsSending(true);
        fetch(API.sendTicket, {
          method: 'post',
          body: JSON.stringify(params)
        }).then(function(response) {
          return response.json();
        }).then(function(rsp) {
          console.log('Response:', rsp);
          setIsSending(false);
          if (rsp.success) {            
            setError("");
            setMsg("Message sent successfully!");
          } else {
            setError(rsp.msg);
            setMsg("");
          }
        });
    }

    return (
        <div className="view-contact">                    
            <div className="section" id="section_contact">
                <div className='section_heading'>
                    <h1>We are here to help</h1>
                    <div className='section_text'>
                        Whether you have a question about our services, need help with a registry, or just want to say hello — we have someone who will be more than happy to help! 
                    </div>
                </div>
                <form>
                    <div className='form-row'>
                        <input
                            name="name"
                            value={data.name}
                            onChange={handleChange}
                            type="text"
                            placeholder="Full Name"
                        />
                    </div>
                    <div className='form-row'>
                        <input
                            name="email"
                            value={data.email}
                            onChange={handleChange}
                            type="email"
                            placeholder="Email"
                        />                        
                    </div>
                    <div className='form-row'>
                        <input
                            name="phone"
                            value={data.phone}
                            onChange={handleChange}
                            type="text"
                            placeholder="Phone Number(optional)"
                        />                            
                    </div>
                    <div className='form-row'>
                        <textarea name="message" placeholder="Message" onChange={handleChange} rows="10" value={data.message} />
                    </div>

                    {error!=="" && (
                        <div className='form-row'>
                            <p className='error_text'>{error}</p>
                        </div>
                    )}
                    {msg!=="" && (
                        <div className='form-row'>
                            <p className='success_text'>{msg}</p>
                        </div>
                    )}

                    <div className='form-button'>
                        <button onClick={handleSubmit} disabled={isSending}>Submit</button>                        
                    </div>
                </form>
            </div>
        </div>
    );
}

const ContactPageWrap = withRouter(withFirebase(ContactPageComponent));
export default ContactPage;


