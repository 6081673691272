import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import footerLogo from "../../images/FooterLogo.svg";
import pdfPrivacy from "../../pdf/PrivacyStatement-v2.pdf";
import pdfTerms from "../../pdf/Sirvve_terms-and-conditions.pdf";

const Footer = () => (
    <div className="footer">
          <div className="page-container">
            <div className="flexbox">
              <div className="item-4-2-1">
                <div className="footer-heading-box">
                  <h3 className="footer-title">About</h3>
                </div>
                <div className="footer-content-box">
                  <div className="footer-link">
                    <a href={ROUTES.ABOUT} className="footer-text">About</a>
                  </div>
                  <div className="footer-link">
                    <a href={ROUTES.WHY} className="footer-text">Why Sirvve</a>                        
                  </div>
                </div>
              </div>

              <div className="item-4-2-1">
                <div className="footer-heading-box">
                  <h3 className="footer-title">Registries</h3>
                </div>
                <div className="footer-content-box">
                  <div className="footer-link">
                    <a href={ROUTES.SIGN_UP} className="footer-text">Start a Meal Registry</a>
                  </div>

                  <div className="footer-link">
                    <a href={ROUTES.CAMPAIGNS} className="footer-text">Featured Registries</a>                        
                  </div>

                  <div className="footer-link">
                    <a href={ROUTES.AMBASSADOR_SIGN_UP} className="footer-text">Ambassador Signup</a>
                  </div>
                </div>
              </div>

              <div className="item-4-2-1">
                <div className="footer-heading-box">
                  <h3 className="footer-title">Support</h3>
                </div>
                <div className="footer-content-box">
                  <div className="footer-link">
                    <a href={ROUTES.CONTACT} className="footer-text">Contact</a>
                  </div>
                  <div className="footer-link"> 
                    <a href={pdfTerms} target="_blank" className="footer-text">Terms & Conditions</a>                      
                  </div>
                  <div className="footer-link">
                    <a href={pdfPrivacy} target="_blank" className="footer-text">Privacy Policy</a>                        
                  </div>
                </div>
              </div>

              <div className="item-4-2-1">
                <div className="footer-heading-box">
                  <img src={footerLogo} alt="" />
                </div>
                <div className="footer-content-box">
                  <p className="footer-text">Sign up below to stay informed on Sirvve</p>

                  <form 
                    id="ic_signupform" 
                    captcha-key="6LeCZCcUAAAAALhxcQ5fN80W6Wa2K3GqRQK6WRjA" 
                    captcha-theme="light" 
                    new-captcha="true" 
                    method="POST" 
                    action="https://app.icontact.com/icp/core/mycontacts/signup/designer/form/?id=633&cid=1874151&lid=11315">
                      <div className="elcontainer normal hidden-label left-aligned">
                        <div className="form-header"><h3>Form Heading</h3></div>
                        <div className="sortables">
                          <div className="formEl fieldtype-input required" data-validation-type="1" data-label="Email" style={{display:'inline-block', width:'100%'}}>
                            <label>Email<span class="indicator required">*</span></label>
                            <input type="text" placeholder="ENTER YOUR EMAIL" name="data[email]" />
                          </div>
                          <div className="formEl fieldtype-checkbox required" dataname="listGroups" data-validation-type="1" data-label="Lists" style={{display:'none', width:'100%'}}>
                            <h3>Lists<span class="indicator required">*</span></h3>
                            <div className="option-container">
                              <label className="checkbox"><input type="checkbox" alt="Lists" name="data[listGroups][]" value="24789" checked="checked" />sirvve</label>
                            </div>
                          </div>
                          <div className="submit-container">
                            <input type="submit" value="Submit" class="btn btn-submit" />
                          </div>
                        </div>
                        <div className="hidden-container"></div>
                      </div>
                    </form>

                </div>
              </div>

            </div>
            <div className="footer-bottom-box">
              <div className="footer-text">© &nbsp;Sirvve</div>
            </div>
          </div>
        </div>
  );


export default Footer;


