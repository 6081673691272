import React, { useState, useEffect } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import _  from "lodash";

import { withAuthorization } from '../Session';
import Layout from '../Page';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

function Profiles(props) {  

  const db = props.firebase.getdb();

  useEffect(() => {
  }, []);

  
  return (
    <Layout page="profiles" >
      
    </Layout>
  );
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Profiles);

