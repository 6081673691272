import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';

import Layout from '../Page';

import img1 from "../../images/about_hero.jpg";
import img2 from "../../images/Fareed.jpg";
import img3 from "../../images/about-story.jpg";

const AboutPage = () => (
    <Layout additionalClass="no_wrap" menuType="no_menu" page="about">
      <AboutPageWrap/>
    </Layout>
  );


function AboutPageComponent(props) {

    return (
        <div className="view-about">

            {/* Section 13 */}
            <div className="section" id="section13">
              <div className="page-container">
                <div className="heading-box">
                  <h2 className="sectiontitle">About Us</h2>
                  <div className="sectionbigtext">Our focus is to make the act of giving easy!</div>
                </div>

                <div className="content-box">
                    <div className="image-box">
                        <img src={img1} alt="" className="full-image" />
                    </div>

                    <div className="text-box5">
                        <div className="sectionbigtext">
                        We support those who are going through life events by delivering ready to heat and eat meals to their doors.
                        </div>
                    </div>                                                                             
                </div>
              </div>
            </div>

            {/* Section 15 */}
            <div className="section" id="section15">
                <div className="page-container">
                    <div className="content-box">
                        {/*
                        <div className="flexbox">
                            <div className='text-box10'>
                                <h2 className="sectiontitle">Our Story</h2>
                                <p className='sectiontext'>
                                Our Founder is a 30-year veteran of the hospitality industry whose personal experience inspired this platform when her brother in law and business partner was diagnosed with Acute Myeloid Leukemia she saw firsthand the difficulty of getting a good meal on the dinner table.  
                                </p>

                                <p className='sectiontext'>
                                Along with the normal routines of bath time, dance, soccer, homework, carpools, and careers, now caretaking and battling illness upended anything resembling balance. Every chicken pie and lasagna that was delivered by a steady stream of heartfelt family and friends added another layer of pressure on the family. 
                                </p>
                            </div>

                            <div className='image-box'>
                                <img src={img3} alt="" className="full-image" />
                            </div>
                        </div>
                        */}
                        <h2 className="sectiontitle">Our Story</h2>
                        <div className='text-box11'>
                            <div className='text-box12'>
                                
                                <p className='sectiontext'>
                                Our Founder is a 30-year veteran of the hospitality industry whose personal experience inspired this platform.  When her brother in law and business partner was diagnosed with Acute Myeloid Leukemia in 2015 she saw firsthand the difficulty of getting a good meal on the dinner table.  Her twin sister and brother in law were a family of six, with children aged 2, 4, 14 and 15.   Along with the normal routines of bath time, dance, soccer, homework and careers, now caretaking and battling one of the most aggressive and unforgiving cancers – finding anything resembling balance seemed impossible.  Add in meal trains and drop-ins by well-intended family members and friends offering another chicken pot pie and well wishes.  The result is a busy and worn out family that is so grateful, but filled with feelings of being overwhelmed.
                                </p>
                            </div>

                            <div className='image-box' id="image-box5">
                                <img src={img3} alt="" className="full-image" />
                            </div>
                        </div>

                        <div className="text-box7">
                            <div className="sectionbigtext">
                            We know how important it is for community to be part of the healing process. We allow people to give easily and freely, without causing more stress.
                            </div>
                        </div>

                        <div className="text-box8">
                            <p className='sectiontext'>
                            <strong>How could the instinctive response to show care and concern for someone dealing with a life-changing event bring comfort and ease for </strong><a href="#">everyone.</a> This is when the idea of Sirvve was born.  Bringing together the classic meal trains, crowd sourcing and the ability to curate and deliver chef-crafted meals that are ready to eat in just minutes.  The benefits were there for both the givers and receivers. 
                            </p>

                            <p className='sectiontext'>
                            In these moments of change that life dishes out, what we have learned is all people want and need to heal with family and friends, to get some personal time back, and greater convenience.
                            </p>

                            <p className='sectiontext'>
                            <span>Sirvve supports…</span>
                            </p>

                            <p className='sectiontext'>
                            Events like maternity leave, recovery from hospitalizations, those facing chronic illness and those dealing with loss and mourning can now be met with flavorful meals that the whole family will love. Our service allows all of your family and friends (wherever they may be) to gift you meals – free of any worry of interrupting your new routine and/or burdening you with a drop in. 
                            </p>

                            <p className='sectiontext'>
                            <span>Sirvve celebrates…</span>
                            </p>

                            <p className='sectiontext'>
                            The bounty that generosity brings!  We humbly come to tables across town and across the country with our promise to serve you with pride and great food!
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>


            {/* Section 14 */}
            <div className="section" id="section14">
              <div className="page-container">
                <div className="content-box">
                    <div className="item-box">
                        <div className="flexbox">
                            <div className="item-half">
                                <div className="image-box">
                                    <img src={img2} alt="" className="full-image" />
                                </div>
                            </div>

                            <div className="item-half">
                                <div className="text-box6">
                                    
                                    <h2 className="sectiontitle">In Loving Memory</h2>
                                    <div className="sectiontext">
                                    We Sirvve in loving memory of Fareed Al-Khori.  He was a loving and devoted husband and father.  He was also a well-respected cardiologist and at any table a gracious and proud host to anyone in need. 
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                                                                              
                </div>
              </div>
            </div>            
        </div>
    );
}

const AboutPageWrap = withRouter(withFirebase(AboutPageComponent));
export default AboutPage;


