import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Layout from '../Page';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import {getPrices, getZipCodes} from '../Utils/campaigns';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const AmbassadorSignUpPage = () => (
  <Layout route="signup">
    <div className="signup_wrap">
        <SignUpForm />
    </div>
  </Layout>
);

const INITIAL_STATE = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  passwordOne: '',
  passwordTwo: '',
  ambassadorID: '',
  zipcode: '',
  category: '',
  step: 1,
  error: null,
  number_of_people: 1,
  period: '',
  restrictions: '',
  goalType:'',
  recommandationGoals: 0,
  meals: [],
  weekdays: [],
  zipcodes: [],
  organization:false,
  organizationName: '',
  agreed: false,
  isInvalid: false,
  isInvalidPhone: false,
};

const boxCount = 48;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
  componentDidMount() {
    const db = this.props.firebase.getdb();
    getZipCodes(db, this.loadZipCodes);
  }
  loadZipCodes = (zipcodes) => {
    
    const data = localStorage.getItem("campaign");
    if (data) {
      this.setState({...JSON.parse(data), zipcodes, step: 1, error:null, agreed: false});
    } else {
      this.setState({zipcodes: zipcodes});
    }
  }
  checkZipcodes = () => {
    let zipcode = this.state.zipcode;
    var exist = true;
    this.state.zipcodes.map(function(zip, index) {
      if(parseInt(zip.start) <= parseInt(zipcode.substring(0, 3)) &&  parseInt(zipcode.substring(0, 3)) <= parseInt(zip.end)) {          
        console.log(parseInt(zip.start), 'asdf');
        console.log(parseInt(zipcode.substring(0, 3)), 'asdf');
        console.log(parseInt(zipcode.substring(0, 3)), 'asdf');
        console.log(parseInt(zip.end), 'asdf');
        exist = false;
      }
    })  
    return exist;
  }

  onAddCampaign = () => {
    const { 
      firstname, 
      lastname, 
      organizationName,
      organization,
      recommandationGoals,
      goalType,
      email, 
      passwordOne, 
      ambassadorID, 
      zipcode, 
      category, 
      number_of_people, 
      period, 
      restrictions, 
      meals, 
      weekdays, 
      phone,
      passwordTwo,
      agreed,
      
    } = this.state;

    let username = firstname + ' ' + lastname;
    let that = this;
    const db = this.props.firebase.getdb();
    getPrices(db, zipcode, function(product_price, rate, zone){
      if(that.props.firebase.auth.currentUser) {
        db.collection('Campaigns').add({
          campaignAdmin: that.props.firebase.auth.currentUser.email, 
          ambassadorID: ambassadorID, 
          campaignCategory: category,
          campaignPeople: number_of_people,
          campaignLength: period,
          recipientDietaryPreferences: [],
          campaignMeals: meals, 
          campaignWeekdays: weekdays,
          campaignOrganization: organization,
          campaignGoalType:goalType,
          campaignStatus: 'Incomplete',
          orderFundsNeeded: boxCount * product_price,
          orderTotalMealsGiftedQty: 0,
          ambassador: true,
          orderTotalMealsGiftedSubtotal: 0,
          orderTotalDollarsGifted: 0,
          orderTotalCampaignSum: 0,
          dateRegistered: new Date(),
          datePublished: null,
          dateExpiration: null,
          boxCount: boxCount,
          orderTotalMealOrdersQty: 0,
          recommandationGoals: organization?parseInt(recommandationGoals):Math.ceil(number_of_people * meals.length * weekdays.length * Math.ceil(period/7) / boxCount) * boxCount,
          orderTotalOrderAmtSpent: 0,
          orderTotalMealAmtSpent: 0,
          orderTotalShippingAmt: 0,
          scheduleLastOrder: new Date(),
          scheduleNextOrder: null,
          orderDeliveryPrice: boxCount * product_price,
          orderShippingCost: 0,
          zone: zone
        })
        .then((docRef) => {
          that.setState({ ...INITIAL_STATE });
          that.props.history.push(`${ROUTES.COMPLETE_SETUP}/${docRef.id}`);
        })
      }
    })
  }

  onSubmit = () => {
    const { 
      firstname, 
      lastname, 
      organizationName,
      organization,
      recommandationGoals,
      goalType,
      email, 
      passwordOne, 
      ambassadorID, 
      zipcode, 
      category, 
      number_of_people, 
      period, 
      restrictions, 
      meals, 
      weekdays, 
      phone,
      passwordTwo,
      agreed,
      
    } = this.state;

    const isInvalid =
      passwordOne === '' ||
      passwordOne !== passwordTwo ||
      email === '' ||
      lastname === ''||
      firstname === '' ||
      phone === '' ||
      (organization && organizationName==='') ||
      !agreed;

    const strPhone = phone.replace(/\D/g,'');
    if(strPhone.length != 9 && strPhone.length != 10 && strPhone.length != 11 ) {
      this.setState({isInvalidPhone: true});
      return;
    }
    if (isInvalid) {
      this.setState({isInvalid: true});
      return;
    }

    this.setState({isInvalid: false, isInvalidPhone: false});

    let username = firstname + ' ' + lastname;
    let that = this;
    const db = this.props.firebase.getdb();
    getPrices(db, zipcode, function(product_price, rate, zone){
      if(that.props.firebase.auth.currentUser) {
        db.collection('Campaigns').add({
          campaignAdmin: that.props.firebase.auth.currentUser.email, 
          ambassadorID: ambassadorID, 
          ambassador: true,
          campaignCategory: category,
          campaignPeople: number_of_people,
          campaignLength: period,
          recipientDietaryPreferences: [],
          campaignMeals: meals, 
          campaignWeekdays: weekdays,
          campaignOrganization: organization,
          campaignGoalType:goalType,
          campaignStatus: 'Incomplete',
          orderFundsNeeded: boxCount * product_price,
          orderTotalMealsGiftedQty: 0,
          orderTotalMealsGiftedSubtotal: 0,
          orderTotalDollarsGifted: 0,
          orderTotalCampaignSum: 0,
          dateRegistered: new Date(),
          datePublished: null,
          dateExpiration: null,
          boxCount: boxCount,
          orderTotalMealOrdersQty: 0,
          recommandationGoals: organization?parseInt(recommandationGoals):Math.ceil(number_of_people * meals.length * weekdays.length * Math.ceil(period/7) / boxCount) * boxCount,
          orderTotalOrderAmtSpent: 0,
          orderTotalMealAmtSpent: 0,
          orderTotalShippingAmt: 0,
          scheduleLastOrder: new Date(),
          scheduleNextOrder: null,
          orderDeliveryPrice: boxCount * product_price,
          orderShippingCost: 0,
          zone: zone
        })
        .then((docRef) => {
          that.setState({ ...INITIAL_STATE });
          that.props.history.push(`${ROUTES.COMPLETE_SETUP}/${docRef.id}`);
        })
      }
      else {
        that.props.firebase
          .doCreateUserWithEmailAndPassword(email, passwordOne)
          .then(authUser => {
            // Create a user in your Firebase realtime database
            authUser.user.updateProfile({displayName: username})
            db.collection('Admins').add({
              email: email,
              phone: phone,
              name: username,
              organizationName: organizationName,
              zipcode: zipcode, 
              notificationDonation: false,
              notificationMealStop: true              
            })
              .then(() => {
                const customer_data = {
                  "first_name": firstname, 
                  "last_name": lastname,
                  "email": email,
                  "phone": "+1"+phone.replace(/\D/g,''),
                  "tags": "host"
                }
                fetch("https://sirvve-prod.herokuapp.com/customer" , {
                   headers: {
                     'Content-Type': 'application/json'
                   },
                mode: "no-cors",
                   method: 'POST',
                   body: JSON.stringify(customer_data)
                 })
                db.collection('Campaigns').add({
                  campaignAdmin: email, 
                  ambassadorID: ambassadorID, 
                  ambassador: true,
                  campaignCategory: category,
                  campaignPeople: number_of_people,
                  campaignLength: period,
                  recipientDietaryPreferences: [],
                  campaignMeals: meals, 
                  campaignWeekdays: weekdays,
                  campaignOrganization: organization,
                  campaignGoalType:goalType,
                  campaignStatus: 'Incomplete',
                  orderFundsNeeded: boxCount * product_price,
                  orderTotalMealsGiftedQty: 0,
                  orderTotalMealsGiftedSubtotal: 0,
                  orderTotalDollarsGifted: 0,
                  orderTotalCampaignSum: 0,
                  dateRegistered: new Date(),
                  datePublished: null,
                  dateExpiration: null,
                  boxCount: boxCount,
                  orderTotalMealOrdersQty: 0,
                  recommandationGoals: organization?parseInt(recommandationGoals):Math.ceil(number_of_people * meals.length * weekdays.length * Math.ceil(period/7) / boxCount) * boxCount,
                  orderTotalOrderAmtSpent: 0,
                  orderTotalMealAmtSpent: 0,
                  orderTotalShippingAmt: 0,
                  scheduleLastOrder: new Date(),
                  scheduleNextOrder: null,
                  orderDeliveryPrice: boxCount * product_price,
                  orderShippingCost: 0,
                  zone: zone
                })
                  .then((docRef) => {
                    that.setState({ ...INITIAL_STATE });
                    that.props.history.push(`${ROUTES.COMPLETE_SETUP}/${docRef.id}`);
                  })
              })
              .catch(error => {
                that.setState({ error });
              });
          })
          .catch(error => {
            that.setState({ error });
          });
      }
    })
    
  };

  onChange = event => {
    let {name, value, checked, type} = event.target;
    value = type==="checkbox"?checked:value;

    if (name === "monthly" && checked) {
      this.setState({ goalType: "monthly" });  
    } else if (name === "annual" && checked) {
      this.setState({ goalType: "annual" });  
    }

    this.setState({ [name]: value });
  };
  updateNumber = num => {
    this.setState({ number_of_people : (this.state.number_of_people+num)>0 ? this.state.number_of_people+num : 1})
  }
  saveSession = () => {
    localStorage.setItem('campaign', JSON.stringify(this.state));
  }

  nextStep = step => {
    if (step == 1) {

      if (this.state.ambassadorID!='' && this.state.zipcode!='') {
        if (this.checkZipcodes()){
          this.setState({error: {message: 'Zipcode invalid!'}})
        }
        else {
          this.setState({step: 2, error: {message: ''}});
          this.saveSession();
        }
          
      }
      else {
        this.setState({error: {message: 'please fill empty fields!'} })
      }
    } else if (step == 2) {
      console.log(this.state.meals.length>0 && this.state.weekdays.length>0 && this.state.period!='' );
      if (this.state.organization && this.state.meals.length>0  && this.state.goalType !== "" && this.state.recommandationGoals > 0) {
        this.setState({step: 4, error: {message: ''}})
        this.saveSession();
      } else if (!this.state.organization && this.state.meals.length>0 && this.state.weekdays.length>0 && this.state.period!='') {
        this.setState({step: 3, error: {message: ''}})
        this.saveSession();
      } else {
        this.setState({error: {message: 'please fill empty fields!'} })
      }
    } else if (step == 3) {

      this.saveSession();
      if (this.state.organization) {
        if(this.props.firebase.auth.currentUser)
          this.onAddCampaign();
        else
          this.setState({step: 3, isInvalid: false})
      } else {
        if(this.props.firebase.auth.currentUser)
          this.onAddCampaign();
        else
          this.setState({step: 4, isInvalid: false})
      }

    }
  }
  updateMeals = (str, status) => {
    let meals = this.state.meals;
    if(meals.includes(str) && !status) {
      this.setState({meals : meals.filter(e => e!=str)});
    }
    if(!(meals.includes(str)) && status) {
      meals.push(str);
      this.setState({meals : meals});
    }
    console.log(this.state.meals, this.state.weekdays);  
  }
  updateWeekdays = (str, status) => {
    let weekdays = this.state.weekdays;
    if(weekdays.includes(str) && !status) {
      this.setState({weekdays : weekdays.filter(e => e!=str)});
    }
    if(!(weekdays.includes(str)) && status) {
      weekdays.push(str);
      this.setState({weekdays : weekdays});
    }
    console.log(this.state.meals, this.state.weekdays);  
  }

  renderStep1() {
    const {
      zipcode,
      ambassadorID,
      organization,
      error,
    } = this.state;
    return (
      <div>
        <h1>Welcome Ambassadors</h1>
        <p>Setting up a registry is quick and easy. Just enter your info and click continue below.</p>
        <div className="form-content-wrap">
          <input
            name="ambassadorID"
            value={ambassadorID}
            onChange={this.onChange}
            type="text"
            placeholder="Enter ID (ie ufo, affiliate id)"
          />
          <input
            name="zipcode"
            value={zipcode}
            onChange={this.onChange}
            type="text"
            placeholder="Their ZIP code (for meal delivery)"
          />
          
          

          <button onClick={()=>this.nextStep(1)}>
            Next
          </button>
          {error && <p className="error_message">{error.message}</p>}
        </div>
      </div>
    );
  }

  renderStep2() {
    const {
      meals,
      weekdays,
      number_of_people,
      error,
      period,
    } = this.state;

    return (
      <div>
        <h1>Define meal needs</h1>
        <p>This will help us recommend a meal plan that fits their needs.</p>
        <p className="buttons_title">Meals per Day</p>
        <div className="form-content-wrap">
          <div className="buttons_wrap">
            <div className="button_wrap">
              <input type="checkbox" id="breakfast" value="Breakfast" checked={meals.includes("breakfast")} onClick={(e) => this.updateMeals('breakfast', e.target.checked)}/>
              <label htmlFor="breakfast">Breakfast</label>
            </div>
            <div className="button_wrap">
              <input type="checkbox" id="lunch" value="lunch"  checked={meals.includes("lunch")} onClick={(e) => this.updateMeals('lunch', e.target.checked)}/>
              <label htmlFor="lunch">Lunch</label>
            </div>
            <div className="button_wrap">
              <input type="checkbox" id="dinner" value="dinner"  checked={meals.includes("dinner")} onClick={(e) => this.updateMeals('dinner', e.target.checked)}/>
              <label htmlFor="dinner">Dinner</label>
            </div>
          </div>
          <p className="buttons_title">Days of the Week</p>
          <div className="buttons_wrap weekdays">
            <div className="button_wrap">
              <input type="checkbox" id="weekday_0" value="Sunday"  checked={weekdays.includes("Sunday")} onClick={(e) => this.updateWeekdays('Sunday', e.target.checked)}/>
              <label htmlFor="weekday_0">S</label>
            </div>
            <div className="button_wrap">
              <input type="checkbox" id="weekday_1" value="Monday"  checked={weekdays.includes("Monday")} onClick={(e) => this.updateWeekdays('Monday', e.target.checked)} />
              <label htmlFor="weekday_1">M</label>
            </div>
            <div className="button_wrap">
              <input type="checkbox" id="weekday_2" value="Tuesday"  checked={weekdays.includes("Tuesday")} onClick={(e) => this.updateWeekdays('Tuesday', e.target.checked)} />
              <label htmlFor="weekday_2">T</label>
            </div>
            <div className="button_wrap">
              <input type="checkbox" id="weekday_3" value="Wednesday"  checked={weekdays.includes("Wednesday")} onClick={(e) => this.updateWeekdays('Wednesday', e.target.checked)} />
              <label htmlFor="weekday_3">W</label>
            </div>
            <div className="button_wrap">
              <input type="checkbox" id="weekday_4" value="Thursday"  checked={weekdays.includes("Thursday")} onClick={(e) => this.updateWeekdays('Thursday', e.target.checked)} />
              <label htmlFor="weekday_4">T</label>
            </div>
            <div className="button_wrap">
              <input type="checkbox" id="weekday_5" value="Friday"  checked={weekdays.includes("Friday")} onClick={(e) => this.updateWeekdays('Friday', e.target.checked)} />
              <label htmlFor="weekday_5">F</label>
            </div>
            <div className="button_wrap">
              <input type="checkbox" id="weekday_6" value="Saturday"  checked={weekdays.includes("Saturday")} onClick={(e) => this.updateWeekdays('Saturday', e.target.checked)} />
              <label htmlFor="weekday_6">S</label>
            </div>
          </div>
          <p className="buttons_title">Number of People</p>
          <div className="buttons_wrap number_of_people">
            <span className="number_button" onClick={()=>this.updateNumber(-1)}>-</span>
            <span className="number">{number_of_people}</span>
            <span className="number_button" onClick={()=>this.updateNumber(1)}>+</span>
          </div>
          <div className="select_wrap">
            <select 
              name="period"
              onChange={this.onChange}
              placeholder="How long do they need meals for?"
              defaultValue={period}
              style={{color: period==''?'rgba(66, 82, 90, 0.64)':'black'}}>
              <option value="" disabled hidden>How long do they need meals for?</option>
              <option value={28}>4 weeks</option>
              <option value={42}>6 weeks</option>
              <option value={60}>2 months</option>
              <option value={90}>3 months</option>
              <option value={120}>4 months</option>
              <option value={180}>6 months</option>
            </select>
          </div>
          
          <button onClick={()=>this.nextStep(2)}>
            Next
          </button>
          {error && <p className="error_message">{error.message}</p>}
        </div>
      </div>
    );
  }

  renderOrganizationStep2 () {
    const {
      meals,
      weekdays,
      number_of_people,
      error,
      period,
      organization,
      goalType,
      recommandationGoals,
    } = this.state;

      return (
        <div>
          <h1>Define meal needs</h1>
          <p>This will help us recommend a meal plan that fits their needs.</p>
          <p className="buttons_title">Meals per Day</p>
          <div className="form-content-wrap">
            <div className="buttons_wrap">
              <div className="button_wrap">
                <input type="checkbox" id="breakfast" value="Breakfast" checked={meals.includes("breakfast")} onClick={(e) => this.updateMeals('breakfast', e.target.checked)}/>
                <label htmlFor="breakfast">Breakfast</label>
              </div>
              <div className="button_wrap">
                <input type="checkbox" id="lunch" value="lunch"  checked={meals.includes("lunch")} onClick={(e) => this.updateMeals('lunch', e.target.checked)}/>
                <label htmlFor="lunch">Lunch</label>
              </div>
              <div className="button_wrap">
                <input type="checkbox" id="dinner" value="dinner"  checked={meals.includes("dinner")} onClick={(e) => this.updateMeals('dinner', e.target.checked)}/>
                <label htmlFor="dinner">Dinner</label>
              </div>
            </div>
            <p className="buttons_title">Goal Preference</p>
            <div className="buttons_wrap">
              <div className="button_wrap">
                <input type="checkbox" id="monthly" name="monthly" value="monthly" checked={goalType==="monthly"} onClick={this.onChange}/>
                <label htmlFor="monthly">Monthly</label>
              </div>
              <div className="button_wrap">
                <input type="checkbox" id="annual" name="annual" value="annual"  checked={goalType==="annual"} onChange={this.onChange} />
                <label htmlFor="annual">Annual</label>
              </div>
            </div>
            <p className="buttons_title">Meals per goal</p>
            <div className="select_wrap">
              <select 
                name="recommandationGoals"
                onChange={this.onChange}
                placeholder="How long do they need meals for?"
                defaultValue={recommandationGoals}
                style={{color: period==''?'rgba(66, 82, 90, 0.64)':'black'}}>
                <option value="" disabled hidden>Choose the amount of meals you would like </option>
                <option value={48}>48 meals</option>
                <option value={96}>96 meals</option>
                <option value={144}>144 meals</option>
                <option value={192}>192 meals</option>
                <option value={240}>240 meals</option>
              </select>
            </div>
            
            <button onClick={()=>this.nextStep(3)}>
              Next
            </button>
            {error && <p className="error_message">{error.message}</p>}
          </div>
        </div>
      );

  }

  renderStep3() {
    const {
      meals,
      weekdays,
      number_of_people,
      error,
      period,
    } = this.state;

    var weekscount = '';
    if (period==28) weekscount = "4 weeks";
    if (period==42) weekscount = "6 weeks";
    if (period==60) weekscount = "2 months";
    if (period==90) weekscount = "3 months";
    if (period==120) weekscount = "4 months";
    if (period==180) weekscount = "6 months";

    const total_meal = number_of_people * meals.length * weekdays.length * Math.ceil(period / 7);
    const total_meals = Math.round( (total_meal + boxCount - 1)/ boxCount) * boxCount;
    const family_meals = total_meals/4;

    return (
      <div>
        <h1>Recommended Goal</h1>
        <p>Your goal will help dictate the amount of meals delivered and funds needed.</p>
        <div className="form-content-wrap">
          <div className="total_meals">
            {total_meals} meals
          </div>
          <p className="meals_comment">Provides approximately {family_meals} family meals (4 servings/meal) over {weekscount}</p>

          <button onClick={()=>this.nextStep(3)}>
            Next
          </button>
          {error && <p className="error_message">{error.message}</p>}
        </div>
      </div>
    );
  }

  renderStep4 () {
    return this.renderSignUpForm();
  }

  renderOrganizationStep3 () {
    return this.renderSignUpForm();
  }

  renderSignUpForm() {
    const {
      firstname,
      lastname,
      email,
      phone,
      passwordOne,
      passwordTwo,
      error,
      organization,
      organizationName,
      agreed,
      isInvalidPhone,
      isInvalid
    } = this.state;


    return (
      <div>
        <h1>Sign Up to Continue</h1>
        <p>Create an account to finish setting up your registry.</p>
        <div className="form-content-wrap">
          {isInvalid && <div className='form-error'>Please fill out correct information</div>}  
          {isInvalidPhone && <div className='form-error'>Phone number is not correct (must be 10 number digits)</div>}          
          <form onSubmit={(e)=>{e.preventDefault();this.onSubmit()}}>
            <div className="double-input">
              <input
                name="firstname"
                value={firstname}
                onChange={this.onChange}
                type="text"
                placeholder="First Name"
              />
              <input
                name="lastname"
                value={lastname}
                onChange={this.onChange}
                type="text"
                placeholder="Last Name"
              />
            </div>

            {organization && (
            <input
              name="organizationName"
              value={organizationName}
              onChange={this.onChange}
              type="text"
              placeholder="Organization Name"
            />
            )}

            <input
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
            <input
              name="phone"
              value={phone}
              onChange={this.onChange}
              type="text"
              placeholder="Phone Number"
            />
            <input
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
            <input
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm Password"
            />    

            <FormControlLabel
              className="form-content-check"
              control={
                <Checkbox
                  color="primary"
                  name="agreed"
                  checked={agreed}
                  onChange={this.onChange}
                />
              }
              label="I agree to the Terms and Conditions"
            />

            <button type="submit">
              Setup Profile
            </button>

            {error && <p className="error_message">{error.message}</p>}
          </form>
        </div>
      </div>
    );
  }

  render() {
    const {step, organization} = this.state;
    const totalSteps = organization?3:4;
    return (
      <div>
        <div className="step_wrap">
          {step > 1 && <span onClick={()=>this.setState({step: step-1})}>&lt; Previous Step</span>}
          <p>Step {step} of {totalSteps}</p>
        </div>
        <div className={"signup_content step_" + step}>
          {step==1 && this.renderStep1()}
          {step==2 && (organization?this.renderOrganizationStep2():this.renderStep2())}
          {step==3 && (organization?this.renderOrganizationStep3():this.renderStep3())}
          {step==4 && this.renderStep4()}
        </div>
      </div>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default AmbassadorSignUpPage;

export { SignUpForm, SignUpLink };
