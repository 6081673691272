import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Paper, Box, Typography } from '@material-ui/core'
import * as ROUTES from '../../constants/routes';
import Grid from '@material-ui/core/Grid';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Layout from '../Page';
import { getFeaturedCampaigns, getCampaignAsync } from '../Utils/campaigns';
import { HostCard, CampaginSimpleCard } from '../Page/campaign';
import { API } from '../Utils/defines';

import img1 from "../../images/why_hero.jpg";
import img2 from "../../images/newborn/Giving_Made_Easy.jpg";
import img3 from "../../images/newborn/Family Friendly.jpg";
import img4 from "../../images/newborn/Free and easy.jpg";
import img5 from "../../images/newborn/Convenient for everyone.jpg";

import img17 from "../../images/step1.gif";
import img18 from "../../images/step2.gif";
import img19 from "../../images/step3.gif";
import img20 from "../../images/step4.gif";

import imgTestimonial1 from "../../images/Cara_crop.png";
import imgTestimonial2 from "../../images/keesha.png";
import imgTestimonial3 from "../../images/kim-crop.png";
import quoteSvg from "../../images/quote.svg";

const useHeaderStyles = makeStyles((theme) =>({
    itemBox: {
        padding: "40px 50px",
        marginBottom: "0",

    },
    itemBox1: {
        padding: "40px 20px",
        [theme.breakpoints.down('md')]: {
            padding: "25px 10px",
        }
    },
    itemBox2: {
        maxWidth: '850px',
        margin: '50px auto'
    },
    imageBox1: {
        [theme.breakpoints.down('md')]: {
            marginTop: "15px"
        }
    },
    title: {
        fontFamily: "Bely",
        fontSize: "55px",
        lineHeight: "1.09",
        letterSpacing: "0px",
        color: "#42525a",
        [theme.breakpoints.down('md')]: {
            fontSize: "36px",
            lineHeight: "0.97"            
        }
    },
    subtitle: {
        fontFamily: "Bely",
        fontSize: "24px",        
        letterSpacing: "0px",
        color: "#1c976b"
    },
    text: {
        fontFamily: "OpenSans",
        fontSize: "20px",
        fontWeight: "300",
        lineHeight: "1.6",
        letterSpacing: "0px",
        color: "#42525a",
        marginTop: '20px',
        [theme.breakpoints.down('md')]: {
            fontSize: "16px",
            lineHeight: "1.25",
            marginTop: '10px',         
        }
    },
    textBox: {
        padding: '50px 50px 0 0',
        [theme.breakpoints.down('md')]: {
            padding: '0px 0px 0 0',
        }
    },
    title2: {
        fontFamily: "Bely",
        fontSize: "48px",        
        letterSpacing: "0px",
        textAlign: "center",
        color: "#1c976b"
    },
    divider: {
        height: '1px',
        background: '#42525a',
        maxWidth: '350px',
        margin: '50px auto'
    },
    buttonWrap: {
      textAlign: 'center',
      marginTop: '30px'
    },
    button1: {
      display: 'inline-block',
      fontFamily: 'OpenSans',
      width: '200px',
      margin: '0px 25px 20px',
      fontSize: '16px',
      fontWeight: '600',
      padding: '12px 10px',
      borderRadius: '6px',   
      border: '1px solid #fed202',
      lineHeight: '1.43',
      textAlign: 'center',
      textDecoration: 'none',
      textTransform: 'none',
      backgroundColor: '#fed202',
      color: '#333333',
      '&:hover':{
        backgroundColor: '#fed202',
        color: '#000000'
      }
    },
    button2: {
      display: 'inline-block',
      fontFamily: 'OpenSans',
      width: '200px',
      margin: '0px 25px 20px',
      fontSize: '16px',
      fontWeight: '600',

      padding: '12px 10px',
      borderRadius: '6px',   
      border: '1px solid #1c976b',
      lineHeight: '1.43',
      textAlign: 'center',
      textDecoration: 'none',
      textTransform: 'none',
      backgroundColor: '#1c976b',
      color: '#fff',
      '&:hover':{
        backgroundColor: '#1c976b',
        color: '#fff'
      }
    },
    button3: {
      fontFamily: 'OpenSans',
      padding: '14px 70px!important',
      fontSize: '14px!important',
      lineHeight: '1.43!important',
      color: '#fff!important',
      borderRadius: '6px!important',
      backgroundColor: '#1c976b!important',
    },
    contactSection: {
      maxWidth: '1000px',
      margin: '50px auto 40px',
      backgroundColor: 'rgba(240,240,240,.4)',
      padding: '50px 20px 70px',
      [theme.breakpoints.down('sm')]: {
        padding: '40px 20px 40px'
      }
    }

}));

const NewbornPage = () => (
    <Layout additionalClass="no_wrap" menuType="no_menu" page="landing">
      <NewbornPageWrap/>
    </Layout>
);

var testimonialSliderItems = [
    {
        name1: "-Cara L.",
        name2: "Newborn",
        description: "Just gave meals to my best friend and her daughter in Seattle….love you Jessica!   Never had to leave NC or wash a dish😊",
        image: imgTestimonial3
    },
    {
      name1: "-Keesha S.",
      name2: "",
      description: "I work in the school system and am frequently asked to contribute to and sometimes plan a meal train.   On Sirvve I was able to gift my meals and have started two campaigns for a couple of people I know that could really use the help.  This is my new “run to” for meal giving.    Thank you Sirvve! ",
      image: imgTestimonial2
    },
    {
      name1: "-Kim L.",
      name2: "Cancer",
      description: "I received a gift request link from a friend of mine hosting a campaign for someone battling cancer.  I had wanted to do something for the family and didn’t know what to bring or when to drop by.   Giving meals is sometimes hard because you never know what people really want.  I LOVE the idea of giving meals (or money) towards their meal campaign.  Super EASY.",
      image: imgTestimonial1
    }
];

var testimonialSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    fade: true
};

function TestimonialItem(props) {
    return (
        <Paper style={{height:'100%'}}>
          <div className="testimonial-box">
            <div className="content-box">
              <div className="image-box3">
                <img src={props.item.image} className="full-image" alt="" />
              </div>
              <div className="text-box2">
                <div className="image-box4">
                  <img src={quoteSvg} alt="" />
                </div>
  
                <div className="sectiontext">{props.item.description}</div>
              </div>
              <div className="text-box3">
                <div className="sectionbigtext">{props.item.name1}</div>
                <div className="sectionsmalltext">{props.item.name2}</div>
              </div>
            </div>
          </div>
        </Paper>
    )
}

const DEFAULT_STATE = {
    name: '',
    email: '',
    phone: '',
    message: ''
};

const featuredCampaigns = [
  {
    id: "hr8VTncRuQBkbFrqrLbm",
    image: "https://firebasestorage.googleapis.com/v0/b/sirvve.appspot.com/o/images%2F49b6653f-c62e-49aa-bd23-63f098cf033c.jpg?alt=media&token=68868136-e409-4390-af2e-e03fed9a1e88",
    title: "Tyla had a baby!",
    message: "76 Meals - Gifted in 3 days"
  },
  {
    id: "3l0CkpTq0MgDehBkIpEQ",
    image: "https://firebasestorage.googleapis.com/v0/b/sirvve.appspot.com/o/images%2F723936ef-240b-44ef-a997-97f06fb8d747.JPG?alt=media&token=752246b4-811d-4ac2-a30a-c43bbab7f5ad",
    title: "Welcome Baby Briggs",
    message: "103 Meals - Gifted in 28 days"
  },
  {
    id: "gnpEYmvyAcfLalWmiol5",
    image: "https://firebasestorage.googleapis.com/v0/b/sirvve.appspot.com/o/images%2F9b7cbd01-8b08-47c0-97c4-12a53070cf49.jpeg?alt=media&token=ce972271-9ea3-4485-ade3-66a18b7fa116",
    title: "Nolan Strong",
    message: "352 Meals - Gifted in 2 months"
  }
];

function NewbornPageComponent(props) {
    const [data, setData] = useState(DEFAULT_STATE);
    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState("");
    const [msg, setMsg] = useState("");

    const db = props.firebase.getdb();
    const classes = useHeaderStyles();

    useEffect(() => {

    }, []);


    const handleChange = (event) => {
        let { name, value} = event.target;

        let _data = {...data};
        _data[name] = value;
        
        setData(_data);
        setError("");
        setMsg("");
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();
        setMsg("");
        let params = {...data};
        if (data.name === "") {
            setError("Incorrect Name");
            return;
        }

        if (data.email === "") {
            setError("Incorrect Email");
            return;
        }

        setIsSending(true);
        fetch(API.sendTicket, {
          method: 'post',
          body: JSON.stringify(params)
        }).then(function(response) {
          return response.json();
        }).then(function(rsp) {
          console.log('Response:', rsp);
          setIsSending(false);
          if (rsp.success) {            
            setError("");
            setMsg("Message sent successfully!");
          } else {
            setError(rsp.msg);
            setMsg("");
          }
        });
    }

    const handleClickTalk = event => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        '#section_contact',
      );
  
      if (anchor) {
        anchor.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }

    const handleClickStart = event => {
      window.location.href = ROUTES.SIGN_UP;
    }

    const renderFeatureCampaigns = () => {
        const items = [...featuredCampaigns];
        return (
          <div className="section" id="newborn_section5">
            <div className="page-container">
              <div className="heading-box">
                <h2 className="sectiontitle">Discover campaigns</h2>
                <div className="sectionbigtext">Learn how people are supporting members of their communities with Sirvve.</div>
              </div>
  
              <Grid container spacing={6}>
                {
                  _.map(items, (campaign, index) => {
                    return (
                      <Grid item xs={12} sm={12} md={4}>
                        <CampaginSimpleCard key={`campaign_card_${campaign.id}`} campaign={campaign} label="View Registry" />                      
                      </Grid>
                    );
                  })
                }
              </Grid>
            </div>
          </div>
        );
    }

    const renderFeaturedHost = () => {
        return (
            <div className="section" id="newborn_section5">
                <div className="page-container">
                    <HostCard label="Become a Host" />

                    <Box className={classes.itemBox2}>
                        <Box className={classes.divider}></Box>
                        <Typography className={classes.title2}>98% of shared campaigns are fully funded within days of being started!</Typography>
                        <Box className={classes.divider}></Box>
                    </Box>

                    
                </div>
            </div>
        );
    }

    const renderContactForm = () => {
        return (
            <div className={`section view-contact ${classes.contactSection}`}>
                <div className='section_heading'>
                    <h1>Talk To An Expert</h1>
                    <div className='section_text'>
                    We will be happy to help! Enter your email below to talk with a Sirvve specialist. 
                    </div>
                </div>
                <form className="">
                    <div className='form-row'>
                        <input
                            name="name"
                            value={data.name}
                            onChange={handleChange}
                            type="text"
                            placeholder="Full Name"
                        />
                    </div>
                    <div className='form-row'>
                        <input
                            name="email"
                            value={data.email}
                            onChange={handleChange}
                            type="email"
                            placeholder="Email"
                        />                        
                    </div>
                    <div className='form-row'>
                        <input
                            name="phone"
                            value={data.phone}
                            onChange={handleChange}
                            type="text"
                            placeholder="Phone Number(optional)"
                        />                            
                    </div>
                    <div className='form-row'>
                        <textarea name="message" placeholder="Message" onChange={handleChange} rows="10" value={data.message} />
                    </div>

                    {error!=="" && (
                        <div className='form-row'>
                            <p className='error_text'>{error}</p>
                        </div>
                    )}
                    {msg!=="" && (
                        <div className='form-row'>
                            <p className='success_text'>{msg}</p>
                        </div>
                    )}

                    <div className='form-button'>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        disabled={isSending}
                        className={classes.button3} 
                        onClick={handleSubmit}>Submit</Button>                      
                    </div>
                </form>
            </div>            
        )
    }

    return (
        <div className="view-campaign">

            {/* Section 11 */}
            <div className="section" id="section11">
                <div className="flexbox">
                  <div className="item-45">
                      <div className="item-box6">
                          <div className={classes.itemBox}>
                            <div class="bannersubtitle">The New Way To Give</div>
                            <h1 class="bannertitle">A perfect <br/>gift for new parents!</h1>
                            <a href="/signup" class="banner-link">Start For Free Today</a>
                          </div>
                      </div>
                  </div>
                  <div className="item-55">
                    <div className="image-box">
                        <img src={img1} alt="" className="full-image" />
                    </div>
                  </div>
                </div>
            </div>

            <div className="section" id="newborn_section2">
                <div className="page-container">
                    <div className="heading-box">
                        <h2 className="sectiontitle green">Know someone who just had a baby…Want an easy way to gift them meals?</h2>
                        <div className="sectionbigtext">Set up a campaign for a new or expecting family in just minutes. All meals are cooked fresh and delivered conveniently to their doorstep. Set it up, share it, and everyone will pitch in :)</div>
                    </div>

                    <div className="content-box">
                        <div className="sectionbigtext">A Sirvve specialist will  help you setup a campaign for free.</div>

                        <div className={classes.buttonWrap}>
                          <Button variant="contained" color="primary" className={classes.button1} onClick={handleClickTalk}>Talk to An Expert</Button>
                          <Button variant="contained" color="primary" className={classes.button2} onClick={handleClickStart}>Start Myself</Button>
                        </div>

                    </div>
                </div>
            </div>

            {/* Section 4 */}
            <div className="section" id="section4">
              <Slider {...testimonialSettings}>
              {
                testimonialSliderItems.map( (item, i) => <TestimonialItem key={i} item={item} /> )
              }  
              </Slider>
            </div>

            {/* Section 5 */}
            {renderFeatureCampaigns()}

            {/* Section 12 */}
            <div className="section" id="newborn_section3">
              <div className="page-container">
                <div className="content-box">
                    <div className={classes.itemBox1}>
                        <div className="flexbox">
                            <div className="item-half">
                                <div className={classes.textBox}>
                                    <Typography className={classes.subtitle}>Giving Made Easy</Typography>
                                    <Typography className={classes.title}>Everyone can give meals</Typography>
                                    <Typography className={classes.text}>
                                    People from all over the world can purchase meals easily for others on Sirvve. Meals are delivered when and where they’re needed, and stay cool in sustainable insulated packages.
                                    </Typography>                                    
                                </div>
                            </div>
                            <div className="item-half">
                                <div className={classes.imageBox1}>
                                    <img src={img2} alt="" className="full-image" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.itemBox1}>
                        <div className="flexbox">
                            <div className="item-half">
                                <div className={classes.textBox}>                                
                                    <Typography className={classes.subtitle}>Family Friendly</Typography>
                                    <Typography className={classes.title}>Just how they like it</Typography>
                                    <Typography className={classes.text}>
                                    No gluten, no dairy, no soy? No problem. You can tailor your chef-crafted meals to any dietary needs and food preferences.
                                    </Typography>     
                                </div>
                            </div>
                            <div className="item-half">
                                <div className={classes.imageBox1}>
                                    <img src={img3} alt="" className="full-image" />
                                </div>
                            </div>
                        </div>
                    </div>                                                                             
                </div>
              </div>
            </div>

            {renderFeaturedHost()}

            {/* Section 12 */}
            <div className="section" id="newborn_section4">
              <div className="page-container">
                <div className="content-box">
                    <div className={classes.itemBox1}>
                        <div className="flexbox">
                            <div className="item-half">
                                <div className={classes.textBox}>
                                    <Typography className={classes.subtitle}>Free and easy!</Typography>
                                    <Typography className={classes.title}>Set up and share for free in a few minutes </Typography>
                                    <Typography className={classes.text}>
                                    Signup in under five minutes, spread the word with a click of a button, then sit back, relax and watch the gifts come in :) 
                                    </Typography>                                    
                                </div>
                            </div>
                            <div className="item-half">
                                <div className={classes.imageBox1}>
                                    <img src={img4} alt="" className="full-image" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.itemBox1}>
                        <div className="flexbox">
                            <div className="item-half">
                                <div className={classes.textBox}>                                
                                    <Typography className={classes.subtitle}>Convenient for everyone</Typography>
                                    <Typography className={classes.title}>Seamless support</Typography>
                                    <Typography className={classes.text}>
                                    We'll handle scheduling and contactless delivery, so you never have to worry whether you're intruding.
                                    </Typography>     
                                </div>
                            </div>
                            <div className="item-half">
                                <div className={classes.imageBox1}>
                                    <img src={img5} alt="" className="full-image" />
                                </div>
                            </div>
                        </div>
                    </div>                                                                             
                </div>
              </div>
            </div>

            {/* Section 6 */}
            <div className="section" id="section6">
              <div className="page-container">
                <div className="heading-box">
                  <h2 className="sectiontitle">How it works</h2>
                  <p className="sectiontext">Set it up, share it, and watch the gifts come in.</p>
                </div>
                <div className="flexbox offset-25">
                  <div className="item-4-2-1">
                    <div className="item-box" id="camp_1">
                      <div className="item-wrapper">
                        <div className="item-badge">1</div>
                        <div className="image-box max-200">
                          <img src={img17} alt="" className="full-image" />
                        </div>
                        <h3 className="sectionsmalltitle">You create a<br/> registry</h3>
                        <p className="sectiontext">Get started in just five minutes by filling in a few details. Easily manage preferences and updates as you go.</p>
                      </div>
                      
                    </div>
                  </div>

                  <div className="item-4-2-1">
                    <div className="item-box" id="camp_2">
                      <div className="item-wrapper">
                        <div className="item-badge">2</div>
                        <div className="image-box max-200">
                          <img src={img18} alt="" className="full-image" />
                        </div>
                        <h3 className="sectionsmalltitle">Everyone<br/> gives meals</h3>
                        <p className="sectiontext">Invite friends and loved ones to  give healthy, delicious meals that show someone they care. </p>                        
                      </div>
                      
                    </div>
                  </div>
                  
                  <div className="item-4-2-1">
                    <div className="item-box" id="camp_3">
                      <div className="item-wrapper">
                        <div className="item-badge">3</div>
                        <div className="image-box max-200">
                          <img src={img19} alt="" className="full-image" />
                        </div>
                        <h3 className="sectionsmalltitle">We prep, cook<br/> & deliver</h3>
                        <p className="sectiontext">Our chefs craft high-quality, family-style meals from scratch and freeze them at the peak of freshness for delivery right to recipients’ doors.</p>
                      </div>

                    </div>
                  </div>

                  <div className="item-4-2-1">
                    <div className="item-box" id="camp_4">
                      <div className="item-wrapper">
                        <div className="item-badge">4</div>
                        <div className="image-box max-200">
                          <img src={img20} alt="" className="full-image" />
                        </div>
                        <h3 className="sectionsmalltitle">They enjoy!</h3>
                        <p className="sectiontext">With a few minutes of reheating, your recipients will enjoy heartfelt, chef-cooked meals with easy cleanup.</p>
                      </div>
                      
                    </div>
                  </div>
                </div>


              </div>
            </div>

            {renderContactForm()}
        </div>
    );
}

const NewbornPageWrap = withRouter(withFirebase(NewbornPageComponent));
export default NewbornPage;


