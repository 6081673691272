import moment from 'moment';
const MAX_BATCH_CHARS = 41;

const formatDate = (_time) => {
    var date = _time.toDate();    
    var str = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
    return str;
}

const formatTime = (_time) => {
    var date = _time.toDate();    
    var str = date.getHours() + ':' + date.getMinutes();
    return str;
}

const getDaysDiff = (strDate) => {

    if (!strDate)
        return -1;
    let registered = moment(strDate.toDate());      
    let days_left = moment().diff(registered, 'days') ;
    return days_left;
}

const getDaysLeft = (strDate, period) => {
    if (!strDate)
        return '';
    
    let days_left = parseInt(period)  - getDaysDiff(strDate);
    let strDaysLeft = '';
    if (days_left <= 0)
      strDaysLeft = 'Expired';
    else 
      strDaysLeft  = `${days_left} ${days_left>1?" Days":"Day"} left`;
    return strDaysLeft;
}

export {formatDate, formatTime, getDaysDiff, getDaysLeft};