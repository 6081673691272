import React, { Component } from 'react';
import { Link, withRouter, useParams } from 'react-router-dom';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import _  from "lodash";
import ToggleSwitch from '../ToggleSwitch';
import { withFirebase } from '../Firebase';
import FileUploader from "react-firebase-file-uploader";
import campaign_img from "../../images/campaign.png";
import PersonOutlineSharpIcon from '@material-ui/icons/PersonOutlineSharp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AuthUserContext } from '../Session';
import { withAuthorization } from '../Session';
import Layout from '../Page';
import {getDaysLeft, getDaysDiff} from '../Utils/functions';
import {getCampaign, updateCampaign, updateCampaignPrivate, getPrices} from '../Utils/campaigns';
import {updateUserCampaignData} from '../Utils/users';
import {getGifts, getAllGifts} from '../Utils/gifts';
import Select from 'react-select'
import ShareCmapaignModal from '../Dashboard/shareCampaignModal';
import emptystate from "../../images/emptystate.svg";
import { CampaignCategories, CampaignMealOptions, CampaignDietaryOptions, CampaignDurationOptions } from '../Utils/defines';

const customStyles = {
}

const CampaignOverviewPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <Layout route="overview" title="John “Gramps” Jackson"  name={authUser.displayName?authUser.displayName.split(" ")[0]:''}>
        <CampaignOverviewWrap authUser={authUser}/>
      </Layout>
    )}
  </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
  selected: 'overview',
  campaignPhoto: null,
  errorCampaign: null,
  campaignTitle: '',
  openModal: false,
  campaignCategory: '',
  campaignDescription: '',
  campaignNote: '',
  campaignGoalType: '',
  campaignOrganization: false,
  recipientContactInformation: '',
  recipientContactPhone: '',
  recipientFirstName: '',
  recipientLastName: '',
  recipientAddress1: '',
  recipientAddress2: '',
  recipientPhone: '',
  recipientCity: '',
  recipientState: '',
  recipientZipCode: '',
  recipientNotes: '',
  recipientMeals: 0,
  recipientFrequency: '',  
  campaignPeople: 0,
  campaignCategory: '',
  campaignLength: '',
  publishState: false,
  campaignMeals: [],
  recipientDietaryPreferences: [],
  gifts: [],
  isPrivate: false,
  campaign: null
};
class CampaignOverview extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, uploading: false };
    this.storage = props.firebase.getstorage();
    this.db = this.props.firebase.getdb();
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      getCampaign(this.db, this.props.match.params.id, this.campaignData)
      getGifts(this.db, this.props.match.params.id, this.giftsData)
    }
    if (this.props.location.search == '?tab=4') {
      this.setState({selected: 'donations'})
    }
  }
  handleCloseDialog = () => {
    this.setState({openModal: false})
  }
  giftsData = (data) => {
    let gifts = data.sort(function(a,b){
      return new Date(b.createdDate) - new Date(a.createdDate)
    })
    this.setState({gifts: data})
  }
  campaignData = campaign => {
    var campaignMeals = [];
    campaign.campaignMeals.map(function(meal, index){
      campaignMeals.push({value: meal, label: meal});
    })
    var recipientDietaryPreferences = [];
    campaign.recipientDietaryPreferences.map(function(dietary, index){
      recipientDietaryPreferences.push({value: dietary, label: dietary});
    })
    console.log(campaign.recipientContactPhone)
    this.setState({
      campaignPhoto: campaign.campaignPhoto?campaign.campaignPhoto:null,
      campaignTitle: campaign.campaignTitle,
      campaignDescription: campaign.campaignDescription,
      recipientContactPhone: campaign.recipientContactPhone?campaign.recipientContactPhone:'',
      campaignCategory: campaign.campaignCategory,
      recipientFirstName: campaign.recipientFirstName,
      recipientLastName: campaign.recipientLastName,
      recipientAddress1: campaign.recipientAddress1,
      recipientAddress2: campaign.recipientAddress2,
      recipientPhone: campaign.recipientPhone,
      recipientCity: campaign.recipientCity,
      campaignPeople: campaign.campaignPeople,
      campaignLength: campaign.campaignLength,
      recipientState: campaign.recipientState,
      recipientZipCode: campaign.recipientZipCode,
      recipientNotes: campaign.recipientNotes,
      dateRegistered: campaign.dateRegistered,
      campaignStatus: campaign.campaignStatus,
      isPrivate: !!campaign.isPrivate,
      recipientDietaryPreferences: recipientDietaryPreferences,
      campaignMeals: campaignMeals,
      recipientContactInformation: campaign.recipientContactInformation,
      orderTotalCampaignSum: campaign.orderTotalCampaignSum,
      orderDeliveryPrice: campaign.orderDeliveryPrice,
      boxCount: campaign.boxCount,
      recommandationGoals: campaign.recommandationGoals,
      scheduleLastOrder: campaign.scheduleLastOrder,
      scheduleNextOrder: campaign.scheduleNextOrder,
      orderFundsNeeded: campaign.orderFundsNeeded,
      orderTotalMealOrdersQty: campaign.orderTotalMealOrdersQty,      
      campaignAdmin: campaign.campaignAdmin,
      campaign: campaign,

      recipientMeals: campaign.recipientMeals,
      recipientFrequency: campaign.recipientFrequency,
      campaignNote: campaign.campaignNote,
      campaignGoalType: campaign.campaignGoalType,
      campaignOrganization: campaign.campaignOrganization
    })
  }
  onSubmit = event => {
    const { } = this.state;
    

    event.preventDefault();
  }

  onChange = event => {
    const {name, value} = event.target;
    let _value;
    if (name === "campaignDescription") {
      _value = value.replace(/(?:\r\n|\r|\n)/g, '<br>');
    } else {
      _value = value;
    }

    this.setState({ [name]: value });
  }
  handleUploadStart = () => {
    this.setState({uploading:true});
  }
  handleProgress = (progress) => {
  }
  handleUploadError = (error) => {
    this.setState({uploading:false});
  }
  handleUploadSuccessPrimary = (filename) => {
    this.storage
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ campaignPhoto: url, uploading: false }));
  }

  completeCampaign = () => {
    const {
      campaignStatus
    } = this.state;

    if (!this.validateDeliveryInfo()) {
      alert('Please fill out delivery information.');
      return;
    }

   if (campaignStatus == 'Cancelled') {
      alert('Campaign is cancelled already!');
    } else {
      this.setState({publishState: true, campaignStatus: 'Active'});
      updateCampaign(this.db, this.props.match.params.id, {
        campaignStatus: 'Active'
      })
    }
  }

  onMakePrivate = val => {
    updateCampaignPrivate(this.db, this.props.match.params.id, val);
    this.setState({isPrivate: val});

  }

  validateDeliveryInfo = () => {
    const {
      campaignOrganization,
      recipientContactInformation,
      recipientFirstName,
      recipientLastName,
      recipientAddress1,
      //recipientContactPhone,
      recipientAddress2,
      recipientPhone,
      recipientCity,
      recipientState,
      recipientZipCode,
      recipientNotes,
      recipientMeals,
      recipientFrequency,
    } = this.state;

    if (!recipientContactInformation ||
      !recipientFirstName ||
      !recipientLastName ||
      !recipientAddress1 ||
      !recipientPhone ||
      !recipientCity ||
      !recipientState ||
      !recipientZipCode ||
      (campaignOrganization && !recipientMeals) ||
      (campaignOrganization && !recipientFrequency)
    ) {
      return false;
    }

    return true;
  }

  onSubmitDelivery = (event) => {
    event.preventDefault();
    if (!this.validateDeliveryInfo()) {
      this.setState({errorCampaign: 'Please fill empty inputs!'});
      return;
    }

    const {
      campaignOrganization,
      recipientContactInformation,
      recipientFirstName,
      recipientLastName,
      recipientAddress1,
      recipientContactPhone,
      recipientAddress2,
      recipientPhone,
      recipientCity,
      recipientState,
      recipientZipCode,
      recipientNotes,
      recipientMeals,
      recipientFrequency,
      campaignTitle,
      campaignCategory,
      campaignDescription,
      campaignStatus
    } = this.state;

    let that = this;
    let newCampaignStatus = campaignStatus?campaignStatus:'Inactive';
    if (campaignTitle && campaignCategory && campaignDescription && (campaignStatus!='Active')) {
      newCampaignStatus = 'Inactive'
    }
    getPrices(this.db, recipientZipCode,function(product_price ,rate, zone){
      updateCampaign(that.db, that.props.match.params.id, {
        recipientContactInformation: recipientContactInformation,
        recipientFirstName: recipientFirstName,
        recipientLastName: recipientLastName,
        //recipientContactPhone: recipientContactPhone,
        recipientAddress1: recipientAddress1,
        recipientPhone: recipientPhone,
        orderDeliveryPrice: that.state.boxCount * product_price + rate,
        orderFundsNeeded: that.state.boxCount * product_price + rate,
        recipientAddress2: recipientAddress2?recipientAddress2:'',
        recipientCity: recipientCity,
        recipientState: recipientState,
        recipientZipCode: recipientZipCode,
        recipientNotes: recipientNotes?recipientNotes:'',
        recipientMeals: recipientMeals?recipientMeals:'',
        recipientFrequency: recipientFrequency?recipientFrequency:'',
        zone: zone,
        campaignStatus: newCampaignStatus
      })
      that.setState({campaignStatus: newCampaignStatus})
    })

  }
  onSubmitManage = (event) => {
    event.preventDefault();

    const {
      campaignPhoto,
      campaignTitle,
      campaignCategory,
      campaignDescription,
      campaignLength,
      campaignStatus,
      campaignGoalType,
      recommandationGoals,
      campaignOrganization,
      campaignMeals,
      isPrivate
    } = this.state;

    
    let isError = !campaignPhoto || 
      !campaignTitle || 
      !campaignCategory || 
      !campaignDescription || 
      campaignMeals.length==0 ||
      (campaignOrganization && !campaignGoalType) ||
      (campaignOrganization && !recommandationGoals);

    if (isError) {
      this.setState({errorCampaign: 'Please fill empty inputs!'});
      return;
    }    
    this.setState({errorCampaign: ''});

    var data = {    
      campaignTitle,  
      campaignDescription,
      campaignCategory, 
      campaignPhoto,
      campaignMeals: campaignMeals.map(meal => meal.value),
      campaignLength,      
      isPrivate
    };

    var that = this;
    updateUserCampaignData(this.db, this.props.match.params.id, data)
    .then(() =>{
      that.setState({selected: 'overview'})
    })
  }

  mealChange = (meals) => {
    this.setState({campaignMeals: meals})
  }
  dietaryChange = (dietary) => {
    this.setState({recipientDietaryPreferences: dietary})
  }

  renderOverview = () => {
    const {
      campaignPhoto,
      campaignDescription,
      campaignLength,
      recipientContactInformation,
      recipientFirstName,
      recipientLastName,
      recipientAddress1,
      recipientPhone,
      recipientCity,
      recipientState,
      recipientZipCode,
      recipientNotes,
      recipientMeals,
      openModal,
      campaignStatus,
      publishState,
      pageStep,
      gifts,
      orderDeliveryPrice,
      orderFundsNeeded,
      orderTotalCampaignSum,
      dateRegistered,
      recommandationGoals,
      scheduleLastOrder,
      scheduleNextOrder,
      recipientDietaryPreferences,
      campaignMeals,
      campaign,
      campaignPeople,
      boxCount, 
      uploading,
      campaignGoalType,
      campaignOrganization,   
      isPrivate, 
    } = this.state;
    let that = this;
    var months = [ "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December" ];
    let meals_donated = parseInt(parseFloat(orderTotalCampaignSum)*parseInt(boxCount)/parseFloat(orderDeliveryPrice));
    var Difference_In_Time = (scheduleLastOrder ? scheduleLastOrder.toDate() : new Date()).getTime() - new Date().getTime();
    let nextDate = scheduleNextOrder ? scheduleNextOrder.toDate() : null;
    var nextDateText = nextDate!=null?`${months[nextDate.getMonth()]} ${nextDate.getDate()}th, ${nextDate.getFullYear()}`:"";   
    
    let lastOrderDate = scheduleLastOrder ? scheduleLastOrder.toDate() : null;
    var lastOrderText = lastOrderDate!=null?`${months[lastOrderDate.getMonth()]} ${lastOrderDate.getDate()}th, ${lastOrderDate.getFullYear()}`:"";

    const nextShipLimit = parseFloat(orderTotalCampaignSum)<parseFloat(orderDeliveryPrice);
    let strDaysLeft = getDaysLeft(dateRegistered, campaignLength);

    const daysLastOrder = getDaysDiff(scheduleLastOrder);

    const stCampaignLength = _.find(CampaignDurationOptions, {value: campaignLength})?_.find(CampaignDurationOptions, {value: campaignLength}).label:'';
    

    return (
    <div className="overview-content overview">
      <div className="campaigns-form">
        <div className="form-left">
          {campaignPhoto && <img src={campaignPhoto} style={{objectFit: 'contain', objectPosition: 'center'}} />}
          {!uploading && !campaignPhoto && <div className="placeholder-img"><img src={emptystate}/></div>}
          {uploading && <div className="placeholder-img"><CircularProgress /></div>}
        </div>
        {/*
        <div className="form-right">
          <div className="progress_bar">
            <div className="cur_progress" style={{width: (meals_donated>recommandationGoals ? "100" : parseInt(meals_donated*100/recommandationGoals)) + "%"}}>
            </div>
          </div>
          <p className="campaign_detail">
            <span>{gifts.length} gifts</span> {!campaignOrganization && meals_donated<recommandationGoals && <span> • {strDaysLeft}</span>}                    
          </p>
          <div className="campaign_info">
            <div className="campaign_donated">
              <p className="count">{meals_donated?meals_donated:0}</p>
              <p className="comment">Servings Gifted</p>
              
            </div>
            <div className="campaign_goal">
              <p className="count">{recommandationGoals/4}</p>
              <p className="comment">Family Meals  In Goal</p>
              <p className="desc">4 servings per family meal</p>
            </div>
            <div className="campaign_funded">
              <p className="count">{meals_donated>recommandationGoals?100:Math.ceil(meals_donated*100.0/recommandationGoals)}%</p>
              <p className="comment">Funded</p>
            </div>
          </div>
        </div>
        */}
      </div>


      <div className="campaign-button-wrap">
      {(campaignStatus === "active" || campaignStatus === "Active") ? (
        <div className="campaign-status-wrap">
          <a className="btn-meals" target="_blank" href={`/donation/${this.props.match.params.id}`}>Give Meals</a>   
          <button className="btn-share"  onClick={()=>this.setState({openModal: true})}>
            <PublishIcon/>
            Share Registry
          </button>
          <button className="btn-profile" onClick={()=>this.props.history.push('/view/'+campaign.id)}>
            View Registry
          </button>  
        </div>
      ):(
        <div className="campaign-status-wrap">
          <button className="btn-complete" onClick={() => this.completeCampaign()}>
            Complete Registry
          </button>  
        </div>
      )}
      </div>

      <div className="campaign-description-wrap">
        <label>Description</label>
        <p dangerouslySetInnerHTML={{__html: campaignDescription?campaignDescription.replaceAll('$#$%', '<br/>').replaceAll('\n', '<br/>'):''}}></p>
      </div>

      {/*
      <div className="campaign-private-wrap">
        <label>Want a little more privacy?</label>
        <div className="notification">
            <p className="content">Private Mode</p>
            <ToggleSwitch
              checked={isPrivate}
              onChange={this.onMakePrivate}
              id="publish_state"
            />
        </div>
        <p>
        Private campaigns can only be found by those with your unique campaign link and will not be featured on the campaigns page. We recommend keeping campaigns public so they can be discovered easily.
        </p>
      </div>
      */}

      <hr/>
      <div className="meals-detail-wrap">
        <div className="meals-detail">
          <div>
            <p className="title">
              Next Shipment
            </p>
            <p className="content">
              {nextShipLimit?`The next shipment will be scheduled as soon as 48 servings have been gifted`:(daysLastOrder>0 && !nextDate)?"Will be scheduled as soon as there are enough funds to cover the next delivery.":nextDate?`Your next shipment is scheduled to 
ship on ${nextDateText}.`:`Your next shipment is scheduled to 
ship on ${lastOrderText}.`}
            </p>
          </div>
        </div>
        <div className="campaign_info">

          <div className="campaign_goal">
            <p className="count">12</p>
            <p className="comment">Family Meals</p>
            <p className="desc">(48 servings total)</p>
          </div>
          {/** <div className="campaign_funded">
            <p className="count">${orderTotalCampaignSum}</p>
            <p className="comment">Amount Donated</p>
          </div>**/}
        </div>
      </div>
      <hr/>

      <div className="delivery-details">
        <div className='delivery-detail-pane'>
          <div className='delivery-heading'>
            <label>Campagin Preferences</label>
            <a href='#' onClick={()=>this.setState({selected: 'manage'})}>Edit</a>
          </div>

          {
            campaignOrganization?(
              <div>
                <div className='delivery-item'>
                  <label>Goal</label>
                  <div>{campaignGoalType}</div>
                  <p></p>
                </div>

                <div className='delivery-item'>
                  <label>Meals In Goal</label>
                  <div>{recommandationGoals}</div>
                </div>                        
              </div>
            ):(
              <div>
                <div className='delivery-item'>
                  <label>Duration</label>
                  <div>{stCampaignLength}</div>
                  <p></p>
                </div>

                <div className='delivery-item'>
                  <label>Number of People</label>
                  <div>{campaignPeople}</div>
                </div>                      
              </div>
            )
          }

          <div className='delivery-item'>
            <label>Meal Preferences</label>
            <div>{campaignMeals.length>0 && campaignMeals.map(meal => meal.value && meal.value.charAt(0).toUpperCase()+meal.value.slice(1)).join(', ')}</div>
          </div>

          <div className='delivery-item'>
            <label>Dietary Preferences</label>
            <div>{recipientDietaryPreferences.length>0 && recipientDietaryPreferences.map(dietary => dietary.value && dietary.value.charAt(0).toUpperCase()+dietary.value.slice(1)).join(', ')}</div>
          </div>
        </div>

        <div className='delivery-detail-pane'>
          <div className='delivery-heading'>
            <label>Delivery Information</label>
            <a href='#' onClick={()=>this.setState({selected: 'delivery'})}>Edit</a>
          </div>

          <div className='delivery-item'>
            <label>Your Order</label>
            <div>{recipientMeals}</div>
          </div>

          <div className='delivery-item'>
            <label>Recipient Contact Information</label>
            <div>{recipientContactInformation}<br/>{recipientPhone}</div>
          </div>

          <div className='delivery-item'>
            <label>Shipping Address</label>
            <div>
              {recipientFirstName} {recipientLastName}<br/>
              {recipientAddress1}<br/>
              {recipientPhone}<br/>

              {recipientCity && (`${recipientCity?recipientCity:''}, ${recipientState?recipientState:''}, ${recipientZipCode?recipientZipCode:''}`)}
            </div>
          </div>

        </div>

        <div className='delivery-detail-pane'>
          <div className='delivery-heading'>
            <label>Delivery Schedule</label>                  
          </div>

          {((!nextShipLimit && nextDate!==null) || (daysLastOrder>0 && !nextDate)) && (
          <div className='delivery-item'>
            <label>Last Shipment</label>
            <div className='delivery-copy'>
              <div>{lastOrderText}</div>
            </div>
            <p></p>
          </div>
          )}

          <div className='delivery-item'>
            <label>NEXT SCHEDULED SHIPMENT</label>
            <p></p>
            <p></p>
            <div className='delivery-copy'>
              <div>{nextShipLimit?`The next shipment will be scheduled as soon as 48 servings have been gifted`:(daysLastOrder>0 && !nextDate)?"Will be scheduled as soon as there are enough funds to cover the next delivery.": nextDate!==null?nextDateText:lastOrderText}</div>
            </div>
          </div>
        </div>
      </div>

    </div>      
    )
  }

  renderManage = () => {
    const {
      campaignPhoto,
      campaignTitle,
      campaignDescription,
      errorCampaign,
      campaignLength,
      recommandationGoals,
      recipientDietaryPreferences,
      campaignMeals,
      campaignCategory,
      uploading,
      campaignGoalType,
      campaignOrganization,   
      isPrivate, 
    } = this.state;
    let that = this;

    return (
      <div className="overview-content manage">
      <div className="campaign-form-wrap settings-form-wrap">
        <form onSubmit={this.onSubmitManage}>
          <div className="photo_wrap">
            {campaignPhoto && !uploading && <img src={campaignPhoto} />}
            {!uploading && !campaignPhoto && <div className="placeholder-img"><img src={emptystate}/></div>}
            {uploading && <div className="placeholder-img"><CircularProgress /></div>}

            <label className="uploader_button" id="uploader_button">
              {campaignPhoto? "Edit Cover Photo":"Upload Cover Photo"}
              <FileUploader
                id="uploader_button"
                accept="image/*"
                name="primary"
                randomizeFilename
                storageRef={this.storage.ref("images")}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccessPrimary}
                onProgress={this.handleProgress}
              />
            </label>
          </div>

          <div className='form-field'>          
            <label>Registry Title</label>
            <input
              name="campaignTitle"
              value={campaignTitle}
              onChange={this.onChange}
              type="text"
              placeholder="Enter Profile Name"
            />            
          </div>


          <div className='form-field'>
            <label>Category</label>
            <div className="select_wrap">
              <select 
                name="campaignCategory"
                onChange={this.onChange}
                placeholder="Select a Category"
                defaultValue="">
                <option value="" disabled hidden>Select a Category</option>
                {_.map(CampaignCategories, item => <option value={item.value}>{item.label}</option>)}
              </select>
            </div>            
          </div>

          <div className='form-field'>
            <label>Description</label>
            <textarea 
              name="campaignDescription"
              value={campaignDescription}
              onChange={this.onChange}
              placeholder="Explain why you are creating this registry.">

            </textarea>
          </div>

          {campaignOrganization?(
            <div>
              <label>Goal Preference</label>
              <div className="select_wrap">
                <select 
                  name="campaignGoalType"
                  onChange={this.onChange}
                  defaultValue={campaignGoalType}>                
                  <option value="monthly">Monthly</option>
                  <option value="annual">Annual</option>
                </select>
              </div>

              <label>Meals Per Goal</label>
              <div className="select_wrap">
                <select 
                  name="recommandationGoals"
                  onChange={this.onChange}
                  placeholder="How long do they need meals for?"
                  defaultValue={recommandationGoals}>
                  <option value={48}>48 meals</option>
                  <option value={96}>96 meals</option>
                  <option value={144}>144 meals</option>
                  <option value={192}>192 meals</option>
                  <option value={240}>240 meals</option>
                </select>
              </div>

            </div>
          ):(
            <div>
              <label>Duration</label>
              <div className="select_wrap">
                <select 
                  name="campaignLength"
                  onChange={this.onChange}
                  placeholder="Select a Duration"
                  value={campaignLength}>
                    {_.map(CampaignDurationOptions, option => <option value={option.value}>{option.label}</option>)}
                </select>
              </div>

              {
                /*
              <label>Number of People</label>
              <input
                name="campaignPeople"
                value={campaignPeople}
                onChange={this.onChange}
                type="number"
                min="1"
                placeholder="Enter Number of Peopel"
              />
                */
              }
            </div>
          )}

          <div className='form-field'>
            <label>Meal Preferences</label>
            <Select
              value={campaignMeals}
              isMulti
              styles={customStyles}
              name="meals"
              options={CampaignMealOptions}
              className="basic-multi-select"
              onChange= {that.mealChange}
              classNamePrefix="select"
            />
          </div>

          <div className='form-field'>
            <label>Dietary Preferences</label>

            <Select
              value={recipientDietaryPreferences}
              isMulti
              styles={customStyles}
              name="restrictions"
              options={CampaignDietaryOptions}
              className="basic-multi-select"
              onChange= {that.dietaryChange}
              classNamePrefix="select"
            />
          </div>

          <div className='form-field'>
            <label>Private Mode</label>

            <ToggleSwitch
              checked={isPrivate}
              onChange={this.onMakePrivate}
              id="publish_state"
            />
          </div>


          {/*campaignOrganization && 
          <div>
            <label>Internal Notes</label>
            <textarea 
              name="campaignNote"
              value={campaignNote}
              onChange={this.onChange}
              placeholder="">

            </textarea>
          </div>
          */}

          <div>
            <button type="submit">
              Save
            </button>
            
          </div>

          {errorCampaign && <p className="error_message">{errorCampaign}</p>}
        </form>
      </div>
    </div>
    );
  }

  renderDelivery = () => {
    const {
      errorCampaign,
      recipientContactInformation,
      recipientContactPhone,
      recipientFirstName,
      recipientLastName,
      recipientAddress1,
      recipientAddress2,
      recipientPhone,
      recipientCity,
      recipientState,
      recipientZipCode,
      recipientNotes,
      recipientMeals,
      campaignOrganization,   
      recipientFrequency,  
    } = this.state;

    return (
      <div className="overview-content delivery">
      <div className="campaign-form-wrap settings-form-wrap">
        <form onSubmit={this.onSubmitDelivery}>
          <label>Next Delivery Includes</label>

          <p className="order-scheduled">1 insulated Box</p>
          <p className="order-meals">12 Individually Packed  Family Meals</p>
          <p className="comment">(48 servings total/4 servings per family meal)</p>

          <label>Recipient Contact Information</label>
          <input
            name="recipientContactInformation"
            value={recipientContactInformation}
            onChange={this.onChange}
            type="email"
            placeholder="Email"
          />
{
  /*
          <input
            name="recipientContactPhone"
            value={recipientContactPhone}
            onChange={this.onChange}
            type="tel"
            placeholder="Phone Number"
          />
  */
}
          <label>Shipping Address</label>
          <div className="double-input">
            <input
              name="recipientFirstName"
              value={recipientFirstName}
              onChange={this.onChange}
              type="text"
              placeholder="First Name"
            />
            <input
              name="recipientLastName"
              value={recipientLastName}
              onChange={this.onChange}
              type="text"
              placeholder="Last Name"
            />
          </div>

          <input
            name="recipientAddress1"
            value={recipientAddress1}
            onChange={this.onChange}
            type="text"
            placeholder="Address"
          />
          <input
            name="recipientPhone"
            value={recipientPhone}
            onChange={this.onChange}
            type="text"
            placeholder="Phone"
          />
          <input
            name="recipientCity"
            value={recipientCity}
            onChange={this.onChange}
            type="text"
            placeholder="City"
          />
          <div className="select_wrap">
            <select 
              name="recipientState"
              onChange={this.onChange}
              placeholder="State"
              value={recipientState}>
              <option value="" disabled selected>State</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
          <input
            name="recipientZipCode"
            value={recipientZipCode}
            onChange={this.onChange}
            type="text"
            placeholder="Zipcode"
          />

          <div>
            <label>Preferred Delivery Frequency</label>
            <textarea name="recipientNotes"
              value={recipientNotes} 
              onChange={this.onChange}
              placeholder="Notes"
              className="notes"
              >
            </textarea>
          </div>

          {campaignOrganization && (
            <div>
              <label>Meals Per Delivery</label>
              <div className="select_wrap">                      
                <select 
                  name="recipientMeals"
                  onChange={this.onChange}
                  placeholder="Choose how many meals you’d like in each delivery"
                  defaultValue={recipientMeals}>
                  <option value="" disabled hidden>Select</option>
                  <option value="48">48 meals(12 family meals)</option>
                  <option value="96">96 meals(24 family meals)</option>
                  <option value="144">144 meals(36 family meals)</option>
                  <option value="288">288 meals(72 family meals)</option>
                </select>
              </div>


              <label>Desired Frequency</label>
              <div className="select_wrap">                      
                <select 
                  name="recipientFrequency"
                  onChange={this.onChange}
                  placeholder="Select your preferred frequency for deliveries"
                  defaultValue={recipientFrequency}>
                  <option value="" disabled hidden>Select</option>
                  <option value="weekly">Weekly</option>
                  <option value="biweekly">BiWeekly</option>
                  <option value="monthly">Monthly</option>              
                </select>
              </div>

            </div>
          )}

          <div>
            <button type="submit">
              Save
            </button>
            
          </div>
          {errorCampaign && <p className="error_message">{errorCampaign}</p>}
        </form>
      </div>
    </div>
    );
  }

  renderDonation = () => {
    const {
      curPage,
      pageNumber,
      selected,
      campaignPhoto,
      campaignTitle,
      campaignDescription,
      errorCampaign,
      campaignAdmin,
      campaignNote,
      orderTotalMealOrdersQty,
      campaignLength,
      campaignSetupStep,
      recipientContactInformation,
      recipientContactPhone,
      recipientFirstName,
      recipientLastName,
      recipientAddress1,
      recipientAddress2,
      recipientPhone,
      recipientCity,
      recipientState,
      recipientZipCode,
      recipientNotes,
      recipientMeals,
      openModal,
      campaignStatus,
      publishState,
      pageStep,
      gifts,
      orderDeliveryPrice,
      orderFundsNeeded,
      orderTotalCampaignSum,
      dateRegistered,
      recommandationGoals,
      scheduleLastOrder,
      scheduleNextOrder,
      recipientDietaryPreferences,
      campaignMeals,
      campaign,
      campaignPeople,
      campaignCategory,
      boxCount, 
      uploading,
      campaignGoalType,
      campaignOrganization,   
      recipientFrequency,  
      isPrivate, 
    } = this.state;
    let that = this;
    var months = [ "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December" ];
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    let product_price = parseFloat(parseFloat(orderDeliveryPrice) / parseFloat(boxCount)).toFixed(2)
    let meals_donated = parseInt(parseFloat(orderTotalCampaignSum)*parseInt(boxCount)/parseFloat(orderDeliveryPrice));
    var Difference_In_Time = (scheduleLastOrder ? scheduleLastOrder.toDate() : new Date()).getTime() - new Date().getTime();
    let nextDate = scheduleNextOrder ? scheduleNextOrder.toDate() : null;
    var nextDateText = nextDate!=null?`${months[nextDate.getMonth()]} ${nextDate.getDate()}th, ${nextDate.getFullYear()}`:"";   
    
    let lastOrderDate = scheduleLastOrder ? scheduleLastOrder.toDate() : null;
    var lastOrderText = lastOrderDate!=null?`${months[lastOrderDate.getMonth()]} ${lastOrderDate.getDate()}th, ${lastOrderDate.getFullYear()}`:"";

    const nextShipLimit = parseFloat(orderTotalCampaignSum)<parseFloat(orderDeliveryPrice);
    var Difference_In_Days = parseInt(Difference_In_Time / (1000 * 3600 * 24) + parseInt(campaignLength) * parseInt(boxCount) / parseInt(recommandationGoals));
    //var days_left = dateRegistered? parseInt((new Date() - dateRegistered.toDate())/24/60/60/1000 + parseInt(campaignLength)): 0
    let maxPageStep = Math.max(pageNumber - 4, 1);

    let strDaysLeft = getDaysLeft(dateRegistered, campaignLength);

    const daysLastOrder = getDaysDiff(scheduleLastOrder);

    const stCampaignLength = _.find(CampaignDurationOptions, {value: campaignLength})?_.find(CampaignDurationOptions, {value: campaignLength}).label:'';

    return (
      <div className="overview-content donations">
      {
        gifts.length == 0 && <label className="empty">Your campaign currently has no gifts.</label>
      }
      {
        gifts.length > 0 &&
        gifts.map((gift, index) => {
          let g_date = new Date(gift.createdDate)

          let giftText = '';
          if (gift.giftedDollarsPrice && gift.giftedDollarsPrice!=0 && gift.giftedMealQty && gift.giftedMealQty!=0) {
            giftText = "Gave $" + parseFloat(gift.giftedDollarsPrice).toFixed(2) + " and " + gift.giftedMealQty + " meals on " + months[g_date.getMonth()] + ' ' + g_date.getDate() + ", " + g_date.getFullYear()
          }
          else {
            if (gift.giftedDollarsPrice && gift.giftedDollarsPrice!=0){
              giftText = "Gave $" + parseFloat(gift.giftedDollarsPrice).toFixed(2) + " on " + months[g_date.getMonth()] + ' ' + g_date.getDate() + ", " + g_date.getFullYear()
            }
            if (gift.giftedMealQty && gift.giftedMealQty!=0) {
              giftText = "Gave " + gift.giftedMealQty + " meals on " + months[g_date.getMonth()] + ' ' + g_date.getDate() + ", " + g_date.getFullYear()
            }
          }
          return  (<div key={index} className="donation">
            <div className="user-img">
              <PersonOutlineSharpIcon/>
            </div>
            <div className="user-detail">
              <p className="title">
                {gift.anonymous?"Anonymous": (gift.campaignFirstName+' '+ gift.customerLastName)}
              </p>
              <p className="content">
                {giftText}
              </p>
            </div>
          </div>)
        }) 
      }
    </div>
    );
    
  }

  render() {
    const {
      selected,
      campaignTitle,
      openModal,
      campaign,
    } = this.state;

    return (
      <div>
        <ShareCmapaignModal campaign={campaign} open={openModal} handleClose={this.handleCloseDialog} />  
        <div className="page_title">{campaignTitle}</div>
        <div className="overview-submenu">
            <button className={selected=="overview"?"active":"inactive"} onClick={()=>this.setState({selected: 'overview', errorCampaign: ''})}>
                Overview
            </button>
            <button className={selected=="manage"?"active":"inactive"} onClick={()=>this.setState({selected: 'manage', errorCampaign: ''})}>
                Manage
            </button>
            <button className={selected=="delivery"?"active":"inactive"} onClick={()=>this.setState({selected: 'delivery', errorCampaign: ''})}>
                Delivery
            </button>
            <button className={selected=="donations"?"active":"inactive"} onClick={()=>this.setState({selected: 'donations', errorCampaign: ''})}>
                Gifts
            </button>
        </div>

        {selected=="overview" && this.renderOverview()}
        {selected=="manage" && this.renderManage()}
        {selected == 'delivery' && this.renderDelivery()}
        {selected == 'donations' && this.renderDonation()}
      </div>
    );
  }
}

const authCondition = authUser => !!authUser;
const CampaignOverviewWrap = withRouter(withFirebase(CampaignOverview));
export default withAuthorization(authCondition)(CampaignOverviewPage);
export { CampaignOverviewWrap };

