import {collections} from './defines';
import _  from "lodash";

const getUsers = (db, callback) => {
  db
  .collection(collections.USERS)  
  .onSnapshot((snapshot) => {
    if (snapshot.docs.length > 0) {
      const items = _.map(snapshot.docs, item => {
        let data = item.data();
        console.log(data)
        return {
          id: item.id,
          email: data.email,
          name: data.name,
          zipcode: data.zipcode,
          notificationDonation: data.notificationDonation,
          notificationMealStop: data.notificationMealStop,
          campaignLength: data.campaignLength
        };
      });
      console.log(items)
      callback(items);       
    }
  },
  error => {
    console.log(error);
    let items = [];
    callback(items);  
  }
  );
}


const getUser = (db, email, callback) => {
  db
    .collection(collections.USERS)
    .where("email", "==", email)
    .get()
    .then(snapshot => {
      let _item = {name: '', notificationDonation: false, notificationMealStop: true};
      if (snapshot.docs.length > 0) {
        snapshot.docs.forEach(doc => {
          if (doc.exists) {
            const data = doc.data();              
            _item.name = data.name;
            _item.notificationDonation = data.notificationDonation;
            _item.notificationMealStop = data.notificationMealStop;
            _item.id = doc.id;
            _item.accImg = data.accImg?data.accImg: null;
          }      
        });        
      }
      
      callback(_item);
    })
    .catch( error => {
      console.log('getUser: ', error);
    });
}
const updateUser = (db, item) => {
  db
    .collection(collections.USERS)
    .doc(item.id)
    .update({
        name: item.name,
        accImg: item.accImg
    });
}
const updateUserCampaign = (db, item, callback) => {
  db
    .collection(collections.CAMPAIGNS)
    .where("campaignAdmin", "==", item.email)
    .get()
    .then(snapshot => {
      if (snapshot.docs.length > 0) {
        snapshot.docs.forEach(doc => {
          if (doc.exists && doc.id == item.campaignId) {
          db
            .collection(collections.CAMPAIGNS)
            .doc(doc.id)
            .update({
              campaignPhoto: item.campaignPhoto,
              campaignTitle: item.campaignTitle,
              campaignCategory: item.campaignCategory,
              campaignDescription: item.campaignDescription,
              campaignNote: item.campaignNote,
              campaignMeals: item.campaignMeals,
              campaignGoalType: item.campaignGoalType,
              recommandationGoals: item.recommandationGoals,
              recipientDietaryPreferences: item.recipientDietaryPreferences
            });
          }      
        });        
      }
      
      callback('success');
    })
    .catch( error => {
      console.log(error);
    });
}

const updateUserCampaignData = (db, campaignId, data) => {
  return new Promise( (resolve, reject) => {
    db
    .collection(collections.CAMPAIGNS)
    .doc(campaignId)
    .update(data)
    .then(() => {
      resolve();
    })
  });
}


const updateUserRecipient = (db, item, callback) => {
  db
    .collection(collections.CAMPAIGNS)
    .where("campaignAdmin", "==", item.campaignAdmin)
    .get()
    .then(snapshot => {
      if (snapshot.docs.length > 0) {
        snapshot.docs.forEach(doc => {
          if (doc.exists && doc.id == item.campaignId) {
            
          db
            .collection(collections.CAMPAIGNS)
            .doc(doc.id)
            .update({
              recipientContactInformation: item.recipientContactInformation,
              recipientContactPhone: item.recipientContactPhone,
              recipientFirstName: item.recipientFirstName,
              recipientLastName: item.recipientLastName,
              recipientAddress1: item.recipientAddress1,
              recipientAddress2: item.recipientAddress2,
              recipientPhone: item.recipientPhone,
              recipientCity: item.recipientCity,
              recipientState: item.recipientState,
              recipientZipCode: item.recipientZipCode,
              recipientMeals: item.recipientMeals,
              recipientFrequency: item.recipientFrequency,
              orderDeliveryPrice: item.orderDeliveryPrice,
              orderFundsNeeded: item.orderFundsNeeded,
              recipientNotes: item.recipientNotes,
              campaignStatus: item.campaignStatus,
              zone: item.zone
            });
          }      
        });        
      }
      
      callback('success');
    })
    .catch( error => {
      console.log(error);
    });
}

const updateUserNotifications = (db, item) => {
  db
    .collection(collections.USERS)
    .doc(item.id)
    .update({
        notificationDonation: item.notificationDonation,
        notificationMealStop: item.notificationMealStop
    });
}
export { getUser, getUsers, updateUser, updateUserNotifications, updateUserCampaign, updateUserCampaignData, updateUserRecipient};