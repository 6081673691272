import {collections} from './defines';
import _  from "lodash";

const updateGift = (db, id, data) => {
  db
    .collection(collections.GIFTS)
    .doc(id)
    .update(data);
  alert('Gift updated!');
}
const getGift = (db, id, callback) => {
  db
    .collection(collections.GIFTS)
    .doc(id)
    .get()
    .then(snapshot => {
      callback(snapshot.data());
    })
    .catch( error => {
      console.log('getGift: ', error);
    });
}

const getAllGifts = (db, callback) => {
  db
  .collection(collections.GIFTS)  
  .onSnapshot((snapshot) => {

    if (snapshot.docs.length > 0) {
      const items = _.map(snapshot.docs, item => {
        let data = item.data();
        return {
          id: item.id,
          giftedDollarsPrice: data.giftedDollarsPrice,
          giftedMealsPrice: data.giftedMealsPrice,
          campaignFirstName: data.campaignFirstName,
          giftedMealQty: data.giftedMealQty,
          customerLastName: data.customerLastName,
          createdDate: data.createdDate,
          campaignId: data.campaignId
        };
      });
      callback(items);       
    }
  },
  error => {
    console.log(error);
    let items = [];
    callback(items);  
  }
  );
}
const getGifts = (db, campaignid, callback) => {
  db
  .collection(collections.GIFTS)  
  .where("campaignId", "==", campaignid)
  .onSnapshot((snapshot) => {

    if (snapshot.docs.length > 0) {
      const items = _.map(snapshot.docs, item => {
        let data = item.data();
        return {
          id: item.id,
          giftedDollarsPrice: data.giftedDollarsPrice,
          giftedMealsPrice: data.giftedMealsPrice,
          campaignFirstName: data.campaignFirstName,
          giftedMealQty: data.giftedMealQty,
          customerLastName: data.customerLastName,
          createdDate: data.createdDate,
          anonymous: data.anonymous?data.anonymous:false
        };
      });
      callback(items);       
    }
  },
  error => {
    console.log(error);
    let items = [];
    callback(items);  
  }
  );
}


export { getGift, updateGift, getGifts, getAllGifts};