export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/account';
export const SETTINGS = '/settings';
export const PASSWORD_FORGET = '/pw-forget';
export const COMPLETE_SETUP = '/complete-setup';
export const CAMPAIGN_OVERVIEW = '/campaign-overview/:id';
export const PROFILES = '/profiles';
export const DASHBOARD = '/dashboard';
export const CAMPAIGNS = '/campaigns';
export const LANDING = '/';
export const WHY = '/why';
export const NEWBORN = '/category/newborn';
export const DONATIONS = '/donations';
export const DONATION = '/donation/:id';
export const VIEW_CAMPAIGN = '/view/:id';
export const CONTACT = '/contact';
export const ABOUT = '/about-sirvve';
export const AMBASSADOR_SIGN_UP = '/ambassador-signup'
