import React from "react"
import Navigation from '../Navigation';
import Footer from  '../Footer';
export default function Layout(props) {
  
  return (
    <section data-page={props.page}>
        <Navigation route={props.route?props.route:'normal'} page={props.page} name={props.name} navClass={props.navClass} menuType={props.menuType}/>
        { 
            (props.additionalClass == 'header_add' || props.additionalClass == "no_wrap") && 
            <div>{props.children}</div>  
        }
        {
            props.additionalClass != 'header_add' && props.additionalClass != 'no_wrap' &&
            <div className="Content">
                { 
                    (props.route == 'account' || props.route == 'dashboard') &&
                    <div className="page_title account">{props.title}</div>
                }
                {   
                    props.route != 'overview' &&
                    <div className={(props.route?props.route:'') + " Container"}>{props.children}</div> 
                }
                {
                    props.route == 'overview' &&
                    <div className="overview">{props.children}</div>
                }      
            </div>
        }
        <Footer/>
    </section>
  )
}