import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import firebase from "firebase";
import Layout from '../Page';
import ToggleSwitch from '../ToggleSwitch';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import FileUploader from "react-firebase-file-uploader";
import CircularProgress from '@material-ui/core/CircularProgress';
import {getUser, updateUser, updateUserNotifications} from '../Utils/users';
import { AuthUserContext } from '../Session';
import { withAuthorization } from '../Session';
import emptystate from "../../images/emptystate.svg";

const SettingsPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <Layout route="account" title="Settings"  name={authUser && authUser.displayName ?authUser.displayName.split(" ")[0]:''}>
        <SettingsForm authUser={authUser}/>
      </Layout>
    )}
  </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
  name: '',
  currentPassword: '',
  newPassword: '',
  errorPassword: null,
  errorName: null,
  notificationDonation: false,
  notificationMealStop: true,
  accImg: null,
  user: null,
};

class SettingsFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, uploading: false };
    this.storage = props.firebase.getstorage();
    this.db = this.props.firebase.getdb();
    this.firebase = this.props.firebase;
    getUser(this.db, props.authUser.email, this.fetchUserData);
  }
  fetchUserData = item => {
    this.setState({user: item.id, accImg: item.accImg, notificationDonation: item.notificationDonation, notificationMealStop: item.notificationMealStop, name: item.name})
  }
  onSubmit = event => {
    const { } = this.state;
    

    event.preventDefault();
  }
  reauthenticate = (currentPassword) => {
    var user = firebase.auth().currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(
        this.props.authUser.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  }
  onSubmitName = event => {

    this.props.authUser.updateProfile({displayName: this.state.name});
    updateUser(this.db, {id: this.state.user, name: this.state.name, accImg: this.state.accImg})
    alert("Account updated!");
    event.preventDefault();
  }
  onSubmitPassword = event => {

    this.reauthenticate(this.state.currentPassword).then(() => {
      var user = firebase.auth().currentUser;
      user.updatePassword(this.state.newPassword).then(() => {
        alert("Password updated!");
      }).catch((error) => { this.setState({errorPassword: error}) });
    }).catch((error) => { this.setState({errorPassword: error}) });
    event.preventDefault();
  }
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }
  
  onNotificationDonationChange = val => {
    this.setState({notificationDonation: val});
    updateUserNotifications(this.db, {id: this.state.user, notificationDonation: val, notificationMealStop: this.state.notificationMealStop})
  }
  onNotificationMealStopChange = val => {
    this.setState({notificationMealStop: val});
    updateUserNotifications(this.db, {id: this.state.user, notificationDonation: this.state.notificationDonation, notificationMealStop: val})
  }
  handleUploadStart = () => {
    this.setState({uploading:true});
  }
  handleProgress = (progress) => {
  }
  handleUploadError = (error) => {
    this.setState({uploading:false});
  }
  handleUploadSuccessPrimary = (filename) => {
    this.storage
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ accImg: url, uploading:false }));
  }
  render() {
    const {
      name,
      currentPassword,
      newPassword,
      errorName,
      errorPassword,
      notificationDonation,
      notificationMealStop,
      accImg,
      uploading
    } = this.state;

    const isInvalidName =
      name === '';
    const isInvalidPassword =
      currentPassword === '' ||
      newPassword === '';
  console.log(this.props.authUser)
    
    return (
      <div>
        <div className="settings-form-wrap">
          <form onSubmit={this.onSubmitName}>
            <div className="photo_wrap">
              {accImg && !uploading && <img src={accImg} />}
              {!uploading && !accImg && <div className="placeholder-img"><img src={emptystate}/></div>}
              {uploading && <div className="placeholder-img"><CircularProgress /></div>}

              <label className="uploader_button" id="uploader_button">
                {accImg? "Edit Photo":"Upload Photo"}
                <FileUploader
                  id="uploader_button"
                  accept="image/*"
                  name="primary"
                  randomizeFilename
                  storageRef={this.storage.ref("images")}
                  onUploadStart={this.handleUploadStart}
                  onUploadError={this.handleUploadError}
                  onUploadSuccess={this.handleUploadSuccessPrimary}
                  onProgress={this.handleProgress}
                />
              </label>
            </div>
            <label>Name</label>
            <input
              name="name"
              value={name}
              onChange={this.onChange}
              type="text"
              placeholder="Name"
            />
            {errorName && <p className="error_message">{errorName.message}</p>}
            <button disabled={isInvalidName} type="submit">
              Update
            </button>
          </form>
          <div>
            <label>Password</label>
            <input
              name="currentPassword"
              value={currentPassword}
              onChange={this.onChange}
              type="password"
              placeholder="Current Password"
            />
            <input
              name="newPassword"
              value={newPassword}
              onChange={this.onChange}
              type="password"
              placeholder="New Password"
            />
            <div>
              {errorPassword && <p className="error_message">{errorPassword.message}</p>}
              <button disabled={isInvalidPassword} onClick={this.onSubmitPassword}>
                Update
              </button>
              <a className="forget_password" onClick={()=>this.props.history.push(ROUTES.PASSWORD_FORGET)}>
                Forgot password?
              </a>
            </div>
          </div>
          <div className="notifications-wrap" style={{display:'none'}}>
            <label>Notifications</label>
            <div className="notification">
              <p className="content">Notify me when I receive donations</p>
              <ToggleSwitch
                id="notification_donation"
                checked={notificationDonation}
                onChange={this.onNotificationDonationChange}
              />
            </div>
            <div className="notification">
              <p className="content">Notify me when meals are paused</p>
              <ToggleSwitch
                id="notification_meal_stop"
                checked={notificationMealStop}
                onChange={this.onNotificationMealStopChange}
              />
            </div>
            <p className="notification_comment">
              Meals only get delivered when there are enough donations to cover the next order. 
              You can select to automatically be billed if your account is low. <a href="#">Click to learn more</a>.
            </p>
          </div>
        </div>
      </div>
    );
  }
}


const authCondition = authUser => !!authUser;
const SettingsForm = withRouter(withFirebase(SettingsFormBase));

export default withAuthorization(authCondition)(SettingsPage);

export { SettingsForm };
