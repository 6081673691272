import React, { Component } from 'react';
import { Link, withRouter, useParams } from 'react-router-dom';
import _  from "lodash";
import ToggleSwitch from '../ToggleSwitch';
import { withFirebase } from '../Firebase';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Grid from '@material-ui/core/Grid';
import Layout from '../Page';
import {getDaysLeft} from '../Utils/functions';
import {getCampaign, updateCampaign, getAllCampaigns} from '../Utils/campaigns';
import {getUser, getUsers} from '../Utils/users';
import {getAllGifts} from '../Utils/gifts';
import img21 from "../../images/second-helpings.jpg";
import { Typography } from '@material-ui/core';
import {CampaginCard} from '../Page/campaign';

const CampaignsPage = () => (
  <Layout additionalClass="no_wrap" menuType="no_menu" page="view-campaign">
    <CampaignsWrap/>
  </Layout>
);
const INITIAL_STATE = {
  campaigns: [],
  gifts: [],
  profileName: '',
  sortByToggle: false,
  categoryToggle: false,
  sortBy: 'Most Recent',
  category: [],
  users: []
};

class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.profileNameInput = React.createRef();
    this.state = { ...INITIAL_STATE };
    this.storage = props.firebase.getstorage();
    this.db = this.props.firebase.getdb();
    this.searchBind = this.searchBind.bind(this)
  }

  componentDidMount() {
    getAllCampaigns(this.db, this.campaignData);
    getAllGifts(this.db, this.giftData);
    getUsers(this.db, this.userData);
  }
  campaignData = campaigns => {
    let activeCampaigns = campaigns.filter(cam => cam.campaignStatus == 'Active');

    this.setState({
      campaigns: activeCampaigns
    })
  }
  giftData = gifts => {
    this.setState({
      gifts: gifts
    })
  }
  userData = users => {
    this.setState({
      users: users
    })
  }
  searchBind = () => {
    this.setState({profileName: this.profileNameInput.current.value})
  }
  updateType = (typeStr) => {
    let category = this.state.category
    if(category.includes(typeStr)) {
      let indexStr = category.indexOf(typeStr);
      category.splice(indexStr, 1)
    }
    else {
      category.push(typeStr)
    }
    this.setState({category: category})
  }

  filterCampaigns = () => {

    let campaigns = [...this.state.campaigns];
    const users = [...this.state.users];
    const category = this.state.category;

    const searchKey = this.state.profileName.toLowerCase();
    const _campaigns = _.filter(campaigns, item => {
      const {recipientFirstName, recipientLastName, campaignTitle, campaignAdmin, campaignCategory, isPrivate} = item;

      if (isPrivate == true) {
        return false;
      }

      if ((category.length > 0) && !(category.includes(campaignCategory))){
        return false;
      }
      
      if (!!recipientFirstName && recipientFirstName.toLowerCase().includes(searchKey)) {
        return true;
      }
      
      if (!!recipientLastName && recipientLastName.toLowerCase().includes(searchKey)) {
        return true;
      }
      
      if (!!campaignTitle && campaignTitle.toLowerCase().includes(searchKey)) {
        return true;
      }

      const user = _.find(users, {email:campaignAdmin});
      if (!!user) {
        if (!!user.name && user.name.toLowerCase().includes(searchKey)) {
          return true;
        }
        if (!!user.email && user.email.toLowerCase().includes(searchKey)) {
          return true;
        }
      }

      return false;

    });
    return _campaigns;
  }

  render() {
    const {
      gifts,
      sortBy,
      sortByToggle,
      categoryToggle,
    } = this.state;
    let sortCampaigns = [];

    const campaigns = this.filterCampaigns();

    if (sortBy == 'A-Z')
      sortCampaigns = campaigns.sort((a,b) => (a.campaignTitle > b.campaignTitle) ? 1 : (a.campaignTitle < b.campaignTitle) ? -1 : 0 )
    else if (sortBy == 'Z-A')
      sortCampaigns = campaigns.sort((a,b) => (a.campaignTitle < b.campaignTitle) ? 1 : (a.campaignTitle > b.campaignTitle) ? -1 : 0 )
    else if (sortBy == 'Most Recent')
      sortCampaigns = campaigns.sort((a,b) => (a.dateRegistered.seconds > b.dateRegistered.seconds) ? -1 : 0 )
    
    return (
      <div>
        <div className="Content">
          <h2 className="section-title text-center">Featured Registries</h2>
          <p className="section-content text-center">Sirvve provides an easy way for you to give meals to your loved ones. </p>
          <div className="search-box">
            <SearchIcon />
            <input type="text" ref={this.profileNameInput} placeholder="Recipient, host, or registry name" />
            <button onClick={this.searchBind}>Search</button>
          </div>
          <div className="Container">
            <div className="filter-box">
              <div className="filter-item" onClick={()=>this.setState({sortByToggle: !sortByToggle, categoryToggle: false})}>
                <b>Sort by&nbsp;</b> {sortBy}
                { 
                  sortByToggle && <ExpandLessIcon />
                }
                { 
                  !sortByToggle && <ExpandMoreIcon />
                }
                {
                  sortByToggle && 
                  <div className="filter-modal" onClick={(e)=>e.stopPropagation()}>
                    <p className="title">Sort By</p>
                    <div className="content">
                      
                      <div className="checkbox-wrap">
                        <input type="checkbox" id="type2" name="type2" checked={sortBy=='Most Recent'} onClick={()=>this.setState({sortBy: 'Most Recent'})} value="Most Recent"/>
                        <label htmlFor="type2">Most Recent</label>
                      </div>
                      <div className="checkbox-wrap">
                        <input type="checkbox" id="type1" name="type1" checked={sortBy=='A-Z'} onClick={()=>this.setState({sortBy: 'A-Z'})} value="A-Z"/>
                        <label htmlFor="type1">A-Z</label>
                      </div>
                      <div className="checkbox-wrap">
                        <input type="checkbox" id="type3" name="type3" checked={sortBy=='Z-A'} onClick={()=>this.setState({sortBy: 'Z-A'})} value="Z-A"/>
                        <label htmlFor="type3">Z-A</label>
                      </div>
                    </div>
                    <button onClick={()=>this.setState({sortByToggle: false, categoryToggle: false})}>
                      Show Results
                    </button>
                  </div>
                }
              </div>
              <div className="filter-item" onClick={()=>this.setState({categoryToggle: !categoryToggle, sortByToggle: false})}>
                <b>Category</b>
                { 
                  categoryToggle && <ExpandLessIcon />
                }
                { 
                  !categoryToggle && <ExpandMoreIcon />
                }
                {
                  categoryToggle && 
                  <div className="filter-modal" onClick={(e)=>e.stopPropagation()}>
                    <p className="title">Campaign type</p>
                    <div className="content">
                      <div className="checkbox-wrap">
                        <input type="checkbox" id="type1" name="type1" onClick={()=>this.updateType("Disability Support")} value="Disability Support"/>
                        <label htmlFor="type1">Disability Support</label>
                      </div>
                      <div className="checkbox-wrap">
                        <input type="checkbox" id="type2" name="type2" onClick={()=>this.updateType("Illness")} value="Illness"/>
                        <label htmlFor="type2">Illness</label>
                      </div>
                      <div className="checkbox-wrap">
                        <input type="checkbox" id="type3" name="type3" onClick={()=>this.updateType("Injury Recovery")} value="Injury Recovery"/>
                        <label htmlFor="type3">Injury Recovery</label>
                      </div>
                      <div className="checkbox-wrap">
                        <input type="checkbox" id="type4" name="type4" onClick={()=>this.updateType("Long Term Care")} value="Long Term Care"/>
                        <label htmlFor="type4">Long Term Care</label>
                      </div>
                      <div className="checkbox-wrap">
                        <input type="checkbox" id="type5" name="type5" onClick={()=>this.updateType("Mourning / Grief")} value="Mourning / Grief"/>
                        <label htmlFor="type5">Mourning / Grief</label>
                      </div>
                      <div className="checkbox-wrap">
                        <input type="checkbox" id="type6" name="type6" onClick={()=>this.updateType("Newborn")} value="Newborn"/>
                        <label htmlFor="type6">Newborn</label>
                      </div>
                    </div>
                    <button onClick={()=>this.setState({sortByToggle: false, categoryToggle: false})}>
                      Show Results
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="Container result-box">
            <Grid container spacing={6}>
              {
                sortCampaigns.map((campaign, index) => {
                  return (
                    <Grid item xs={12} sm={12} md={4}>
                      <CampaginCard key={`campaign_card_${campaign.id}`} campaign={campaign} label="View Registry" />                      
                    </Grid>
                  );
                })
              }

              {sortCampaigns.length === 0 && (<Grid item sm={12} md={12}><Typography style={{textAlign:'center'}}>Sorry there are no results, please try typing the admin name, recipient name, or registry title.</Typography></Grid>)}
            </Grid>
          </div>
          <hr style={{borderColor: "rgba(0, 0, 0, 0.16)", margin: "80px auto 80px"}} />
          <div className="Container">

            {/* Section 7 */}
            <div className="section" id="section7">
              <div className="page-container">
                <div className="flexbox">
                  <div className="item-55">
                    <div className="image-box2">
                      <img src={img21} alt="" className="full-image" />
                    </div>
                  </div>
                  <div className="item-45">
                    <div className="item-box3">
                      <div className="heading-box left">
                        <h2 className="sectiontitle">Second helpings</h2>
                        <div className="sectionbigtext">Your table just got a little longer…every time you make a gift to a registry on Sirvve, you are sharing meals with folks that want support.  For every registry that meets its goal, Sirvve gifts 1 family meal to someone in need.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const CampaignsWrap = withRouter(withFirebase(Campaigns));
export default CampaignsPage;
export { CampaignsWrap };

