import React, { Component } from 'react';
import { Link, withRouter, useParams } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import _  from "lodash";
import { withFirebase } from '../Firebase';
import emptystate from "../../images/emptystate.svg";
import CloseIcon from '@material-ui/icons/Close';

import { HeartSpinner } from "react-spinners-kit";
import PersonOutlineSharpIcon from '@material-ui/icons/PersonOutlineSharp';
import ShareCmapaignModal from '../Dashboard/shareCampaignModal';

import Grid from '@material-ui/core/Grid';
import Layout from '../Page';
import {getCampaign, updateCampaign} from '../Utils/campaigns';
import {getDaysLeft} from '../Utils/functions';
import {getUser} from '../Utils/users';
import {getGifts} from '../Utils/gifts';
import { Paper, Button } from '@material-ui/core';

import img5 from "../../images/curated_meals.jpg";
import img6 from "../../images/Group1.svg";
import img7 from "../../images/Group2.svg";
import img8 from "../../images/Group3.svg";
import img9 from "../../images/Group4.svg";
import img17 from "../../images/step1.gif";
import img18 from "../../images/step2.gif";
import img19 from "../../images/step3.gif";
import img20 from "../../images/step4.gif";
import img21 from "../../images/second-helpings.jpg";
import imgTestimonial1 from "../../images/Cara_crop.png";
import imgTestimonial2 from "../../images/keesha.png";
import imgTestimonial3 from "../../images/kim-crop.png";
import quoteSvg from "../../images/quote.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var testimonialSliderItems = [
  {
      name1: "-Cara L.",
      name2: "Newborn",
      description: "Just gave meals to my best friend and her daughter in Seattle….love you Jessica!   Never had to leave NC or wash a dish😊",
      image: imgTestimonial3
  },
  {
    name1: "-Keesha S.",
    name2: "",
    description: "I work in the school system and am frequently asked to contribute to and sometimes plan a meal train.   On Sirvve I was able to gift my meals and have started two registries for a couple of people I know that could really use the help.  This is my new “run to” for meal giving.    Thank you Sirvve! ",
    image: imgTestimonial2
  },
  {
    name1: "-Kim L.",
    name2: "Cancer",
    description: "I received a gift request link from a friend of mine hosting a registry for someone battling cancer.  I had wanted to do something for the family and didn’t know what to bring or when to drop by.   Giving meals is sometimes hard because you never know what people really want.  I LOVE the idea of giving meals (or money) towards their meal registry.  Super EASY.",
    image: imgTestimonial1
  }
];

function TestimonialItem(props) {
  return (
      <Paper style={{height:'100%'}}>
        <div className="testimonial-box">
          <div className="content-box">
            <div className="image-box3">
              <img src={props.item.image} className="full-image" alt="" />
            </div>
            <div className="text-box2">
              <div className="image-box4">
                <img src={quoteSvg} alt="" />
              </div>

              <div className="sectiontext">{props.item.description}</div>
            </div>
            <div className="text-box3">
              <div className="sectionbigtext">{props.item.name1}</div>
              <div className="sectionsmalltext">{props.item.name2}</div>
            </div>
          </div>
        </div>
      </Paper>
  )
}

const ViewCampaignPage = () => (
  <Layout additionalClass="no_wrap" menuType="no_menu" page="view-campaign">
    <ViewCampaignWrap/>
  </Layout>
);
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
const INITIAL_STATE = {
  selected: 'overview',
  campaignPhoto: null,
  errorCampaign: null,
  campaignTitle: '',
  campaignCategory: '',
  campaignGoalType: '',
  campaignOrganization: false,
  openModal: false,
  campaignDescription: '',
  recipientContactInformation: '',
  recipientFirstName: '',
  recipientLastName: '',
  recipientAddress1: '',
  recipientAddress2: '',
  selectedCampaign: null,
  recipientCity: '',
  recipientState: '',
  recipientZipCode: '',
  recipientNotes: '',
  dateRegistered: null,
  publishState: false,
  gifts: [],
  giftPopupToggle: false,
  pageNumber: 1,
  curPage: 1,
  loading: false,
  tlimit: false,
  orderTotalMealsGiftedQty: 0,
  pageStep: 1,
  campaignAdminName: '',
  read_more: false
};

class ViewCampaign extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.storage = props.firebase.getstorage();
    this.db = this.props.firebase.getdb();
  }

  componentDidMount() {
    var that = this;
    setTimeout(function(){that.setState({tlimit: true})}, 3000)
    if (this.props.match.params.id) {
      getCampaign(this.db, this.props.match.params.id, this.campaignData)
      getGifts(this.db, this.props.match.params.id, this.giftsData)
      
    }
  }
  giftsData = (data) => {
    let gifts = data.sort(function(a,b){
      return new Date(b.createdDate) - new Date(a.createdDate)
    })
    this.setState({gifts: data, pageNumber: Math.ceil(data.length / 8), pageStep: 1})
  }
  handleCloseDialog = () => {
    this.setState({openModal: false})
  }
  campaignData = campaign => {
    console.log(campaign)
    this.setState({
      selectedCampaign: campaign,
      campaignPhoto: campaign.campaignPhoto,
      campaignTitle: campaign.campaignTitle,
      campaignDescription: campaign.campaignDescription,
      campaignCategory: campaign.campaignCategory,
      campaignGoalType: !campaign.campaignGoalType?'':campaign.campaignGoalType,
      campaignOrganization: !campaign.campaignOrganization?false:true,
      recipientFirstName: campaign.recipientFirstName,
      recipientLastName: campaign.recipientLastName,
      recipientAddress1: campaign.recipientAddress1,
      recipientAddress2: campaign.recipientAddress2,
      recipientCity: campaign.recipientCity,
      recipientState: campaign.recipientState,
      recipientZipCode: campaign.recipientZipCode,
      recipientNotes: campaign.recipientNotes,
      dateRegistered: campaign.dateRegistered,
      campaignStatus: campaign.campaignStatus,
      recipientContactInformation: campaign.recipientContactInformation,
      orderTotalCampaignSum: campaign.orderTotalCampaignSum,
      orderDeliveryPrice: campaign.orderDeliveryPrice,
      boxCount: campaign.boxCount,
      recommandationGoals: campaign.recommandationGoals,
      scheduleLastOrder: campaign.scheduleLastOrder,
      scheduleNextOrder: campaign.scheduleNextOrder,
      orderFundsNeeded: campaign.orderFundsNeeded,
      orderTotalMealsGiftedQty: campaign.orderTotalMealsGiftedQty,
      campaignLength: campaign.campaignLength,
      campaignAdmin: campaign.campaignAdmin,
      loading: true
    })
    getUser(this.db, campaign.campaignAdmin, this.adminData)
  }
  adminData = admin => {
    console.log(admin)
    this.setState({campaignAdminName: admin})
  }

  render() {
    const {
      curPage,
      pageNumber,
      selected,
      campaignPhoto,
      campaignTitle,
      campaignCategory,
      campaignDescription,
      campaignGoalType,
      campaignOrganization,
      errorCampaign,
      campaignAdmin,
      campaignLength,
      campaignSetupStep,
      recipientContactInformation,
      recipientFirstName,
      recipientLastName,
      recipientAddress1,
      recipientAddress2,
      recipientCity,
      recipientState,
      recipientZipCode,
      recipientNotes,
      campaignStatus,
      publishState,
      pageStep,
      gifts,
      orderDeliveryPrice,
      orderFundsNeeded,
      orderTotalCampaignSum,
      dateRegistered,
      recommandationGoals,
      scheduleLastOrder,
      orderTotalMealsGiftedQty,
      scheduleNextOrder,
      boxCount,
      selectedCampaign,
      openModal,
      loading,
      read_more,
      tlimit
    } = this.state;

    var testimonialSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
        adaptiveHeight: true,
      fade: true
    };
    
    if(document.querySelector('.campaign-description') && document.querySelector('.campaign-description').scrollHeight <= 170) {

      var elements = document.getElementsByClassName('read_more');
      while(elements.length > 0){
          elements[0].parentNode.removeChild(elements[0]);
      }
    }
    var months = [ "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December" ];
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    let product_price = parseFloat(parseFloat(orderDeliveryPrice) / parseFloat(boxCount)).toFixed(2)
    let meals_donated = parseInt(parseFloat(orderTotalCampaignSum)*parseInt(boxCount)/parseFloat(orderDeliveryPrice));

    var Difference_In_Time = (scheduleLastOrder ? scheduleLastOrder.toDate() : new Date()).getTime() - new Date().getTime();
    let nextDate = scheduleLastOrder ? scheduleLastOrder.toDate() : new Date()
    var nextDateText = days[nextDate.getDay()]+', '+ months[nextDate.getMonth()] + ' ' + nextDate.getDate() + 'th';
    var Difference_In_Days = parseInt(Difference_In_Time / (1000 * 3600 * 24) + parseInt(campaignLength) * parseInt(boxCount) / parseInt(recommandationGoals));

    let maxPageStep = Math.max(pageNumber - 4, 1);
    
    return (
      <div>
      {(!loading || !tlimit) && <div className="loadWrap">
        <HeartSpinner size={250} color="#FED202" loading={(!loading || !tlimit)} />
      </div>}
      {loading && <div className="view-campaign">
        <ShareCmapaignModal campaign={selectedCampaign} open={openModal} handleClose={this.handleCloseDialog} />  
        {
          this.state.giftPopupToggle &&
          <div className="gift-popup-wrap">
            <div className="gift-popup">
              <CloseIcon className="close-popup" onClick={()=>this.setState({giftPopupToggle: false})} />
              <p className="gift-title">
                Gifts
              </p>
              {
                gifts.length > 0 &&
                gifts.slice((curPage-1)*8, curPage*8).map((gift, index) => {
                  let t = gift.createdDate.split(/[- :]/);
                  let d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
                  let g_date = new Date(new Date(d)-300*60*1000);

                  let giftText = '';
                  if (gift.giftedDollarsPrice && gift.giftedDollarsPrice!=0 && gift.giftedMealQty && gift.giftedMealQty!=0) {
                    //giftText = "Gave $" + parseFloat(gift.giftedDollarsPrice).toFixed(2) + " and " + gift.giftedMealQty + " meals on " + months[g_date.getMonth()] + ' ' + g_date.getDate() + ", " + g_date.getFullYear()
                    giftText = `Gave $${parseFloat(gift.giftedDollarsPrice).toFixed(2)} and ${gift.giftedMealQty} servings on ${months[g_date.getMonth()]} ${g_date.getDate()}, ${g_date.getFullYear()}`;
                  }
                  else {
                    if (gift.giftedDollarsPrice && gift.giftedDollarsPrice!=0){
                      giftText = "Gave $" + parseFloat(gift.giftedDollarsPrice).toFixed(2) + " on " + months[g_date.getMonth()] + ' ' + g_date.getDate() + ", " + g_date.getFullYear()
                    }
                    if (gift.giftedMealQty && gift.giftedMealQty!=0) {
                      //giftText = "Gave " + gift.giftedMealQty + " meals on " + months[g_date.getMonth()] + ' ' + g_date.getDate() + ", " + g_date.getFullYear()
                      giftText = `Gave ${gift.giftedMealQty} servings on ${months[g_date.getMonth()]} ${g_date.getDate()}, ${g_date.getFullYear()}`;
                    }
                  }
                  return  (<div key={index}  className="gift-tile">

                    <PersonOutlineSharpIcon/>
                    <div>
                      <p className="title">
                        {gift.anonymous?"Anonymous": (gift.campaignFirstName+' '+ gift.customerLastName)}
                      </p>
                      <p className="desc">
                        {giftText}
                      </p>
                    </div>
                  </div>)
                }) 
              }
              <div className="pagination">
                <ArrowBackIcon  className={pageStep == 1 ? 'inactive' : ''} onClick={()=>this.setState({pageStep: Math.max(pageStep-1, 1)})}/>
                {
                  [pageStep, pageStep+1, pageStep+2, pageStep+3, pageStep+4].map((ind, index) => {
                    if (ind > pageNumber )
                      return
                    return <span className={curPage==ind? 'active' : ''}  onClick={()=>this.setState({curPage: ind})}>{ind}</span>
                  })
                }
                <ArrowForwardIcon className={pageStep == maxPageStep ? 'inactive' : ''} onClick={()=>this.setState({pageStep: Math.min(pageStep+1, maxPageStep)})} />
              </div>
              
            </div>
          </div>
        }
        <div className="Content">
          <div className="Container">
            <Grid container spacing={6}>
              <Grid item xs={12} sm={3}>
                {campaignPhoto && <img className="campaign-photo" src={campaignPhoto} />}
                {meals_donated>=recommandationGoals && <p className="campaign-goal">This registry has reached its goal!</p>}
                {!campaignPhoto && <div className="placeholder_img"><img src={emptystate}/></div>}
                {/* <p className="det">
                  {gifts.length==0?"":`${gifts.length} GIFTS`}
                  
                  {gifts.length>0 && (meals_donated<recommandationGoals) && " - "}
                  {
                    (meals_donated<recommandationGoals) && getDaysLeft(dateRegistered, campaignLength)
                  }
                </p>*/}
                {/*
                  (meals_donated<recommandationGoals) && scheduleNextOrder &&
                  <div className="devlivery-wrap">
                    <p className="title">Next Delivery</p>
                    <p className="item-title">Scheduled</p>
                    <p className="item-content">{nextDateText}</p>
                    <p className="item-title">Gifts Needed</p>
                    <p className="item-content">{orderFundsNeeded>0?parseInt(orderFundsNeeded*boxCount/orderDeliveryPrice): 0} Meals *</p>
                    <span className="comment">*gifts needed for scheduled delivery to be shipped</span>
                  </div>
                */}
                {/*
                  (meals_donated<recommandationGoals) && !scheduleNextOrder &&
                  <div className="devlivery-wrap">
                    <p className="title">Next Delivery</p>
                    <p className="item-title">Gifts Needed</p>
                    <p className="item-content">{orderFundsNeeded>0?parseInt(orderFundsNeeded*boxCount/orderDeliveryPrice): 0} Meals *</p>
                    <span className="comment">*gifts needed for scheduled delivery to be shipped</span>
                  </div>
                */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <p className="campaign-title">{campaignTitle}</p>
                <div  className={"campaign-description_wrap " + (read_more?"active":"")}>
                  <p className="campaign-description" dangerouslySetInnerHTML={{__html: campaignDescription ? campaignDescription.replaceAll('$#$%', '<br/>') : ''}}>
                    
                  </p>
                  <span className="read_more" onClick={()=>this.setState({read_more: !read_more})}>Read More</span>
                </div>
                {
                  meals_donated<recommandationGoals && 
                  <div className="buttons-wrap">
                    <a className="gift-meal" target="_blank" href={`/donation/${this.props.match.params.id}`}>Gift Meals</a>                  
                    <button className="share-campaign"  onClick={()=>this.setState({openModal: true})} >Share Registry</button>
                  </div>
                }
                {
                  meals_donated>=recommandationGoals && 
                  <div className="second-helpings">
                  This registry is fully funded, however you can still make a difference by gifting towards second helpings 🙏

                  <div className="buttons-wrap">
                    <a className="gift-meal" target="_blank" href={`/donation/${this.props.match.params.id}`}>Second Helpings</a>     
                  </div>
                  </div>
                }
                {/*
                <div className="progress_bar">
                  <div className="cur_progress" style={{width: (meals_donated>recommandationGoals ? "100" : parseInt(meals_donated*100/recommandationGoals)) + "%"}}>
                  </div>
                </div>
                <div className="campaign_info">
                  <div className="campaign_donated">
                    <p className="count">{meals_donated?meals_donated:0}</p>
                    <p className="comment">Servings Gifted</p>                    
                  </div>

                  {campaignOrganization?(
                    <div className="campaign_funded">
                      <p className="count">{recommandationGoals}</p>
                      <p className="comment">{campaignGoalType} Goal</p>
                    </div> 
                  ):(
                    <div className="campaign_funded">
                      <p className="count">{recommandationGoals / 4}</p>
                      <p className="comment">Family meals In Goal</p>
                      <p className="desc">4 servings per family meal</p>
                    </div> 
                  )}

                 
                  <div className="campaign_goal">
                    <p className="count">{meals_donated>recommandationGoals?100:Math.ceil(meals_donated*100.0/recommandationGoals)}%</p>
                    <p className="comment">Funded</p>
                  </div>
                </div>
                */}
              </Grid>
              <Grid item xs={12} sm={3}>
                <p className="gift-title">GIFTS</p>
                <div className="gift-content">
                  {
                    gifts.length == 0 && <span>There are currently no gifts, be the first to give!</span> 
                  }
                  {
                    gifts.length > 0 &&
                    gifts.slice(0, 4).map((gift, index) => {
                      let t = gift.createdDate.split(/[- :]/);
                      let d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
                      let g_date = new Date(new Date(d)-300*60*1000);
                      let giftText = '';
                      if (gift.giftedDollarsPrice && gift.giftedDollarsPrice!=0 && gift.giftedMealQty && gift.giftedMealQty!=0) {
                        //giftText = "Gave $" + parseFloat(gift.giftedDollarsPrice).toFixed(2) + " and " + gift.giftedMealQty + " meals on " + months[g_date.getMonth()] + ' ' + g_date.getDate() + ", " + g_date.getFullYear()
                        giftText = `Gave $${parseFloat(gift.giftedDollarsPrice).toFixed(2)} and ${gift.giftedMealQty} servings on ${months[g_date.getMonth()]} ${g_date.getDate()}, ${g_date.getFullYear()}`;
                      }
                      else {
                        if (gift.giftedDollarsPrice && gift.giftedDollarsPrice!=0){
                          giftText = "Gave $" + parseFloat(gift.giftedDollarsPrice).toFixed(2) + " on " + months[g_date.getMonth()] + ' ' + g_date.getDate() + ", " + g_date.getFullYear()
                        }
                        if (gift.giftedMealQty && gift.giftedMealQty!=0) {
                          //giftText = "Gave " + gift.giftedMealQty + " meals on " + months[g_date.getMonth()] + ' ' + g_date.getDate() + ", " + g_date.getFullYear()
                          giftText = `Gave ${gift.giftedMealQty} servings on ${months[g_date.getMonth()]} ${g_date.getDate()}, ${g_date.getFullYear()}`;
                        }
                      }
                      return  (<div key={index}>
                        <hr/>
                        <p className="title">
                          {gift.anonymous?"Anonymous": (gift.campaignFirstName+' '+ gift.customerLastName)}
                        </p>
                        <p className="desc">
                          {giftText}
                        </p>
                      </div>)
                    }) 
                  }
                  { gifts.length > 0 && <hr/> }
                </div>
                <div className="gift-buttons">
                  { gifts.length > 0 &&
                    <span onClick={()=>this.setState({giftPopupToggle: true})}>
                      See All Gifts
                    </span>
                  }
                  {/*
                  <span>
                    Comments (38)
                  </span>
                */}
                </div>
                <div className="Organizer-and-beneficiray">
                  <div className="tile">
                    {this.state.campaignAdminName && this.state.campaignAdminName.accImg && <img src={this.state.campaignAdminName.accImg} />}
                    <div className="detail">
                      <p className="name">{this.state.campaignAdminName && this.state.campaignAdminName.name}</p>
                      <p className="position">Host</p>
                      <a href={"mailto:"+this.state.campaignAdmin}>Contact</a>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

            {/* Section 6 */}
            <div className="section" id="section6">
              <div className="page-container">
                <div className="heading-box">
                  <h2 className="sectiontitle">Registries bring people together</h2>
                </div>
                <div className="flexbox offset-25">
                  <div className="item-4-2-1">
                    <div className="item-box" id="camp_1">
                      <div className="item-wrapper">
                        <div className="item-badge">1</div>
                        <div className="image-box max-200">
                          <img src={img17} alt="" className="full-image" />
                        </div>
                        <h3 className="sectionsmalltitle">You create a<br/> registry</h3>
                        <p className="sectiontext">Get started in just five minutes by filling in a few details. Easily manage preferences and updates as you go.</p>
                      </div>
                      
                    </div>
                  </div>

                  <div className="item-4-2-1">
                    <div className="item-box" id="camp_2">
                      <div className="item-wrapper">
                        <div className="item-badge">2</div>
                        <div className="image-box max-200">
                          <img src={img18} alt="" className="full-image" />
                        </div>
                        <h3 className="sectionsmalltitle">Everyone<br/> gives meals</h3>
                        <p className="sectiontext">Invite friends and loved ones to  give healthy, delicious meals that show someone they care. </p>                        
                      </div>
                      
                    </div>
                  </div>
                  
                  <div className="item-4-2-1">
                    <div className="item-box" id="camp_3">
                      <div className="item-wrapper">
                        <div className="item-badge">3</div>
                        <div className="image-box max-200">
                          <img src={img19} alt="" className="full-image" />
                        </div>
                        <h3 className="sectionsmalltitle">We prep, cook<br/> & deliver</h3>
                        <p className="sectiontext">Our chefs craft high-quality, family-style meals from scratch and freeze them at the peak of freshness for delivery right to recipients’ doors.</p>
                      </div>

                    </div>
                  </div>

                  <div className="item-4-2-1">
                    <div className="item-box" id="camp_4">
                      <div className="item-wrapper">
                        <div className="item-badge">4</div>
                        <div className="image-box max-200">
                          <img src={img20} alt="" className="full-image" />
                        </div>
                        <h3 className="sectionsmalltitle">They enjoy!</h3>
                        <p className="sectiontext">With a few minutes of reheating, your recipients will enjoy heartfelt, chef-cooked meals with easy cleanup.</p>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/* Section 2 */}
            <div className="section" id="section22">
              <div className="page-container">
                <div className="flexbox">
                  <div className="item-55">
                    <div className="image-box2">
                      <img src={img5} alt="" className="full-image" />
                    </div>
                  </div>
                  <div className="item-45">
                    <div className="item-box4">
                      <div className="heading-box left">
                        <h2 className="sectiontitle">Fully cooked & reheats in minutes</h2>
                        <p className="sectionbigtext">Our chefs create recipes based on selected meal and dietary preferences. This makes it easy to share dishes the whole family will love when they need it most. <br/><br/>What to expect.</p>
                      </div>
                      <div className="context-box1">
                        <div className="item-25">
                          <img src={img6} alt="" />
                          <div className="sectionsmalltext">Great family friendly recipes</div>
                        </div>
                        
                        <div className="item-25">
                          <img src={img7} alt="" />
                          <div className="sectionsmalltext">Based on preferences & dietary restrictions</div>
                        </div>
                        
                        <div className="item-25">
                          <img src={img8} alt="" />
                          <div className="sectionsmalltext">Fully cooked reheats in minutes</div>
                        </div>
                        <div className="item-25">
                          <img src={img9} alt="" />
                          <div className="sectionsmalltext">Arrives at their doorstep safely</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Section 4 */}
            <div className="section" id="section4">
              <Slider {...testimonialSettings}>
              {
                testimonialSliderItems.map( (item, i) => <TestimonialItem key={i} item={item} /> )
              }  
              </Slider>
            </div>

            {/* Section 7 */}
            <div className="section" id="section7">
              <div className="page-container">
                <div className="flexbox">
                  <div className="item-55">
                    <div className="image-box2">
                      <img src={img21} alt="" className="full-image" />
                    </div>
                  </div>
                  <div className="item-45">
                    <div className="item-box3">
                      <div className="heading-box left">
                        <h2 className="sectiontitle">Second helpings</h2>
                        <div className="sectionbigtext">Your table just got a little longer…every time you make a gift to a registry on Sirvve, you are sharing meals with folks that want support.  For every registry that meets its goal, Sirvve gifts 1 family meal to someone in need.</div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>


      </div>}
      </div>
    );
  }
}

const ViewCampaignWrap = withRouter(withFirebase(ViewCampaign));
export default ViewCampaignPage;
export { ViewCampaignWrap };

