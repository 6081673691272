import React, { Component } from 'react';
import { Link, withRouter, useParams } from 'react-router-dom';
import _  from "lodash";
import { withFirebase } from '../Firebase';
import vve from "../../images/vve.png";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Layout from '../Page';
import {getCampaign, updateCampaign, getProductDetails} from '../Utils/campaigns';
import Client from 'shopify-buy';
import Product from '../Product';
import imgHelping from "../../images/donation_helping.png";
import pdfTerms from "../../pdf/Sirvve_terms-and-conditions.pdf";

const client = Client.buildClient({
  storefrontAccessToken: '865772609f279a8d2d17f2a469825680',
  domain: 'checkout.sirvve.com'
});
const second_helping_var_id = "gid://shopify/ProductVariant/40247574495418";
const gift_price_var_id = "gid://shopify/ProductVariant/40247568793786";

const DonationsPage = () => (
  <Layout navClass="donations_nav" page="donation">
    <DonationsWrap client={client}/>
  </Layout>
);

const INITIAL_STATE = {
  isCartOpen: true,
  checkout: { lineItems: [] },
  products: [],
  meals_count: 0,
  gift_count: 0,
  collapse: false,
  second_helping_count: 0,
  shop: {},
  alert: false,
  title: '',
  anonymous: false,
  load: false,
  onlySecondHelping: false,
  agreed: false,
  note: "",
  show: false,
  meals_price_var_id: "gid://shopify/ProductVariant/40526893646010"
};
class Donations extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.storage = props.firebase.getstorage();
    this.db = this.props.firebase.getdb();
  }
  componentWillMount() {
    var that= this;
    getCampaign(this.db, this.props.match.params.id, function(campaign){
      let meals_donated = parseInt(parseFloat(campaign.orderTotalCampaignSum)*parseInt(campaign.boxCount)/parseFloat(campaign.orderDeliveryPrice));
      if(campaign.recommandationGoals <= meals_donated) {
        that.setState({onlySecondHelping: true})
      }
      getProductDetails(that.db, campaign.recipientZipCode, function(var_id){
        that.setState({meals_price_var_id: var_id, show: true, title: campaign.campaignTitle})
      })
      that.setState({load: true})
    });

    this.props.client.checkout.create().then((res) => {
      this.setState({
        checkout: res,
      });
    });

    this.props.client.product.fetchAll().then((res) => {
      this.setState({
        products: res,
      });
    });

    this.props.client.shop.fetchInfo().then((res) => {
      this.setState({
        shop: res,
      });
    });
  }

  componentDidMount() {
    //this.createOrUpdate(this.state.meals_count, this.state.meals_price_var_id);
    //this.createOrUpdate(this.state.gift_count, gift_price_var_id);
  }

  addVariantToCartByTitle = (variantTitle, quantity) => {
    let varId = "";
    if (variantTitle === "Second Helping Donations") {
      varId = second_helping_var_id;
    } else if (variantTitle === "Gifted Funds") {
      varId = gift_price_var_id;
    } else if (variantTitle === "Gifted Meals") {
      varId = this.state.meals_price_var_id;
    }

    return this.addVariantToCart(varId, quantity);
  }

  addVariantToCart = (variantId, quantity) => {
    this.setState({
      isCartOpen: true,
    });

    const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}]
    const checkoutId = this.state.checkout.id

    return this.props.client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
      res.lineItems.map((line_item) => {
        if(line_item.variant.id == second_helping_var_id) {
          if(line_item.qunatity != this.state.second_helping_count){
            this.updateQuantityInCart(line_item.id, this.state.second_helping_count);
          }
        }
      })
      this.setState({
        checkout: res,
      });
    });
  }

  updateQuantityInCart = (lineItemId, quantity) => {
    const checkoutId = this.state.checkout.id
    const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}]

    return this.props.client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
      this.setState({
        checkout: res,
      });
    });
  }

  openCheckout = (price) => {
    if (!this.state.agree) {
      this.setState({alert:true, alertMsg:"Please review and accept the terms and conditions"});
      return;
    }

    if(this.state.checkout.lineItems.length > 0) {
      if(price > 5) {
        client.checkout.updateAttributes(
          this.state.checkout.id, 
          {
            customAttributes: [
              {key: "Anonymous", value: this.state.anonymous?"Yes": "No"}, 
              {key: "Campaign Title", value: this.state.title}, 
              {key: "CampaignId", value: this.props.match.params.id},
              {key: "Note", value: this.state.note}
            ]
          }).then((checkout) => {
          console.log('success');    
          window.location.href = this.state.checkout.webUrl;  
        });
      } else {
        if(this.state.onlySecondHelping) {
          client.checkout.updateAttributes(
            this.state.checkout.id, 
            {
              customAttributes: [
                {key: "Anonymous", value: this.state.anonymous?"Yes": "No"}, 
                {key: "Campaign Title", value: this.state.title}, 
                {key: "CampaignId", value: this.props.match.params.id},
                {key: "Note", value: this.state.note}
              ]
            }).then((checkout) => {
            console.log('success');    
            window.location.href = this.state.checkout.webUrl;  
          });
        }
        else 
          this.setState({alert:true, alertMsg:"Minimum order of $5 required"});
      }
    } else {
      this.setState({alert:true, alertMsg:"Minimum order of $5 required"});
    }
     
  }

  removeLineItemInCart = (lineItemId) => {
    const checkoutId = this.state.checkout.id

    return this.props.client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
      this.setState({
        checkout: res,
      });
    });
  }
  handleAnoChange = () => {
    this.setState({anonymous: !this.state.anonymous})
  }
  handleAgreeChange = () => {
    this.setState({agree: !this.state.agree})
  }

  handleNoteChange = (event) => {
    this.setState({note: event.target.value});
  }

  createOrUpdateByTitle = (count, title) => {
    console.log(count)
    if (count<0)
      return;

    let vcount = count < 0 ? 0 : count;
    if (title === "Gifted Meals" && count<0)
      vcount = 0;
    else if (title === "Gifted Funds" && count < 0)
      vcount = 0;

    var exist = false;
    var that = this;
    this.state.checkout.lineItems.map((line_item) => {
      if ( line_item.title === title ) {
        exist = true;
        that.updateQuantityInCart(line_item.id, vcount);
      }
    })
    if (!exist) {
      this.addVariantToCartByTitle(title, vcount);
    }
    if(title === "Gifted Meals") {
      this.setState({ meals_count : vcount })
    }
    else if(title === "Second Helping Donations") {
      this.setState({ second_helping_count : vcount })
    }
    else if(title === "Gifted Funds") {
      this.setState({ gift_count : vcount })
    }
  }

  createOrUpdate = (count, var_id) => {
    console.log(count)
    if (count<0)
      return;

    let vcount = count < 0 ? 0 : count;
    if (var_id == this.state.meals_price_var_id && count<0)
      vcount = 0;
    else if (var_id == gift_price_var_id && count < 0)
      vcount = 0;

    var exist = false;
    var that = this;
    this.state.checkout.lineItems.map((line_item) => {
      if ( line_item.variant.id == var_id ) {
        exist = true;
        that.updateQuantityInCart(line_item.id, vcount);
      }
    })
    if (!exist) {
      this.addVariantToCart(var_id, vcount);
    }
    if(var_id == this.state.meals_price_var_id) {
      this.setState({ meals_count : vcount })
    }
    else if(var_id == second_helping_var_id) {
      this.setState({ second_helping_count : vcount })
    }
    else if(var_id == gift_price_var_id) {
      this.setState({ gift_count : vcount })
    }
    // console.log(this.state.checkout);
    // client.checkout.updateAttributes(this.state.checkout.id, {customAttributes: [{key: "CampaignId", value: this.props.match.params.id}]}).then((checkout) => {
    //   console.log('success');      
    // });
  }

  render() {
    const {
      meals_count,
      gift_count,
      collapse,
      second_helping_count,
      meals_price_var_id,
      alert,
      alertMsg,
      anonymous,
      agree,
      onlySecondHelping,
      show,
      load,
      note
    } = this.state;
    let tax = 0, meals_price = 0, gift_price = 0, second_helping = 0, meals_src = vve, gift_price_src = vve;
    
    this.state.products.map((product) => {
      if (product.title === "Gifted Meals") {
        meals_src = product.images[0].src
      } else if (product.title === "Gifted Funds") {
        gift_price_src = product.images[0].src
      }
    })
    this.state.checkout.lineItems.map((line_item) => {
      if (line_item.title === "Second Helping Donations") {
        second_helping += line_item.quantity * line_item.variant.price.amount;
      } else if (line_item.title === "Gifted Funds"){
        tax += line_item.variant.price.amount * line_item.quantity * 0.1;
        gift_price += line_item.variant.price.amount * line_item.quantity;
      } else if (line_item.title === "Gifted Meals"){
        tax += line_item.variant.price.amount * line_item.quantity * 0.1;
        meals_price += line_item.variant.price.amount * line_item.quantity;
      } else {
        tax += line_item.variant.price.amount * line_item.quantity * 0.1;
      }
    })

    const subtotalPrice = (!!this.state.checkout && !!this.state.checkout.subtotalPrice)?parseFloat(this.state.checkout.subtotalPrice.amount):0;

    let products = this.state.products.map((product) => {
      return (
        <Product
          client={this.props.client}
          key={product.id.toString()}
          product={product}
          addVariantToCart={this.addVariantToCart}
        />
      );
    });

    return (
      <div className="donations-wrap">
        {
          load && <div>
          {!this.state.isCartOpen &&
            <div className="App__view-cart-wrapper">
              <button className="App__view-cart" onClick={()=> this.setState({isCartOpen: true})}>Cart</button>
            </div>
          }
          {
            !onlySecondHelping && 
              <div className="left-content">
                <div id="meal_box">
                  <p className="title">Gift Meals</p>
                  {show && 
                  <div className="quantity-selector-wrap">
                    <label className='input-label'> Servings </label>
                    
                    <div className="quantity-selector">
                      <div className="button-side">
                        <span className="button" onClick={()=>this.createOrUpdate(meals_count-1, meals_price_var_id)}>-</span>
                      </div>
                      
                      <input 
                        type="text" 
                        min="0" 
                        step="1"  
                        defaultValue={meals_count} 
                        value={meals_count} 
                        onChange={(e)=>this.createOrUpdate(e.target.value?parseInt(e.target.value): 0, meals_price_var_id)}
                      />

                      <div className="button-side">
                        <span className="button" onClick={()=>this.createOrUpdate(meals_count+1, meals_price_var_id)}>+</span>
                      </div>
                    </div>
                    <p className="comment">*each serving is meant to feed an average adult</p>
                  </div>}
                  {show && <div className="popular-gifts-wrap">
                    <label>Popular Gifts</label>
                    <div className="popular-gifts">
                      <button onClick={()=>this.createOrUpdate(4, meals_price_var_id)} >4 Servings</button>
                      <button onClick={()=>this.createOrUpdate(8, meals_price_var_id)} >8 Servings</button>
                      <button onClick={()=>this.createOrUpdate(12, meals_price_var_id)} >12 Servings</button>
                      <button onClick={()=>this.createOrUpdate(24, meals_price_var_id)} >24 Servings</button>
                    </div>
                  </div>}
                </div>

                <div id="money_box">
                  <p className="title">Gift Money Towards Meals</p>
                  <div className="quantity-selector-wrap">
                    <label className='input-label'> Amount </label>
                    <div className="input-wrap">
                      <input type="text" min="0" step="1"  value={gift_count} onChange={(e)=>this.createOrUpdateByTitle(e.target.value?parseInt(e.target.value): 0, "Gifted Funds")}/>
                    </div>
                  </div>

                  <div className="popular-gifts-wrap">
                    <label>Popular Gifts</label>
                    <div className="popular-gifts">
                      <button onClick={()=>this.createOrUpdateByTitle(25, "Gifted Funds")} >25 Dollars</button>
                      <button onClick={()=>this.createOrUpdateByTitle(50, "Gifted Funds")} >50 Dollars</button>
                      <button onClick={()=>this.createOrUpdateByTitle(100, "Gifted Funds")} >100 Dollars</button>
                      <button onClick={()=>this.createOrUpdateByTitle(150, "Gifted Funds")} >150 Dollars</button>
                    </div>
                  </div>
                </div>

                <div id="note_box">
                  <p className="title">Leave a note</p>

                  <div className="note-wrap">
                    <textarea 
                      placeholder="Add a comment (ie In Memory of) to let them know you care." 
                      rows="5"
                      onChange={this.handleNoteChange}
                    >{note}</textarea>

                  </div>
                </div>

                <div id="helping_box">
                  <div className='text-box'>
                    <p className="title">Second Helpings </p>
                    <p className='description'>
                    Second Helpings is designed to help those in need. All proceeds go directly to meals for underfunded registries. 
                    </p>
                    <div className="quantity-selector-wrap" id="donation_second">
                      <label className='input-label'>Amount</label>
                      <div className="input-wrap">
                        <input type="text"  min="0" step="1" value={second_helping_count} onChange={(e)=>this.createOrUpdate(e.target.value?parseInt(e.target.value): 0, second_helping_var_id)}/>
                      </div>
                    </div>
                  </div>
                  
                  <div className='image-box'>
                    <img src={imgHelping} className='full-image' />
                  </div>
                </div>

              </div>
          }
          {
            onlySecondHelping && 
            <div className="only-second-helping left-content">
              <div id="helping_box">
                <div className='text-box'>
                  <p className="title">Second Helpings </p>
                  <p className='description'>
                  Help us make a difference! All proceeds go towards meals donated to local organizations across the nation serving people with food insecurities. 
                  </p>
                  <div className="quantity-selector-wrap" id="donation_second">
                    <label className='input-label'>Donation amount</label>
                    <div className="input-wrap">
                      <input type="number"  min="0" step="1" defaultValue={second_helping_count} onChange={(e)=>this.createOrUpdate(e.target.value?parseInt(e.target.value): 0, second_helping_var_id)}/>
                    </div>
                  </div>
                </div>
                
                <div className='image-box'>
                  <img src={imgHelping} className='full-image' />
                </div>
              </div>
            </div>
          }
          {
            !onlySecondHelping &&
            <div className="right-sidebar">
              <p className="title">
                Your Gift
              </p>
              <div className="sidebar-content">
              <div className="product-wrap">
                <img src={meals_src}/>
                <div className="product-detail">
                  <p className="product-title">Servings</p>
                  <p className="product-subtitle">(4 Servings per Family Meal)</p>
                  <p className="product-price" >${meals_price.toFixed(2)}</p>
                </div> 
              </div>
              <div className="product-wrap">
                <img src={gift_price_src}/>
                <div className="product-detail">
                  <p className="product-title">Monetary Gifts</p>
                  <p className="product-price" >${gift_price.toFixed(2)}</p>
                </div> 
              </div>
              <div className="price-cal-wrap">
                <div className="price_wrap">
                  <span className="price_title">Second Helpings</span>
                  <span>${second_helping.toFixed(2)}</span>
                </div>
                <div className="price_wrap">
                  <span className="price_title">Tax</span>
                  <span>${tax.toFixed(2)}</span>
                </div>
              </div>
              <div className="price_wrap total_price">
                <span className="price_title">Total</span>
                <span>${(subtotalPrice + tax).toFixed(2)}</span>
              </div>
              </div>

              <div className="price_gift">
                <div className="checkbox-wrap">
                    <input type="checkbox" id="anonymous" name="anonymous" onChange={this.handleAnoChange} checked={anonymous}/>
                    <label htmlFor="anonymous">Make gift anonymous</label>
                </div>
                <div className="checkbox-wrap">
                    <input type="checkbox" id="agree" name="agree" onChange={this.handleAgreeChange} checked={agree}/>
                    <label htmlFor="agree">I agree to the <a href={pdfTerms} target="_blank" className="footer-text">Terms and Conditions</a></label>
                </div>

                {alert && <p className="donation_alert">{alertMsg}</p>}
                <button className="continue-but" onClick={(e)=>this.openCheckout(meals_price+gift_price)}>CONTINUE</button>
              </div>
            </div>
          }
          {
            onlySecondHelping && 
            <div className="right-sidebar">
              <p className="title">
                Your Gift
              </p>
              <div className="sidebar-content">
              <div className="product-wrap">
                <img src={meals_src}/>
                <div className="product-detail">
                  <p className="product-title">Second Helpings</p>
                  <p className="product-price" >${second_helping.toFixed(2)}</p>
                </div> 
              </div>
              <div className="price-cal-wrap">
              </div>
              <div className="price_wrap total_price">
                <span className="price_title">Total</span>
                <span>${(subtotalPrice + tax).toFixed(2)}</span>
              </div>
              </div>

              <div className="price_gift">
                <div className="checkbox-wrap">
                    <input type="checkbox" id="agree" name="agree" onChange={this.handleAgreeChange} checked={agree}/>
                    <label htmlFor="agree">I agree to the <a href={pdfTerms} target="_blank" className="footer-text">Terms and Conditions</a></label>
                </div>

                {alert && <p className="donation_alert">{alertMsg}</p>}
                <button className="continue-but" onClick={(e)=>this.openCheckout(meals_price+gift_price)}>CONTINUE</button>
              </div>
            </div>
          }
          </div>
        }
      </div>
    );
  }
}

const DonationsWrap = withRouter(withFirebase(Donations));
export default DonationsPage;
