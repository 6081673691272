import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import { fade, makeStyles } from '@material-ui/core/styles';
import { Button, AppBar, Avatar, Typography} from "@material-ui/core";
import logo from "../../images/logo.svg";
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DehazeIcon from '@material-ui/icons/Dehaze';
import SearchIcon from '@material-ui/icons/Search';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import firebase from "firebase";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: '0px'
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '10px 16px',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  navText: {
    '&:hover': {
      textDecoration: 'none'
    },
  },
  navLink: {
    transition: 'all 200ms',
    fontSize: '14px',
    lineHeight: 1.25,
    color: '#42525a',
    fontWeight: 600,
    textDecoration: 'none',
    textTransform: 'initial',
    padding: '30px 0px',
    borderBottom: '4px solid transparent',
    margin: '0 24px',
    display: 'inline-block',
    cursor: 'Pointer',
    position: 'relative',
    '&:hover': {
      borderBottom: '4px solid #1f7cf2',
      opacity: '1',
    },
    '&.current': {
      borderBottom: '4px solid #1f7cf2',
      opacity: '1',
    }
  },
  navButtonLinkMobile: {
    transition: 'all 200ms',
    background: '#fed202',
    fontSize: '14px',
    lineHeight: 1.43,
    color: '#42525a',
    fontWeight: 600,
    textDecoration: 'none',
    textTransform: 'initial',
    borderRadius: '4px',
    padding: '15px 15px',    
    display: 'inline-block',
    cursor: 'Pointer',
    position: 'relative',       
    [theme.breakpoints.up('lg')]: {
      margin: '0 24px',
    },

  },
  navButtonLink: {
    transition: 'all 200ms',
    background: '#fed202',
    fontSize: '14px',
    lineHeight: 1.43,
    color: '#42525a',
    fontWeight: 600,
    textDecoration: 'none',
    textTransform: 'initial',
    borderRadius: '6px',
    padding: '10px 15px',    
    display: 'inline-block',
    cursor: 'Pointer',
    position: 'relative',       
    [theme.breakpoints.up('lg')]: {
      margin: '0 24px',
    },

  },
  navLinkSignIn: {
    transition: 'all 200ms',
    fontSize: '14px',
    lineHeight: 1.25,
    fontWeight: 600,
    textTransform: 'initial',
    padding: '30px 0px',
    borderBottom: '4px solid transparent',
    margin: '0 24px 0 0',
    display: 'inline-block',
    cursor: 'Pointer',
    color: '#fed202',
    textDecoration: 'underline'
  },
  navSubLink: {
    transition: 'all 200ms',
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'white',
    margin: '0 20px',
    '&:hover': {
      borderRadius: '8px',
      backgroundColor: 'rgba(242, 244, 246, 0.12)',
      '& span': {
        opacity: '1',
      }
    },
    '&.current': {
      borderRadius: '8px',
      backgroundColor: 'rgba(242, 244, 246, 0.12)',
      '& span': {
        opacity: '1',
      }
    },
    '& button': {
      padding: '12px 20px',      
    },
    '& span': {
      opacity: '0.8',
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: 1.25,
    },
    '& svg': {
      marginRight: '10px'
    }
  },
  logoWrap: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '80px'
  },
  primaryMenu: {
    padding: '0px 0px',
    background: "#fff"
  },
  secondaryMenu: {
    padding: '18px 0px',
    background: '#3d3d3d'
  },
  toolBar: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

  },
  toolBarMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  rightMenu: {
    display: 'flex',
    alignItems: 'center',
  },
  navSearch: {
    padding: '10px 20px',
    color: '#42525a',
    '&:hover': {
      color: 'blue'
    },
  },
  navButton: {
    color: '#333333'
  },
  logoWrapMobile: {
    padding: '15px 0'
  },
  drawerHeader:{
    padding: '10px 20px 0',
    textAlign: 'right',
    [theme.breakpoints.down('lg')]: {
      width: '400px'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '330px',
      width: '100%'
    },
  },
  primaryPanel: {
    padding: '10px 25px'
  },
  secondaryPanel: {
    padding: '20px 25px'
  },
  drawerClose: {
    cursor: 'pointer'
  },
  drawerText: {
    fontFamily: 'Bely',
    fontSize: '24px',
    color: '#42525a'    
  },
  drawerItem: {
    padding: '10px 0'
  },
  mobileMenu: {
    width: '100%',
    maxWidth: '400px'
  }
}));

function Navigation(props) {
  const classes = useStyles();
  const page = props.page;
  const [openDrawer, setOpenDrawer] = useState(false);

  let menuType = props.route == 'overview'? 'normal' : props.route;
  if (props.menuType == 'no_menu') {
    menuType=""
  }
  let isProfilePage  = false;
  if (page === "dashboard") {
    isProfilePage  = true;
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [opendrop, setOpendrop] = React.useState(false);
  console.log(firebase.auth().currentUser)
  let cUser =  firebase.auth().currentUser; 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpendrop(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpendrop(false);
  };

  const openMobileMenu = () => {
    setOpenDrawer(true);
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpenDrawer(open);
  };

  const renderMobileMenu = () => {
    return (
      <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>
        <Box className={classes.drawerHeader}>
          <div className={classes.drawerClose} onClick={toggleDrawer(false)}>
            <CloseIcon />
          </div>
        </Box>
        <Box className={classes.primaryPanel}>
          {
            cUser && <div className={classes.drawerItem + ' right_arrow'}>
              <Link href={ROUTES.DASHBOARD}><Typography className={classes.drawerText}>My Registries<ChevronRightIcon/></Typography></Link>
            </div>  
          }
          {
            cUser && <div className={classes.drawerItem + ' right_arrow'}>
              <Link href={ROUTES.SETTINGS}><Typography className={classes.drawerText}>Account Settings<ChevronRightIcon/></Typography></Link>
            </div>  
          }
          {
            /*<div className={classes.drawerItem}>
              <Link href="/campaigns"><Typography className={classes.drawerText}>Registries</Typography></Link>
            </div>*/
          }
          <div className={classes.drawerItem}>
            <Link href={ROUTES.WHY}><Typography className={classes.drawerText}>Why Sirvve</Typography></Link>
          </div>
          <div className={classes.drawerItem}>
            <Link href={ROUTES.CAMPAIGNS}><Typography className={classes.drawerText}>Featured Registries</Typography></Link>
          </div>   
          <div className={classes.drawerItem}>
            <Link href={ROUTES.ABOUT}><Typography className={classes.drawerText}>About</Typography></Link>
          </div>   

          <div className={classes.drawerItem}>
            <Link href="https://blog.sirvve.com/" target="_blank"><Typography className={classes.drawerText}>Blog</Typography></Link>
          </div>    

          <div className={classes.drawerItem}>
            <Link href={ROUTES.SIGN_UP} className={classes.navButtonLinkMobile} >Start a New Registry</Link>
          </div>   

        </Box>

        <Box className={classes.secondaryPanel + ' bottom_box'}>
          {
            cUser?(
              <div className={classes.drawerItem + " singout_mobile"}>
                <SignOutButton/>
              </div>   
            ):(
              <div className={classes.drawerItem}>
                <Link href={ROUTES.SIGN_IN}><Typography className={classes.drawerText}>Login</Typography></Link>
              </div>
            )
          }

          <div className={classes.drawerItem}>
            <Link href={ROUTES.AMBASSADOR_SIGN_UP}><Typography className={classes.drawerText}>Ambassador Signup</Typography></Link>            
          </div>
          <div className={classes.drawerItem}>
            <Link href={ROUTES.CONTACT}><Typography className={classes.drawerText}>Contact</Typography></Link>
          </div>  
        </Box>
      </Drawer>
    );
  }

  const renderMobileNavbar = () => {
    return (
      <div className={classes.toolBarMobile}>  
        <a className={classes.logoWrapMobile} href="/">
          <img src={logo} alt="Kitty Katty!" className="mainLogo"  />
        </a>

        <div className={classes.navButton}>
          <DehazeIcon onClick={openMobileMenu}/>
        </div>        
      </div>
    );
  }

  return (
    <div className={props.navClass}>
      <AppBar position="static">
        <div className={classes.primaryMenu}>
          <div className="Container">
            <div className={classes.toolBar}>           
              <a className={classes.logoWrap} href="/">
                <img src={logo} alt="Kitty Katty!" className="mainLogo" />
              </a>

              <div className="main-nav">
                <NavLink to={ROUTES.CAMPAIGNS} exact activeClassName="current" >Featured</NavLink>
                <NavLink to={ROUTES.WHY} exact activeClassName="current" >Why Sirvve</NavLink>
                <NavLink to={ROUTES.ABOUT} exact activeClassName="current" >About</NavLink> 
                <Link href="https://blog.sirvve.com/" className={classes.navText} target="_blank">Blog</Link>  
              </div>

              <div className={classes.grow} />            
                <NavLink to={ROUTES.CAMPAIGNS} className={classes.navSearch} exact activeClassName="current" >
                  <SearchIcon/>
                </NavLink>
              {
                !cUser && menuType!='signup' && menuType!='signin' && (
                  <div className={classes.rightMenu}>   
                    <NavLink to={ROUTES.SIGN_IN} className={classes.navLink} exact activeClassName="current" >Login</NavLink>
                    <NavLink to={ROUTES.SIGN_UP} className={classes.navButtonLink} exact activeClassName="current" >Start a Meal Registry</NavLink>
                  </div>
                )
              }
              {
                !cUser && menuType == 'signup' &&
                <div className="main-menu">
                  Already have an account? <NavLink to={ROUTES.SIGN_IN} className={classes.navLinkSignIn} exact activeClassName="current" >Sign In</NavLink>
                </div>

              }
              {
                menuType == 'signin' &&
                <div className="main-menu">
                  <NavLink to={ROUTES.SIGN_UP} className={classes.navLink} exact activeClassName="current" >Get Started</NavLink>
                </div>

              }
              {
                cUser && 
                <div className="main-menu drop-menu">
                  <a style={{paddingRight: "20px"}} className={classes.navLink} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    {cUser && cUser.displayName ? cUser.displayName : 'Anonymous'}
                    {opendrop ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                  </a>
                    <NavLink to={ROUTES.SIGN_UP} className={classes.navButtonLink} exact activeClassName="current" >Start a Meal Registry</NavLink>
                </div>
              }
              {
                cUser && 
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  className="dropdown-menu"
                >
                  <MenuItem onClick={handleClose}><NavLink to={ROUTES.DASHBOARD} exact activeClassName="current" ><DashboardIcon/>My Registries</NavLink></MenuItem>
                  <MenuItem onClick={handleClose}><NavLink to={ROUTES.SETTINGS} exact activeClassName="current" ><SettingsIcon/> Account Settings</NavLink></MenuItem>
                  <MenuItem onClick={handleClose}><NavLink to={ROUTES.SIGN_UP} exact activeClassName="current" ><CardGiftcardIcon/> Start a New Registry</NavLink></MenuItem>
                  <MenuItem onClick={handleClose}><SignOutButton /></MenuItem>
                </Menu>
              }
            </div>

            {renderMobileNavbar()}
            {renderMobileMenu()}
          </div>
        </div>
      </AppBar>      
    </div>
  );
}

export default Navigation;
