import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import firebase from "firebase";
import Layout from '../Page';
import ToggleSwitch from '../ToggleSwitch';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import campaign_img from "../../images/campaign.png";

import {getUser} from '../Utils/users';
import { AuthUserContext } from '../Session';
import { withAuthorization } from '../Session';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import {getAllGifts} from '../Utils/gifts';

import ShareCmapaignModal from './shareCampaignModal';
import {getCampaigns} from '../Utils/campaigns';
import emptystate from "../../images/emptystate.svg";

const DashboardPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <Layout additionalClass="header_add" name={authUser.displayName?authUser.displayName.split(" ")[0]:''}>
        <DashboardForm authUser={authUser}/>
      </Layout>
    )}
  </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
  accImg: null,
  campaigns: [],
  gifts: [],
  openModal: false,
  selectedCampaign: null
};

class DashboardFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.storage = props.firebase.getstorage();
    this.db = this.props.firebase.getdb();
    this.firebase = this.props.firebase;
    getUser(this.db, props.authUser.email, this.fetchUserData);
    console.log(props.authUser)
  }
  componentDidMount() {
    getCampaigns(this.db, this.props.authUser.email, this.loadCampaigns)
    getAllGifts(this.db, this.giftsData)
  }
  giftsData = (data) => {
    let gifts = data.sort(function(a,b){
      return new Date(b.createdDate) - new Date(a.createdDate)
    })
    this.setState({gifts: data, pageNumber: Math.ceil(data.length / 8), pageStep: 1})
  }
  handleCloseDialog = () => {
    this.setState({openModal: false})
  }
  loadCampaigns = items => {
    console.log('campaigns:', items)
    this.setState({campaigns: items})
  }
  fetchUserData = item => {
    this.setState({accImg: item.accImg, name: item.name})
  }
  changeStatus = (e, campaignStatus) => {
    console.log(e.target.parentElement, campaignStatus)
    if (campaignStatus == true)
      e.target.parentElement.classList.add("active")
    else
      e.target.parentElement.classList.remove("active")
  }
  onSubmit = event => {
    const { } = this.state;
    

    event.preventDefault();
  }
  render() {
    const {
      accImg,
      name,
      gifts,
      openModal,
      selectedCampaign, 
      recommandationGoals 
    } = this.state;
    let status_vals = ['Active', 'Pending', 'Inactive', 'Completed', 'Incomplete']
    return (
      <div>
        <div className="page-banner Content">
          {accImg && <img className="account-img" src={accImg} />}
          <div className="account-content">
            <h2>{name}</h2>
            <p>{this.props.authUser.email}</p>
          </div>
        </div>
        <ShareCmapaignModal campaign={selectedCampaign} open={openModal} handleClose={this.handleCloseDialog} />  
        <div className="Container">
          <div className="dashboards-form-wrap">
            {
              status_vals.map((status_val, ind) => {
                return <div className="campaigns-wrap" key={"val_k"+ind}>
                  {
                    this.state.campaigns.filter(campaign => campaign.campaignStatus == status_val).length>0 && <p className="campaigns-title">{status_val} Campaigns</p>
                  }
                  {
                    this.state.campaigns.filter(campaign => campaign.campaignStatus == status_val).map((campaign, index) => {
                      //let meals_donated = campaign.orderTotalMealsGiftedQty;
                      let meals_donated = parseInt(parseFloat(campaign.orderTotalCampaignSum)*parseInt(campaign.boxCount)/parseFloat(campaign.orderDeliveryPrice));

                      console.log(gifts, 'sdf')
                      var days_left = campaign.dateRegistered? parseInt((new Date() - campaign.dateRegistered.toDate())/24/60/60/1000 + parseInt(campaign.campaignLength)): 0
                      return <div className="campaigns-form" key={status_val + index}>
                        <div className="campaigns-dropdown">
                          <ExpandMoreIcon className="more" onClick={(e) => this.changeStatus(e, true)} />
                          <ExpandLessIcon className="less" onClick={(e) => this.changeStatus(e, false)} />
                          <ul>
                            <li><a onClick={()=>this.props.history.push('/campaign-overview/'+campaign.id)}>Edit Registry</a></li>
                            <li><a onClick={()=>this.setState({openModal: true, selectedCampaign: campaign})}>Share Registry</a></li>
                            <li><a onClick={()=>this.props.history.push('/campaign-overview/'+campaign.id+'?tab=4')}>View Gifts</a></li>
                          </ul>
                        </div>
                        <div className="form-left">
                          {campaign.campaignPhoto && <img src={campaign.campaignPhoto} style={{objectFit: 'contain', objectPosition: 'center'}} />}
                          {!campaign.campaignPhoto && <div className="placeholder-img"><img src={emptystate}/></div>}
                          <button onClick={()=>this.props.history.push('/campaign-overview/'+campaign.id)}>Edit Registry</button>
                          <button onClick={()=>this.props.history.push('/view/'+campaign.id)}>View Registry</button>
                        </div>
                        <div className="form-right">
                          <p className="campaign_title">{campaign.campaignTitle}</p>
                          <div className="progress_bar">
                            <div className="cur_progress" style={{width: (meals_donated>campaign.recommandationGoals ? "100" : parseInt(meals_donated*100/campaign.recommandationGoals)) + "%"}}>
                            </div>
                          </div>
                          <p className="campaign_detail">
                            {gifts.filter(gift => gift.campaignId == campaign.id).length} gifts{
                              (meals_donated<campaign.recommandationGoals) &&
                              " • " + days_left + " days left"
                            }
                          </p>
                          <div className="campaign_info">
                            <div className="campaign_donated">
                              <p className="count">{meals_donated?meals_donated: 0}</p>
                              <p className="comment">Servings Gifted</p>
                            </div>
                            <div className="campaign_goal">                            
                              <p className="count">{meals_donated>campaign.recommandationGoals?100:Math.ceil(meals_donated*100.0/campaign.recommandationGoals)}%</p>
                              <p className="comment">Funded</p>
                            </div>
                            <div className="campaign_funded">
                              <p className="count">{campaign.recommandationGoals/4}</p>
                              <p className="comment">Family Meals in Goal</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    })
                  }
                  
                </div>
            })}
          </div>
        </div>
      </div>
    );
  }
}


const authCondition = authUser => !!authUser;
const DashboardForm = withRouter(withFirebase(DashboardFormBase));

export default withAuthorization(authCondition)(DashboardPage);

export { DashboardForm };