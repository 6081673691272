import React, { useState, useEffect } from 'react';
import { fade, makeStyles, withStyles  } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputBase from '@material-ui/core/InputBase';
import { withAuthorization } from '../Session';
import FacebookIcon from '@material-ui/icons/Facebook';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {
  FacebookShareButton,
  EmailShareButton,
  TwitterShareButton,
  LinkedinShareButton
} from "react-share";
const useHeaderStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: '#000000',
  },
  greenButton: {
    background: '#1c986a',
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial'
  },
  linkWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '470px',
    maxWidth: '100%',
    margin: '0 auto',
    border: 'solid 1px rgba(66, 82, 90, 0.24)',
    padding: '20px 0',
    borderRadius: '6px'
  },
  linkWrapText: {
    flex: 1,
    textAlign: 'left',
    fontSize: '18px',
    color: '#42525a',
    fontWeight: '600'
  },
  linkWrapSvg: {
    marginLeft: '30px',
    marginRight: '30px'
  },
  modalTitle: {
    fontFamily: 'OpenSans' 
  }
}));

const DEFAULT_STATE = {
};


function ShareCmapaignModal(props) {
  const {handleClose, open, item, isCreate} = props;

  useEffect(() => {
  }, [props.item]);

  const handleCloseDialog = (event) => {
    handleClose(); 
  }
  const copyCodeToClipboard = (str) => {
    navigator.clipboard.writeText(str);
  }

  const classes = useHeaderStyles();
  const scroll = 'paper';
  return (
    <Dialog onClose={handleCloseDialog} 
        aria-labelledby="revision-dialog-title" 
        open={open} 
        disableBackdropClick={true}
        fullWidth={true} 
        maxWidth={"sm"} 
        scroll={scroll}
        style={{textAlign: "center"}}
        className="share-campaign-modal"
    >
        <DialogTitle onClose={handleCloseDialog} style={{padding: '60px 40px 30px', position: 'relative'}}>
          <Typography variant="h1" className={classes.modalTitle}>Share Registry</Typography>   
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{padding: '0 40px'}}>
          <Box style={{padding: '0 0'}}>
            <FacebookShareButton url={"https://sirvve.com/view/"+(props.campaign ? props.campaign.id : "")} style={{paddingBottom: '20px', maxWidth: '100%'}}
             quote={props.campaign ? props.campaign.title : ""} 
             hashtag="#sirvve">
              <div className={classes.linkWrap}>
                <FacebookIcon className="link-wrap-facebook link-icon"/>
                <span className={classes.linkWrapText}>Facebook</span>
                <ArrowForwardIcon className={classes.linkWrapSvg} />
              </div>
            </FacebookShareButton>
            <a style={{color: 'black', textDecoration: 'none'}} href={"mailto:"+(props.campaign ? props.campaign.campaignAdmin : '')+"?subject=Meals for " + (props.campaign ? props.campaign.recipientFirstName : "") + "&body=Dear friends and family, %0D%0A%0D%0A\
I have setup a meal registry on a new platform called Sirvve! Here you can purchase delicious meals based on their dietary preferences. All meals will be delivered directly to "+(props.campaign ? props.campaign.recipientFirstName+" "+props.campaign.recipientLastName : "")+". %0D%0A%0D%0AFeel free to email me with any questions and just click the link below to give. %0D%0Ahttps://sirvve.com/view/"+(props.campaign ? props.campaign.id : "")+ "%0D%0A%0D%0AThanks!%0D%0A"}>
              <div className={classes.linkWrap}>
                <MailOutlineIcon className="link-wrap-email link-icon" />
                <span className={classes.linkWrapText}>Email</span>
                <ArrowForwardIcon className={classes.linkWrapSvg} />
              </div>
            </a>
            <TwitterShareButton  style={{paddingBottom: '20px', paddingTop: '20px', maxWidth: '100%'}} url={"https://sirvve.com/view/"+(props.campaign ? props.campaign.id : "")}
              title={props.campaign ? props.campaign.title : ""}
              hashtag="#sirvve">
              <div className={classes.linkWrap}>
                <TwitterIcon className="link-wrap-twitter link-icon" />
                <span className={classes.linkWrapText}>Twitter</span>
                <ArrowForwardIcon className={classes.linkWrapSvg} />
              </div>
            </TwitterShareButton>
            <LinkedinShareButton  style={{paddingBottom: '120px', maxWidth: '100%'}} url={"https://sirvve.com/view/"+(props.campaign ? props.campaign.id : "")}>
              <div className={classes.linkWrap}>
                <LinkedInIcon  className="link-wrap-linkedin link-icon" />
                <span className={classes.linkWrapText}>LinkedIn</span>
                <ArrowForwardIcon className={classes.linkWrapSvg} />
              </div>
            </LinkedinShareButton>
          </Box>
        </DialogContent>

        <DialogActions className="share-actions" style={{flexWrap: 'wrap', padding: '38px 20px 70px', borderTop: "solid 1px rgba(217, 223, 233, 0.4)", marginLeft: "40px", marginRight: "40px", justifyContent: 'space-between'}}>
          <input disabled type="text" style={{flex: 1, height: "44px", borderRadius: "6px", borderWidth: "1px", paddingLeft: "30px", paddingRight: "30px"}} defaultValue={"sirvve.com/view/"+(props.campaign ? props.campaign.id : "")} />
          <Button  onClick={() => copyCodeToClipboard("https://sirvve.com/view/"+(props.campaign ? props.campaign.id : ""))} variant="contained" color="primary" className={classes.greenButton}>Copy</Button>
        </DialogActions>
    </Dialog>
  );
}


export default ShareCmapaignModal;
