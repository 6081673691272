
const GCP_PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

const collections = {
    CAMPAIGNS: "Campaigns",
    USERS: "Admins",
    GIFTS: "Gifts",
    SHIPPINGRATE: "ShippingRate"
};

const projectSettings = {
    'sirvve': {
        bucket: "sirvve.appspot.com",
        apiTicket: "https://us-central1-sirvve.cloudfunctions.net/request",
        environment: "production"
    },
    'sirvve-dev': {
        bucket: "sirvve-dev.appspot.com",
        apiTicket: "https://us-central1-sirvve-dev.cloudfunctions.net/request",
        environment: "dev"
    }
}

const API = {
    sendTicket: projectSettings[GCP_PROJECT_ID].apiTicket
}

const CampaignCategories = [
    { value: 'Newborn', label: 'Newborn' },
    { value: 'Injury Recovery', label: 'Injury Recovery' },
    { value: 'Illness', label: 'Illness' },
    { value: 'Grief', label: 'Grief' },
    { value: 'Disability Support', label: 'Disability Support' },
    { value: 'Long Term Care', label: 'Long Term Care' },
    { value: 'Other', label: 'Other' }
];

const CampaignMealOptions = [
    { value: 'breakfast', label: 'Breakfast' },
    { value: 'lunch', label: 'Lunch' },
    { value: 'dinner', label: 'Dinner' }
];
  
const CampaignDietaryOptions = [
    { value: 'gluten free', label: 'Gluten free' },
    { value: 'not spicy', label: 'Not Spicy' },
    { value: 'no seafood', label: 'No Seafood' },
    { value: 'vegetarian', label: 'Vegetarian' },
    { value: 'dairy free', label: 'Dairy free' },
    { value: 'egg free', label: 'Egg free' },
    { value: 'nut free', label: 'Nut free' },
    { value: 'soy free', label: 'Soy free' },
    { value: 'none', label: 'None' },
    { value: 'no pork', label: 'No Pork' },
    { value: 'no beef', label: 'No Beef' },
    { value: 'no chicken', label: 'No Chicken' },
];

const CampaignGoalTypeOptions = [
    { value: 'monthly', label: 'Monthly' },
    { value: 'annual', label: 'Annual' }
];

const CampaignDurationOptions = [
    { value:'28', label:'4 weeks' },
    { value:'42', label:'6 weeks' },
    { value:'60', label:'2 months' },
    { value:'90', label:'3 months' },
    { value:'120', label:'4 months' },
    { value:'180', label:'6 months' },
  ];

export {
    collections,
    API,
    CampaignCategories,
    CampaignMealOptions,
    CampaignDietaryOptions,
    CampaignGoalTypeOptions,
    CampaignDurationOptions
};
