import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import Layout from '../Page';

import img1 from "../../images/why_hero.jpg";
import img2 from "../../images/Rectangle1.jpg";
import img3 from "../../images/Rectangle2.jpg";
import img4 from "../../images/Rectangle3.jpg";
import img5 from "../../images/Rectangle4.jpg";
import img6 from "../../images/Rectangle5.jpg";


import img17 from "../../images/step1.gif";
import img18 from "../../images/step2.gif";
import img19 from "../../images/step3.gif";
import img20 from "../../images/step4.gif";

const WhyPage = () => (
    <Layout additionalClass="no_wrap" menuType="no_menu" page="landing">
      <WhyPageWrap/>
    </Layout>
  );


function WhyPageComponent(props) {

    return (
        <div className="view-campaign">

            {/* Section 11 */}
            <div className="section" id="section11">
                <div className="flexbox">
                  <div className="item-45">
                      <div className="item-box6">
                          <div className="item-box">
                            <div class="bannersubtitle">Why Sirvve</div>
                            <h1 class="bannertitle">Supporting loved ones just got easier</h1>
                            <a href="/signup" class="banner-link">Get Started</a>
                          </div>
                      </div>
                  </div>
                  <div className="item-55">
                    <div className="image-box">
                        <img src={img1} alt="" className="full-image" />
                    </div>
                  </div>
                </div>
            </div>



            {/* Section 12 */}
            <div className="section" id="section12">
              <div className="page-container">
                <div className="heading-box">
                  <h2 className="sectiontitle">Support made simple</h2>
                  <div className="sectionbigtext">Find out what makes Sirvve worth dishing up for the ones you love.</div>
                </div>

                <div className="content-box">
                    <div className="item-box">
                        <div className="flexbox">
                            <div className="item-half">
                                <div className="text-box4">
                                    
                                    <h2 className="sectiontitle">Just how they like it</h2>
                                    <div className="sectionbigtext">
                                        No gluten, no dairy, no soy? No problem. You can tailor your chef-crafted meals to any dietary needs and food preferences.
                                    </div>
                                </div>
                            </div>
                            <div className="item-half">
                            <div className="image-box">
                                <img src={img2} alt="" className="full-image" />
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="item-box">
                        <div className="flexbox">
                            <div className="item-half">
                                <div className="text-box4">
                                    
                                    <h2 className="sectiontitle">Fresh and secure </h2>
                                    <div className="sectionbigtext">
                                    Meals are delivered when and where they’re needed, and stay cool in sustainable insulated packages.
                                    </div>
                                </div>
                            </div>
                            <div className="item-half">
                            <div className="image-box">
                                <img src={img3} alt="" className="full-image" />
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="item-box">
                        <div className="flexbox">
                            <div className="item-half">
                                <div className="text-box4">
                                    
                                    <h2 className="sectiontitle">No stress, no mess</h2>
                                    <div className="sectionbigtext">
                                    We'll do the cooking so you don't have to—all they have to do is heat and eat! No plates required, so cleanup stays easy. 
                                    </div>
                                </div>
                            </div>
                            <div className="item-half">
                            <div className="image-box">
                                <img src={img4} alt="" className="full-image" />
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="item-box">
                        <div className="flexbox">
                            <div className="item-half">
                                <div className="text-box4">
                                    
                                    <h2 className="sectiontitle">Seamless support</h2>
                                    <div className="sectionbigtext">
                                    We'll handle scheduling and contactless delivery, so you never have to worry whether you're intruding.
                                    </div>
                                </div>
                            </div>
                            <div className="item-half">
                            <div className="image-box">
                                <img src={img5} alt="" className="full-image" />
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="item-box">
                        <div className="flexbox">
                            <div className="item-half">
                                <div className="text-box4">
                                    
                                    <h2 className="sectiontitle">Crowd pleasing dishes</h2>
                                    <div className="sectionbigtext">
                                    They'll enjoy freshly-prepared recipes made from high quality ingredients that are good-for-you and taste good, too.
                                    </div>                                    
                                </div>
                            </div>
                            <div className="item-half">
                                <div className="image-box">
                                    <img src={img6} alt="" className="full-image" />
                                </div>
                            </div>
                        </div>
                    </div>                                                                                
                </div>
              </div>
            </div>


            {/* Section 6 */}
            <div className="section" id="section6">
              <div className="page-container">
                <div className="heading-box">
                  <h2 className="sectiontitle">How registries work</h2>
                </div>
                <div className="flexbox offset-25">
                  <div className="item-4-2-1">
                    <div className="item-box" id="camp_1">
                      <div className="item-wrapper">
                        <div className="item-badge">1</div>
                        <div className="image-box max-200">
                          <img src={img17} alt="" className="full-image" />
                        </div>
                        <h3 className="sectionsmalltitle">You create a<br/> registry</h3>
                        <p className="sectiontext">Get started in just five minutes by filling in a few details. Easily manage preferences and updates as you go.</p>
                      </div>
                      
                    </div>
                  </div>

                  <div className="item-4-2-1">
                    <div className="item-box" id="camp_2">
                      <div className="item-wrapper">
                        <div className="item-badge">2</div>
                        <div className="image-box max-200">
                          <img src={img18} alt="" className="full-image" />
                        </div>
                        <h3 className="sectionsmalltitle">Everyone<br/> gives meals</h3>
                        <p className="sectiontext">Invite friends and loved ones to  give healthy, delicious meals that show someone they care. </p>                        
                      </div>
                      
                    </div>
                  </div>
                  
                  <div className="item-4-2-1">
                    <div className="item-box" id="camp_3">
                      <div className="item-wrapper">
                        <div className="item-badge">3</div>
                        <div className="image-box max-200">
                          <img src={img19} alt="" className="full-image" />
                        </div>
                        <h3 className="sectionsmalltitle">We prep, cook<br/> & deliver</h3>
                        <p className="sectiontext">Our chefs craft high-quality, family-style meals from scratch and freeze them at the peak of freshness for delivery right to recipients’ doors.</p>
                      </div>

                    </div>
                  </div>

                  <div className="item-4-2-1">
                    <div className="item-box" id="camp_4">
                      <div className="item-wrapper">
                        <div className="item-badge">4</div>
                        <div className="image-box max-200">
                          <img src={img20} alt="" className="full-image" />
                        </div>
                        <h3 className="sectionsmalltitle">They enjoy!</h3>
                        <p className="sectiontext">With a few minutes of reheating, your recipients will enjoy heartfelt, chef-cooked meals with easy cleanup.</p>
                      </div>
                      
                    </div>
                  </div>
                </div>

                <div className="bottom-box">
                    <a href="/signup" className="section-grouplink ">Start a Meal Registry</a>
                    <a href="/campaigns" className="section-grouplink green">Learn More</a>
                </div>
              </div>
            </div>

        </div>
    );
}

const WhyPageWrap = withRouter(withFirebase(WhyPageComponent));
export default WhyPage;


