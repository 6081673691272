import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import CssBaseline from '@material-ui/core/CssBaseline';
import * as ROUTES from '../../constants/routes';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../Page';
import Container from '@material-ui/core/Container';

const styles = (theme) => ({
  paper: {

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
      width: "200px",
      height: "48px",
      border: "none",
      background: "#fed202",
      borderRadius: "6px",
      fontSize: "14px",
      fontWeight: 600,
      color: "#42525a",
      textTransform: 'initial',
      margin: "40px calc(50% - 100px) 0",
      cursor: "pointer"
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10
  },
  progess: {
    position: 'absolute'
  },
    navLink: {
      transition: 'all 200ms',
      fontSize: '12px',
      lineHeight: 1.25,
      color: 'rgba(66, 82, 90, 0.64)',
      textDecoration: 'none',
      textTransform: 'initial',
      paddingTop: '20px',
      borderBottom: '4px solid transparent',
      display: 'inline-block',
      cursor: 'Pointer',
      '&:hover': {
        opacity: '1',
      },
      '&.current': {
        opacity: '1',
      }
    },
    navLinkSpan: {
      fontSize: '14px',
      textDecoration: 'underline',
      color: '#fed202'
    },
    spaceFlex: {
      display: 'flex',
      justifyContent: 'space-between'
    }
});


const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;
    console.log(email)
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const { classes } = this.props;
    const isInvalid = email === '';

    return (
      <Layout route="signin">
        <div className="signup_wrap">
          <div className="signup_content">
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <h1>Forgot Password</h1>
                <p>Enter your email address, and we’ll email you a link to reset your password</p>
                  <div className="form-content-wrap">
                  <input
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Email"
                  />
                  <button disabled={isInvalid} onClick={this.onSubmit}>
                    Reset My Password
                  </button>

                  {error && <p className="error_message">{error.message}</p>}
                </div>
              </div>
            </Container>
          </div>
        </div>
      </Layout>
    );
  }
}

const PasswordForgetPage = compose(
  withRouter,
  withFirebase,
  withStyles(styles)
)(PasswordForgetFormBase);

export default PasswordForgetPage;


