import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { Paper, Button } from '@material-ui/core'
import * as ROUTES from '../../constants/routes';
import Grid from '@material-ui/core/Grid';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Layout from '../Page';
import img1 from "../../images/Why-Worry.gif";
import img2 from "../../images/Fresh-and-Clean.gif";
import img3 from "../../images/Mess-Less.gif";
import img4 from "../../images/Make-a-Connection.gif";
import img5 from "../../images/curated_meals.jpg";

import img6 from "../../images/Group1.svg";
import img7 from "../../images/Group2.svg";
import img8 from "../../images/Group3.svg";
import img9 from "../../images/Group4.svg";
import img10 from "../../images/newborn.jpg";
import img11 from "../../images/injury-recovery.jpg";
import img12 from "../../images/illness.jpg";
import img13 from "../../images/grief.jpg";
import img14 from "../../images/disability.jpg";
import img15 from "../../images/long-term.jpg";
import img16 from "../../images/campaigns.jpg";

import Helmet from 'react-helmet';
import img17 from "../../images/step1.gif";
import img18 from "../../images/step2.gif";
import img19 from "../../images/step3.gif";
import img20 from "../../images/step4.gif";
import img21 from "../../images/second-helpings.jpg";
import img22 from "../../images/video-image.jpg";
import imgTestimonial1 from "../../images/Cara_crop.png";
import imgTestimonial2 from "../../images/keesha.png";
import imgTestimonial3 from "../../images/kim-crop.png";
import quoteSvg from "../../images/quote.svg";

import imgSlide1 from "../../images/hp_slide1.jpg";
import imgSlide2 from "../../images/hp_slide2.jpg";
import imgSlide3 from "../../images/hp_slide3.jpg";
import imgSlide4 from "../../images/hp_slide4.jpg";
import imgSlide5 from "../../images/hp_slide5.jpg";
import imgSlide6 from "../../images/hp_slide6.jpg";

import imgLogo1 from "../../images/logo/logo_google.svg";
import imgLogo2 from "../../images/logo/logo_yahoo.svg";
import imgLogo3 from "../../images/logo/logo_star.svg";
import imgLogo4 from "../../images/logo/logo_news.svg";

import {getDaysDiff, getDaysLeft} from '../Utils/functions';
import {getFeaturedCampaigns} from '../Utils/campaigns';
import {getAllGifts} from '../Utils/gifts';
import {CampaginCard} from '../Page/campaign';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';

const LandingPage = () => (
    <Layout additionalClass="no_wrap" menuType="no_menu" page="landing">
      <LandingPageWrap/>
    </Layout>
  );

function BannerItem(props) {
    return (
        <Paper>
            <div className="section">
              <div className="image-box">
                <img src={props.item.image} alt="" className="full-image" />
              </div>
              <div className="overlay-box">
                <div className="text-box">
                  <h1 className="bannertitle">{props.item.name}</h1>
                  <div className="bannersubtitle">{props.item.description}</div>
                  <a href={props.item.cta_link} className="banner-link">{props.item.cta}</a>
                </div>
              </div>
            </div>

        </Paper>
    )
}

function TestimonialItem(props) {
  return (
      <Paper style={{height:'100%'}}>
        <div className="testimonial-box">
          <div className="content-box">
            <div className="image-box3">
              <img src={props.item.image} className="full-image" alt="" />
            </div>
            <div className="text-box2">
              <div className="image-box4">
                <img src={quoteSvg} alt="" />
              </div>

              <div className="sectiontext">{props.item.description}</div>
            </div>
            <div className="text-box3">
              <div className="sectionbigtext">{props.item.name1}</div>
              <div className="sectionsmalltext">{props.item.name2}</div>
            </div>
          </div>
        </div>
      </Paper>
  )
}

function LandingPageComponent(props) {
  const [campaigns, setCampaigns] = useState([]);
  const [gifts, setGifts] = useState([]);
  const [openYTModal, setOpenYTModal] = useState(false);

  const db = props.firebase.getdb();

    useEffect(() => {
      getFeaturedCampaigns(db, fetchCampaignData);
      getAllGifts(db, fetchGift);
    }, []);
  
    const fetchCampaignData = items => {
      setCampaigns([...items]);
    }

    const fetchGift = items => {
      setGifts([...items]);
    }

    var bannerSliderItems = [
      {
          name: "Giving meals made easy",
          description: "Share chef-crafted meals with someone you love, when and where they need it.",
          cta: "Start Free Now",
          cta_link: "/signup",
          image: imgSlide1
      },
      {
        name: "Giving meals made easy",
        description: "Share chef-crafted meals with someone you love, when and where they need it.",
        cta: "Start Free Now",
        cta_link: "/signup",
        image: imgSlide2
      },
      {
        name: "Giving meals made easy",
        description: "Share chef-crafted meals with someone you love, when and where they need it.",
        cta: "Start Free Now",
        cta_link: "/signup",
        image: imgSlide3
      },
      {
        name: "Giving meals made easy",
        description: "Share chef-crafted meals with someone you love, when and where they need it.",
        cta: "Start Free Now",
        cta_link: "/signup",
        image: imgSlide4
      },
      {
        name: "Giving meals made easy",
        description: "Share chef-crafted meals with someone you love, when and where they need it.",
        cta: "Start Free Now",
        cta_link: "/signup",
        image: imgSlide5
      },
      {
        name: "Giving meals made easy",
        description: "Share chef-crafted meals with someone you love, when and where they need it.",
        cta: "Start Free Now",
        cta_link: "/signup",
        image: imgSlide6
      },
    ];

    var testimonialSliderItems = [
      {
          name1: "-Cara L.",
          name2: "Newborn",
          description: "Just gave meals to my best friend and her daughter in Seattle….love you Jessica!   Never had to leave NC or wash a dish😊",
          image: imgTestimonial3
      },
      {
        name1: "-Keesha S.",
        name2: "",
        description: "I work in the school system and am frequently asked to contribute to and sometimes plan a meal train.   On Sirvve I was able to gift my meals and have started two registries for a couple of people I know that could really use the help.  This is my new “run to” for meal giving.    Thank you Sirvve! ",
        image: imgTestimonial2
      },
      {
        name1: "-Kim L.",
        name2: "Cancer",
        description: "I received a gift request link from a friend of mine hosting a registry for someone battling cancer.  I had wanted to do something for the family and didn’t know what to bring or when to drop by.   Giving meals is sometimes hard because you never know what people really want.  I LOVE the idea of giving meals (or money) towards their meal registry.  Super EASY.",
        image: imgTestimonial1
      }
    ];

    var testimonialSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      fade: true
    };

    var bannerSettings = {
      dots: false,
      autoplay: true,
      infinite: true,
      lazyLoad: true,
      speed: 1000,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      fade: true
    };

    const renderCampaignInfo = (campaign) => {
      let meals_donated = parseInt(parseFloat(campaign.orderTotalCampaignSum)*parseInt(campaign.boxCount)/parseFloat(campaign.orderDeliveryPrice));
      let recommandationGoals = campaign.recommandationGoals;
      return (
        <div className="campaign_box">
          <div className="progress_bar">
            <div className="cur_progress" style={{width: (meals_donated>recommandationGoals ? "100" : parseInt(meals_donated*100/recommandationGoals)) + "%"}}>
            </div>
          </div>

          <div className="campaign_info">
            <div className="campaign_donated">
              <p className="count">{meals_donated}</p>
              <p className="comment">MEALS GIFTED</p>
            </div>
            <div className="campaign_funded">
              <p className="count">{recommandationGoals}</p>
              <p className="comment">MEALS IN GOAL</p>
            </div>                  
            <div className="campaign_goal">
              <p className="count">{meals_donated>recommandationGoals?100:Math.ceil(meals_donated*100.0/recommandationGoals)}%</p>
              <p className="comment">Funded</p>
            </div>
          </div> 
        </div>
       
      );
    }

    const renderFeatureCampaigns = () => {
      const items = _.filter(campaigns, {featured: true});
      return (
        <div className="section" id="section5">
          <div className="page-container">
            <div className="heading-box">
              <h2 className="sectiontitle">Discover Registries</h2>
              <div className="sectionbigtext">Learn how people are supporting members of their communities with Sirvve.</div>
            </div>

            <Grid container spacing={6}>
              {
                _.map(items, (campaign, index) => {
                  return (
                    <Grid item xs={12} sm={12} md={4}>
                      <CampaginCard key={`campaign_card_${campaign.id}`} campaign={campaign} label="View Registry" />                      
                    </Grid>
                  );
                })
              }
            </Grid>
          
            <div className="bottom-box">
              <a href={ROUTES.CAMPAIGNS}>See More >></a>
            </div>
          </div>
        </div>
      );
    }

    const handlePlayVideo = () => {
      setOpenYTModal(true);
    }

    return (
        <div className="view-campaign">
         
            {/* Section Slider */}
            <Slider {...bannerSettings}>
            {
                bannerSliderItems.map( (item, i) => <BannerItem key={i} item={item} /> )
            }
            </Slider>

            {/* Section 5 */}
            {renderFeatureCampaigns()}

            {/* Section 1 */}
            <div className="section" id="section1">
              <div className="page-container">
                <div className="flexbox offset-25">
                  
                  <div className="item-4-2-1">
                    <div className="item-box">
                      <div className="image-box max-200">
                        <img src={img1} alt="" className="full-image" />
                      </div>
                      <h3 className="sectionsmalltitle">Worry Free</h3>
                      <p className="sectiontext">Prep, scheduling and delivery are taken care of. No drop-ins necessary.</p>
                    </div>
                  </div>
                  
                  <div className="item-4-2-1">
                    <div className="item-box">
                      <div className="image-box max-200">
                        <img src={img2} alt="" className="full-image" />
                      </div>
                      <h3 className="sectionsmalltitle">Fresh &amp; Clean</h3>
                      <p className="sectiontext">Nutritious, chef-crafted recipes, catered to dietary needs and preferences.</p>
                    </div>
                  </div>
                  
                  <div className="item-4-2-1">
                    <div className="item-box">
                      <div className="image-box max-200">
                        <img src={img3} alt="" className="full-image" />
                      </div>
                      <h3 className="sectionsmalltitle">Mess Less</h3>
                      <p className="sectiontext">Heat in minutes with easy cleanup in fully compostable packaging.</p>
                    </div>
                  </div>
                  <div className="item-4-2-1">
                    <div className="item-box">
                      <div className="image-box max-200">
                        <img src={img4} alt="" className="full-image" />
                      </div>
                      <h3 className="sectionsmalltitle">Make a Connection</h3>
                      <p className="sectiontext">A thoughtful gift that shows someone meaningful support when it's needed.</p>
                    </div>
                  </div>

                  
                </div>
              </div>
            </div>


            {/* Section 2 */}
            <div className="section" id="section2">
              <div className="page-container">
                <div className="flexbox">
                  <div className="item-55">
                    <div className="image-box2">
                      <img src={img5} alt="" className="full-image" />
                    </div>
                  </div>
                  <div className="item-45">
                    <div className="item-box4">
                      <div className="heading-box left">
                        <h2 className="sectiontitle">Fully cooked & reheats in minutes</h2>
                        <p className="sectionbigtext">Our chefs create recipes based on selected meal and dietary preferences. This makes it easy to share dishes the whole family will love when they need it most. <br/><br/>What to expect.</p>
                      </div>
                      <div className="context-box1">
                        <div className="item-25">
                          <img src={img6} alt="" />
                          <div className="sectionsmalltext">Great family friendly recipes</div>
                        </div>
                        
                        <div className="item-25">
                          <img src={img7} alt="" />
                          <div className="sectionsmalltext">Based on preferences & dietary restrictions</div>
                        </div>
                        
                        <div className="item-25">
                          <img src={img8} alt="" />
                          <div className="sectionsmalltext">Fully cooked reheats in minutes</div>
                        </div>
                        <div className="item-25">
                          <img src={img9} alt="" />
                          <div className="sectionsmalltext">Arrives at their doorstep safely</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Section 3 */}
            <div className="section" id="section3">
              <div className="page-container">
                <div className="heading-box">
                  <h2 className="sectiontitle">Put love on the table</h2>
                  <div className="sectionbigtext">Easily organize a registry to send nourishing meals to a person or family who could use some support.</div>
                </div>
                <div className="flexbox offset-10">
                  <div className="item-6-3-2">
                    <div className="item-box5">
                      <div className="image-box">
                        <img src={img10} alt="" className="full-image" />
                      </div>
                      <div className="sectiontext">Newborn</div>
                    </div>
                  </div>
                  <div className="item-6-3-2">
                    <div className="item-box5">
                      <div className="image-box">
                        <img src={img11} alt="" className="full-image" />
                      </div>
                      <div className="sectiontext">Injury recovery</div>
                    </div>
                  </div>
                  <div className="item-6-3-2">
                    <div className="item-box5">
                      <div className="image-box">
                        <img src={img12} alt="" className="full-image" />
                      </div>
                      <div className="sectiontext">Illness</div>
                    </div>
                  </div>
                  <div className="item-6-3-2">
                    <div className="item-box5">
                      <div className="image-box">
                        <img src={img13} alt="" className="full-image" />
                      </div>
                      <div className="sectiontext">Grief</div>
                    </div>
                  </div>
                  
                  <div className="item-6-3-2">
                    <div className="item-box5">
                      <div className="image-box">
                        <img src={img14} alt="" className="full-image" />
                      </div>
                      <div className="sectiontext">Disability Support</div>
                    </div>
                  </div>
                  
                  <div className="item-6-3-2">
                    <div className="item-box5">
                      <div className="image-box">
                        <img src={img15} alt="" className="full-image" />
                      </div>
                      <div className="sectiontext">Long Term Care</div>
                    </div>
                  </div>
                </div>
                <div className="bottom-box">
                  <a href={ROUTES.SIGN_UP} className="section-grouplink green">Start a Meal Registry</a>
                  <a href={ROUTES.CAMPAIGNS} className="section-grouplink">See All Registries</a>
                </div>
              </div>
            </div>

            {/* Section 4 */}
            <div className="section" id="section4">
              <Slider {...testimonialSettings}>
              {
                testimonialSliderItems.map( (item, i) => <TestimonialItem key={i} item={item} /> )
              }  
              </Slider>
            </div>

            {/* Section 8 */}
            <div className="section" id="section8">
              <div className="page-container">
                <div className="flexbox">
                  <div className="item-55">
                    <div className="image-box2">
                      <img src={img22} alt="" className="full-image" />
                      <IconButton aria-label="close" size="large" className="section-youtube" onClick={handlePlayVideo}>
                        <PlayCircleOutline />
                      </IconButton>
                    </div>
                  </div>
                  <div className="item-45">
                    <div className="item-box3">
                      <div className="heading-box left">
                        <h2 className="sectiontitle">How to start a registry in under 5 minutes</h2>
                        <div className="sectionbigtext">
                          <span class="Watch-the-video-to-s">
                          Watch the video to see how you can start a free registry in just minutes. They’re 100% free, provide a easy way for everyone to give meals, and a gets the ones you love delicious meals that heat up quickly 
                          </span>
                        </div>

                        <button onClick={handlePlayVideo} className="section-grouplink1">Play Video</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Section 6 */}
            <div className="section" id="section6">
              <div className="page-container">
                <div className="heading-box">
                  <h2 className="sectiontitle">Registries bring people together</h2>
                </div>
                <div className="flexbox offset-25">
                  <div className="item-4-2-1">
                    <div className="item-box" id="camp_1">
                      <div className="item-wrapper">
                        <div className="item-badge">1</div>
                        <div className="image-box max-200">
                          <img src={img17} alt="" className="full-image" />
                        </div>
                        <h3 className="sectionsmalltitle">You create a<br/> registry</h3>
                        <p className="sectiontext">Get started in just five minutes by filling in a few details. Easily manage preferences and updates as you go.</p>
                      </div>
                      
                    </div>
                  </div>

                  <div className="item-4-2-1">
                    <div className="item-box" id="camp_2">
                      <div className="item-wrapper">
                        <div className="item-badge">2</div>
                        <div className="image-box max-200">
                          <img src={img18} alt="" className="full-image" />
                        </div>
                        <h3 className="sectionsmalltitle">Everyone<br/> gives meals</h3>
                        <p className="sectiontext">Invite friends and loved ones to  give healthy, delicious meals that show someone they care. </p>                        
                      </div>
                      
                    </div>
                  </div>
                  
                  <div className="item-4-2-1">
                    <div className="item-box" id="camp_3">
                      <div className="item-wrapper">
                        <div className="item-badge">3</div>
                        <div className="image-box max-200">
                          <img src={img19} alt="" className="full-image" />
                        </div>
                        <h3 className="sectionsmalltitle">We prep, cook<br/> & deliver</h3>
                        <p className="sectiontext">Our chefs craft high-quality, family-style meals from scratch and freeze them at the peak of freshness for delivery right to recipients’ doors.</p>
                      </div>

                    </div>
                  </div>

                  <div className="item-4-2-1">
                    <div className="item-box" id="camp_4">
                      <div className="item-wrapper">
                        <div className="item-badge">4</div>
                        <div className="image-box max-200">
                          <img src={img20} alt="" className="full-image" />
                        </div>
                        <h3 className="sectionsmalltitle">They enjoy!</h3>
                        <p className="sectiontext">With a few minutes of reheating, your recipients will enjoy heartfelt, chef-cooked meals with easy cleanup.</p>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='section' id="section9">
              <div className="page-container">
                  <div className="heading-box">
                    <h2 className="sectiontitle">As seen on</h2>
                    <div class="sectionbigtext">Sirvve is proud to be featured in the following publications</div>
                  </div>

                  <div className="content-box">
                    <div className='logo-item'><img src={imgLogo1} /></div>
                    <div className='logo-item'><img src={imgLogo2} /></div>
                    <div className='logo-item'><img src={imgLogo3} /></div>
                    <div className='logo-item'><img src={imgLogo4} /></div>
                  </div>
              </div>
            </div>

            {/* Section 7 */}
            <div className="section" id="section7">
              <div className="page-container">
                <div className="flexbox">
                  <div className="item-55">
                    <div className="image-box2">
                      <img src={img21} alt="" className="full-image" />
                    </div>
                  </div>
                  <div className="item-45">
                    <div className="item-box3">
                      <div className="heading-box left">
                        <h2 className="sectiontitle">Second helpings</h2>
                        <div className="sectionbigtext">Your table just got a little longer…every time you make a gift to a registry on Sirvve, you are sharing meals with folks that want support.  For every registry that meets its goal, Sirvve gifts 1 family meal to someone in need.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ModalVideo channel='youtube' isOpen={openYTModal} videoId='jdW7GHBrcrc' onClose={() => setOpenYTModal(false)} />
        </div>
    );
}

const LandingPageWrap = withRouter(withFirebase(LandingPageComponent));
export default LandingPage;


