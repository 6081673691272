import {collections} from './defines';
import _  from "lodash";

import Client from 'shopify-buy';

const client = Client.buildClient({
  storefrontAccessToken: '865772609f279a8d2d17f2a469825680',
  domain: 'checkout.sirvve.com'
});

const updateCampaignPrivate = (db, id, val) => {
  db
    .collection(collections.CAMPAIGNS)
    .doc(id)
    .update({isPrivate:val});
  
}

const updateCampaign = (db, id, data) => {
  console.log(data);
  db
    .collection(collections.CAMPAIGNS)
    .doc(id)
    .update(data);
  alert('Campaign updated!');
}
const getCampaign = (db, id, callback) => {
  db
    .collection(collections.CAMPAIGNS)
    .doc(id)
    .get()
    .then(snapshot => {
      var campaign = snapshot.data();
      campaign.id = id;
      callback(campaign);
    })
    .catch( error => {
      console.log('getCampaign: ', error);
    });
}
const getZipCodes = (db, callback) => {
  db
  .collection(collections.SHIPPINGRATE)
  .onSnapshot((snapshot) => {
    const items = _.map(snapshot.docs, item => {
      let data = item.data();
      return {
        start: data.start,
        end: data.end
      };
    });
    callback(items); 
  })

}
const getProductDetails = (db, zipcode, callback) => {
  db
  .collection(collections.SHIPPINGRATE)
  .onSnapshot((snapshot) => {
    if (snapshot.docs.length > 0) {
      var var_id = 0;
      client.product.fetchAll().then((res) => {
        const items = _.map(snapshot.docs, item => {
          let data = item.data();
            
          if(parseInt(data.start) <= parseInt(zipcode.substring(0, 3)) &&  parseInt(zipcode.substring(0, 3)) <= parseInt(data.end)) {          
      
            for (var i=0; i<res.length; i++) {
              if(res[i].handle == "test-meal") {
                let variants = res[i].variants;
                for (var j=0; j<variants.length; j++){
                  if(variants[j].title == ('meal-gift-zone-'+data.zone)){
                    var_id = variants[j].id;
                  }
                }
              }
            }
          }
        });
        callback(var_id)
      });    
    }
  },
  error => {
    callback(0);  
  });
}
const getPrices = (db, zipcode, callback) => {
  db
  .collection(collections.SHIPPINGRATE)
  .onSnapshot((snapshot) => {
    if (snapshot.docs.length > 0) {
      var price = process.env.REACT_APP_MEAL_PRICE;
      var rate = 0;
      var zone = 0;
      client.product.fetchAll().then((res) => {
        const items = _.map(snapshot.docs, item => {
          let data = item.data();
            
          if(parseInt(data.start) <= parseInt(zipcode.substring(0, 3)) &&  parseInt(zipcode.substring(0, 3)) <= parseInt(data.end)) {          
      
            for (var i=0; i<res.length; i++) {
              if(res[i].handle == "test-meal") {
                let variants = res[i].variants;
                for (var j=0; j<variants.length; j++){
                  if(variants[j].title == ('meal-gift-zone-'+data.zone)){
                    price = parseFloat(variants[j].price.amount);
                    zone = data.zone
                  }
                }
              }

              if(res[i].handle == "shipping-rate") {
                let variants = res[i].variants;
                for (var j=0; j<variants.length; j++){
                  if(variants[j].title == ('meal-delivery-zone-'+data.zone)){
                    rate = parseFloat(variants[j].price.amount);
                    zone = data.zone
                  }
                }
              }
            }
          }
        });
        console.log(price, rate);
        // callback(price, rate, zone)
        callback(price, 0, zone)
      });    
    }
  },
  error => {
    callback(process.env.REACT_APP_MEAL_PRICE, 0, 1);  
  });
}

const getPricesByZip = (db, zipcode) => {
  return new Promise( (resolve, reject) => {
    db
    .collection(collections.SHIPPINGRATE)
    .onSnapshot((snapshot) => {
      if (snapshot.docs.length > 0) {
        var price = process.env.REACT_APP_MEAL_PRICE;
        var rate = 0;
        var zone = 0;
        client.product.fetchAll().then((res) => {
          const items = _.map(snapshot.docs, item => {
            let data = item.data();
              
            if(parseInt(data.start) <= parseInt(zipcode.substring(0, 3)) &&  parseInt(zipcode.substring(0, 3)) <= parseInt(data.end)) {          
        
              for (var i=0; i<res.length; i++) {
                if(res[i].handle == "test-meal") {
                  let variants = res[i].variants;
                  for (var j=0; j<variants.length; j++){
                    if(variants[j].title == ('meal-gift-zone-'+data.zone)){
                      price = parseFloat(variants[j].price.amount);
                      zone = data.zone
                    }
                  }
                }
  
                if(res[i].handle == "shipping-rate") {
                  let variants = res[i].variants;
                  for (var j=0; j<variants.length; j++){
                    if(variants[j].title == ('meal-delivery-zone-'+data.zone)){
                      rate = parseFloat(variants[j].price.amount);
                      zone = data.zone
                    }
                  }
                }
              }
            }
          });
          console.log(price, rate);
          // callback(price, rate, zone)
          resolve({price, rate:0, zone});
        });    
      }
    },
    error => {
      resolve({price:process.env.REACT_APP_MEAL_PRICE, rate:0, zone:1});      
    });

  });

}

const getCampaigns = (db, email, callback) => {
  console.log(email)
  db
  .collection(collections.CAMPAIGNS)  
  .where("campaignAdmin", "==", email)
  .onSnapshot((snapshot) => {

    if (snapshot.docs.length > 0) {
      const items = _.map(snapshot.docs, item => {
        let data = item.data();
        var campaignMeals = [];
        data.campaignMeals.map(function(meal, index){
          campaignMeals.push({value: meal, label: meal})
        })
        return {
          id: item.id,
          recipientContactInformation: data.recipientContactInformation,
          recipientFirstName: data.recipientFirstName,
          recipientLastName: data.recipientLastName,
          recipientAddress1: data.recipientAddress1,
          recipientAddress2: data.recipientAddress2,
          recipientCity: data.recipientCity,
          recipientState: data.recipientState,
          recipientZipCode: data.recipientZipCode,
          recipientNotes: data.recipientNotes,
          campaignStatus: data.campaignStatus,
          campaignPhoto: data.campaignPhoto,
          campaignMeals: campaignMeals,
          campaignTitle: data.campaignTitle,
          campaignNote: data.campaignNote,
          campaignOrganization: data.campaignOrganization,
          campaignGoalType: data.campaignGoalType,
          orderTotalMealsGiftedQty: data.orderTotalMealsGiftedQty,
          campaignCategory: data.campaignCategory,
          campaignDescription: data.campaignDescription,
          orderTotalCampaignSum: data.orderTotalCampaignSum,
          orderDeliveryPrice: data.orderDeliveryPrice,
          orderTotalMealOrdersQty: data.orderTotalMealOrdersQty,
          boxCount: data.boxCount,
          recommandationGoals: data.recommandationGoals,
          scheduleLastOrder: data.scheduleLastOrder,
          scheduleNextOrder: data.scheduleNextOrder,
          dateRegistered: data.dateRegistered,
          orderFundsNeeded: data.orderFundsNeeded,
          campaignAdmin: data.campaignAdmin,
          campaignLength: data.campaignLength
        };
      });
      console.log(items)
      callback(items);       
    }
  },
  error => {
    console.log(error);
    let items = [];
    callback(items);  
  }
  );
}

const getAllCampaigns = (db, callback) => {
  db
  .collection(collections.CAMPAIGNS)  
  .onSnapshot((snapshot) => {
    if (snapshot.docs.length > 0) {
      const items = _.map(snapshot.docs, item => {
        let data = item.data();
        console.log(data)
        return {
          id: item.id,
          recipientContactInformation: data.recipientContactInformation,
          recipientFirstName: data.recipientFirstName,
          recipientLastName: data.recipientLastName,
          recipientAddress1: data.recipientAddress1,
          recipientAddress2: data.recipientAddress2,
          recipientCity: data.recipientCity,
          recipientState: data.recipientState,
          recipientZipCode: data.recipientZipCode,
          recipientNotes: data.recipientNotes,
          campaignStatus: data.campaignStatus,
          campaignPhoto: data.campaignPhoto,
          campaignTitle: data.campaignTitle,
          campaignCategory: data.campaignCategory,
          campaignDescription: data.campaignDescription,
          orderTotalCampaignSum: data.orderTotalCampaignSum,
          orderDeliveryPrice: data.orderDeliveryPrice,
          orderTotalMealOrdersQty: data.orderTotalMealOrdersQty,
          boxCount: data.boxCount,
          recommandationGoals: data.recommandationGoals,
          scheduleLastOrder: data.scheduleLastOrder,
          scheduleNextOrder: data.scheduleNextOrder,
          dateRegistered: data.dateRegistered,
          orderFundsNeeded: data.orderFundsNeeded,
          campaignAdmin: data.campaignAdmin,
          campaignLength: data.campaignLength,
          featured: !!data.featured,
          isPrivate: !!data.isPrivate
        };
      });
      console.log(items)
      callback(items);       
    }
  },
  error => {
    console.log(error);
    let items = [];
    callback(items);  
  }
  );
}

const getFeaturedCampaigns = (db, callback) => {
  db
    .collection(collections.CAMPAIGNS)
    .where("featured", "==", true)
    .get()
    .then(snapshot => {
      if (snapshot.docs.length > 0) {
        const items = _.map(snapshot.docs, item => {
          let data = item.data();
          console.log(data)
          return {
            id: item.id,
            recipientContactInformation: data.recipientContactInformation,
            recipientFirstName: data.recipientFirstName,
            recipientLastName: data.recipientLastName,
            recipientAddress1: data.recipientAddress1,
            recipientAddress2: data.recipientAddress2,
            recipientCity: data.recipientCity,
            recipientState: data.recipientState,
            recipientZipCode: data.recipientZipCode,
            recipientNotes: data.recipientNotes,
            campaignStatus: data.campaignStatus,
            campaignPhoto: data.campaignPhoto,
            campaignTitle: data.campaignTitle,
            campaignCategory: data.campaignCategory,
            campaignDescription: data.campaignDescription,
            orderTotalCampaignSum: data.orderTotalCampaignSum,
            orderDeliveryPrice: data.orderDeliveryPrice,
            orderTotalMealOrdersQty: data.orderTotalMealOrdersQty,
            boxCount: data.boxCount,
            recommandationGoals: data.recommandationGoals,
            scheduleLastOrder: data.scheduleLastOrder,
            scheduleNextOrder: data.scheduleNextOrder,
            dateRegistered: data.dateRegistered,
            orderFundsNeeded: data.orderFundsNeeded,
            campaignAdmin: data.campaignAdmin,
            campaignLength: data.campaignLength,
            featured: !!data.featured,
            isPrivate: !!data.isPrivate
          };
        });
        console.log(items)
        callback(items);       
      }
    })
    .catch( error => {
      console.log('getFeaturedCampaigns: ', error);
    });
}

const getCampaignAsync = (db, id) => {
  return new Promise((resolve, reject) => {
    db
      .collection(collections.CAMPAIGNS)
      .doc(id)
      .get()
      .then(snapshot => {
        var data = snapshot.data();
        resolve({...data, id});        
      })
      .catch( error => {
        console.log('getCampaignAsync: ', error);
        resolve({id:""});
      });
  });

}

export { 
  getCampaign, 
  getCampaignAsync,
  getProductDetails, 
  getZipCodes, 
  updateCampaign, 
  updateCampaignPrivate, 
  getCampaigns, 
  getFeaturedCampaigns,
  getAllCampaigns, 
  getPrices,
  getPricesByZip
};