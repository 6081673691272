import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink } from 'react-router-dom';
import logo from "../../images/logo.svg";
import Layout from '../Page';

const styles = (theme) => ({
	paper: {

		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	    width: "200px",
	    height: "48px",
	    border: "none",
	    background: "#fed202",
	    borderRadius: "6px",
	    fontSize: "14px",
	    fontWeight: 600,
	    color: "#42525a",
	    textTransform: 'initial',
	    margin: "40px calc(50% - 100px) 0",
	    cursor: "pointer"
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	progess: {
		position: 'absolute'
	},
	  navLink: {
	    transition: 'all 200ms',
	    fontSize: '12px',
	    lineHeight: 1.25,
	    color: 'rgba(66, 82, 90, 0.64)',
	    textDecoration: 'none',
	    textTransform: 'initial',
	    paddingTop: '20px',
	    borderBottom: '4px solid transparent',
	    display: 'inline-block',
	    cursor: 'Pointer',
	    '&:hover': {
	      opacity: '1',
	    },
	    '&.current': {
	      opacity: '1',
	    }
	  },
	  navLinkSpan: {
	  	fontSize: '14px',
	  	textDecoration: 'underline',
	  	color: '#fed202'
	  },
	  spaceFlex: {
	  	display: 'flex',
	  	justifyContent: 'space-between'
	  }
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error_code: '',
  error_message: '',
  loading: false
};


class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      INITIAL_STATE
    };
  }

  onSubmit = event => {
    const { email, password } = this.state;
    this.setState({ loading: true });
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
		localStorage.setItem("currentuser", email);   
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.DASHBOARD);
      })
      .catch(error => {
        this.setState({ error_code: error.code , error_message: error.message, loading:false });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error_code, error_message, loading } = this.state;
    const { classes } = this.props;

    return (

	  <Layout route="signin">
	  	<div className="signup_wrap">
		  	<div className="signup_content">
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<div className={classes.paper}>
		              <h1>Sign In</h1>
		              <p>Sign in to your registry account here.</p>
              			<div className="form-content-wrap">
		                  <input
		                    name="email"
		                    value={email}
		                    onChange={this.onChange}
		                    type="text"
		                    placeholder="Email Address"
		                  />
		                  <input
		                    name="password"
		                    value={password}
		                    onChange={this.onChange}
		                    type="password"
		                    placeholder="Password"
		                  />
		                  <div className={classes.spaceFlex}>
		                  	<NavLink to={ROUTES.PASSWORD_FORGET} className={classes.navLink} >Forgot Password</NavLink>
		                  	<NavLink to={ROUTES.SIGN_UP} className={classes.navLink}>Need to setup a account <span className={classes.navLinkSpan}>Get Started</span></NavLink>
		                  </div>
							<Button
								onClick={this.onSubmit}
								disabled={loading || !this.state.email || !this.state.password}
							>
								LOGIN
								{loading && <CircularProgress size={30} className={classes.progess} />}
							</Button>
                  			{error_message && <p className="error_message">{error_message}</p>}
						</div>
					</div>
				</Container>
			</div>
		</div>
	</Layout>
    );
  }
}

const SignInPage = compose(
  withRouter,
  withFirebase,
  withStyles(styles)
)(SignInFormBase);

export default SignInPage;

